import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// interface Stavka {
//   trenutnaCena: number;
// }

interface Klijenti {
  stranica: number;
  idKlijenta: number;
 // artikal: number;
}

const initialState: Klijenti = {
    //artikal: 0,
    stranica: 1,
    idKlijenta: 0
};

const klijenti = createSlice({
  name: 'klijenti',
  initialState,
  reducers: {
    setStranica: (state, action: PayloadAction<number>) => {
      state.stranica = action.payload;
    },
    setIdKlijenta: (state, action: PayloadAction<number>) => {
      state.idKlijenta = action.payload;
    },
  }
});

export const { setStranica, setIdKlijenta } = klijenti.actions;
export const klijentiReducer = klijenti.reducer;


