import { Box, Button, ListItem } from '@mui/material';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { setProveraStanja } from '../redux/stranice/login';


export const NavItem = (props) => {
    const { href, icon, title, ...others } = props;
    const active = href ? (window.location.pathname === href) : false;  
    const dispatch = useDispatch();
    
    
    const izadji = async (title) => {  //klik na bilo koji link iz box menia funkcija izvrsava se samo u slucaju kad je link izadji
            if(title == "Izadji"){
                dispatch(setProveraStanja(0)); 
            }
    }
    return (
        <ListItem
            disableGutters
            sx={{
            display: 'flex',
            mb: 0.5,
            py: 0,
            px: 2
            }}
            {...others}
        >
            <Link  key={title} to={href} 
            style={{ 
                        textDecoration: 'none', 
                        color: 'white', 
                        width: '100%'
                    }}
            >
                <Button 
                    //component="a"
                    startIcon={icon}
                    disableRipple
                    sx={{
                    backgroundColor: active && 'rgba(255,255,255, 0.08)',
                    borderRadius: 1,
                    color: active ? 'secondary.main' : 'neutral.300',
                    fontWeight: active && 'fontWeightBold',
                    justifyContent: 'flex-start',
                    px: 3,
                    textAlign: 'left',
                    textTransform: 'none',
                    width: '100%',
                    '& .MuiButton-startIcon': {
                        color: active ? 'secondary.main' : 'neutral.400'
                    },
                    '&:hover': {
                        backgroundColor: 'rgba(255,255,255, 0.08)'
                    }
                    }}

                    onClick={  (event) => { izadji(title); } } 

                >
                <Box sx={{ flexGrow: 1 }}>
                    {title}
                </Box>
                </Button>  
            </Link>
        </ListItem>  
    );
  };
  
  NavItem.propTypes = {
    href: PropTypes.string,
    icon: PropTypes.node,
    title: PropTypes.string
  };