import { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { OtpremnicaTemplate } from './otpremnica_template';
import { PosaljiNaEFakture } from './dugmici/posalji_na_e_fakture';
import { StornirajFakturu } from './dugmici/storniraj_fakturu';
import { ObrisiFakturu } from './dugmici/obrisi_fakturu';
import { NazadNaFakture } from './dugmici/nazad_na_tabelu_fakture';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { dodajUFakturu, dodajStavkeFakture, setVozilo } from '../../../redux/stranice/izlazneFakture';


interface Faktura {
  ime: '';
  adresa: '';
  mestoOtpreme: '';
  pib: 0;
  maticniBr: 0;
  sifraDelatnosti: 0;           
  zr: '';   
  opis: '';   
  telefon: 0;
  fixtelefon: '';
  email: '';

  imeKlijenta: '';
  adresaKlijenta: '';
  mestoKlijenta: '';
  pibKlijenta: 0;
  maticniBrKlijenta: 0;
  telefonKlijenta: '';

  brojFakture: '';
  datumFakture: '';
  datumDospeca: '';
  ukupnaPdvFak: 0;
  poreskaOsnovica: 0;
  ukupnaVrednostFak: 0;
}

export const PrikazFakture = (props:any) => {
  const idFakture = useSelector((state: RootState) => state.izlazneFakture.idFakture);
  const dispatch = useDispatch();
  const MySwal = withReactContent (Swal); //sweetalert2
  const defaultniUrl = useSelector((state: RootState) => state.defaultPodaci.defaultniUrl);

    const okvir = {
      padding: '25px'
    };

    const gornjiDeo = {
        display: 'flex',
        padding: '15px'
      };
    const mojiPodaci = {
        width: '40%',
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'space-between',
        height: '100%',
      };
    const faktura = {
        fontSize: '30px'
      };  
    const mojiPodaci1 = {
        borderLeft: '1px solid black',
        borderTop: '1px solid black',
        borderRight: '1px solid black',
      }; 
    const mojeImeText = {
        fontSize: '21px',
        paddingLeft: '10px'
      };   
    const mojiPodaci2 = {
        border: '1px solid black',
        flexGrow: 1,
      };   
    const mojiPodaciText = {
        fontSize: '16px',
        paddingLeft: '10px'
      };
    const prazanMedjuDiv = {
        width: '10%'
      };
    const klijentoviPodaci = {
        width: '50%',
        display: 'flex',
        flexDirection: 'column' as const,
        height: '100%'
      };
    type TextAlign = 'left' | 'center' | 'right' | 'justify' | 'initial' | 'inherit';
    const datumBroj = {
        //display: 'flex',
        flexWrap: 'wrap' as const,
        width: '90%',
        marginLeft: 'auto', /* Koristimo margin-left: auto; kako bismo pomaknuli datumBroj s desne strane. */
        textAlign: 'center' as TextAlign,
        //padding: '5px'
        marginBottom: '5px'
      };  
      
      const datumBrojGornjiRed = {
        display: 'flex'
      };  
      const datumBrojDonjiRed = {
        display: 'flex',
        minHeight: '30px',
      }; 
      const datumFak = {
        width: '33.3333%',
        border: '1px solid black', 
        borderRight: 'none'
      };
      const datumDosp = {
        width: '33.3333%',
        border: '1px solid black', 
      };
    const Broj = {
        width: '33.3333%',
        border: '1px solid black',
        borderLeft: 'none' /* Uklanjamo levu granicu za Broj. */
      };
    const datumFakIspis = {
      width: '33.3333%',
        border: '1px solid black',
        borderRight: 'none',
        borderTop: 'none'
      };

    const datumDospIspis = {
        width: '33.3333%',
          border: '1px solid black',
          borderTop: 'none'
        };
    const brojIspis = {
        width: '33.3333%',
        border: '1px solid black',
        borderLeft: 'none', /* Uklanjamo levu granicu za brojIspis. */
        borderTop: 'none' /* Uklanjamo gornju granicu za brojIspis. */
      };

    const klijentoviPodaci2 = {
        borderLeft: '1px solid black',
        borderTop: '1px solid black',
        borderRight: '1px solid black',
        paddingLeft: '10px'
      };
    const kupac = {
        fontSize: '21px'
      };
    const klijentoviPodaci3 = {
        border: '1px solid black',
        padding: '5px'
      };
    const donjiDeo = {
        padding: '15px'
      };
    const tabela = {
        width: '100%'
      };
    const th = {
      border: '1px solid black',
      borderLeft: 'none'
      };
    const td = {
        border: '1px solid black',
        borderLeft: 'none',
        borderTop: 'none'
       };
    const footer = {
      flex: 1,
      padding: '15px',
      display: 'flex', 
      height: '100px',      
       };
    const footerLevo = {
      //flex: 1, 
      border: '1px solid black',
      width: '50%',
      
         };
    const footerSredina = {
    //flex: 1, 
    width: '10%',
        };
         
    const footerDesno: React.CSSProperties = {
          display: 'flex',
          flexDirection: 'column',
          //flex: 1,
          width: '40%',
        };
    const footerDesnoGore = {
     // flex: 1, 
     fontSize: '20px',
      paddingLeft: '20px',
      paddingRight: '20px',
      border: '1px solid black',
      margin: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    };
    const footerDesnoDole = {
      flex: 1,
      fontSize: '19px',
      paddingLeft: '20px',
      paddingRight: '20px',
      border: '1px solid black',
      margin: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    };
       
    //moji admin podaci
    const [ime, setIme] = useState('');
    const [adresa, setAdrese] = useState('');
    const [mestoOtpreme, setMestoOtpreme] = useState('');
    const [pib, setPib] = useState(0);
    const [maticniBr, setMaticniBr] = useState(0);
    const [sifraDelatnosti, setSifraDelatnosti] = useState(0);
    const [zr, setZr] = useState('');
    const [opis, setOpis] = useState('');
    const [telefon, setTelefon] = useState(0);
    const [fixtelefon, setFixTelefon] = useState(0);
    const [email, setEmail] = useState('');  
    //moji admin podaci

    //klijentovi podaci
    const [imeKlijenta, setImeKlijenta] = useState('');
    const [adresaKlijenta, setAdresaKlijenta] = useState('');
    const [mestoKlijenta, setMestoKlijenta] = useState('');
    const [pibKlijenta, setPibKlijenta] = useState('');
    const [maticniBrKlijenta, setMaticniBrKlijenta] = useState('');
    const [telefonKlijenta, setTelefonKlijenta] = useState('');
    //klijentovi podaci

    //faktura podaci
    const [brojFakture, setBrojFakture] = useState('');
    const [datumFakture, setDatumFakture] = useState('');
    const [datumDospeca, setDatumDospeca] = useState('');
    const [ukupnaPdvFak, setUkupnaPdvFak] = useState(0);
    const [ukupnaVrednostFak, setUkupnaVrednostFak] = useState(0);
    const [statusFakture, setStatusFakture] = useState('');
    var statusFakturePrevod = '';


    const [prikaziDugmePosaljiEmailom, setPrikaziDugmePosaljiEmailom] = useState<any>(false);
    const [prikaziDugmePosaljiNaEFakture, setPrikaziDugmePosaljiNaEFakture] = useState<any>(false);
    const [prikaziDugmeStorniraj, setPrikaziDugmeStorniraj] = useState<any>(false);
    const [prikaziDugmeObrisi, setPrikaziDugmeObrisi] = useState<any>(false);



    //faktura podaci
    const [stavkeFakture, setStavkeFakture] = useState([]);

    const PodaciFakture = async () => {     
      fetch(`${defaultniUrl}podaci_fakture`, {
              method: 'POST', 
              headers: {
              'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                idFakture:idFakture
                      })        
                  }).then(res => res.json())
                  .then(data => {
                      if(data !== ""){  
                         //admin podaci                 
                         setIme(data.admin[0].ime);
                         setAdrese(data.admin[0].adresa);
                         setMestoOtpreme(data.admin[0].mesto_otpreme);
                         setPib(data.admin[0].pib);
                         setMaticniBr(data.admin[0].maticni_br);
                         setSifraDelatnosti(data.admin[0].sifra_delatnosti);
                         setZr(data.admin[0].ziro_racun);
                         setOpis(data.admin[0].opis);
                         setTelefon(data.admin[0].telefon);
                         setFixTelefon(data.admin[0].fix_telefon);
                         setEmail(data.admin[0].email);

                         //klijent podaci
                         setImeKlijenta(data.faktura_klijent[0].ime);
                         setAdresaKlijenta(data.faktura_klijent[0].adresa);
                         setMestoKlijenta(data.faktura_klijent[0].mesto);
                         setPibKlijenta(data.faktura_klijent[0].pib);
                         setMaticniBrKlijenta(data.faktura_klijent[0].maticni_br);
                         setTelefonKlijenta(data.faktura_klijent[0].telefon);


                         //faktura podaci
                         setBrojFakture(data.faktura_klijent[0].broj);
                         setDatumFakture(data.faktura_klijent[0].datum_pravljenja_fakture);
                         setDatumDospeca(data.faktura_klijent[0].datum_dospeca);
                         setUkupnaPdvFak(data.faktura_klijent[0].ukupan_pdv);
                         setUkupnaVrednostFak(data.faktura_klijent[0].ukupna_vrednost);
                         setStatusFakture(data.faktura_klijent[0].status);


                         //stavke fakture
                         const stavkeFakture = data.stavke_fakture.map((row:any, index:any) =>  (
                          <tr key={row.id} >
                             <td style={{...td, borderLeft: '1px solid black'}} align="center">{index+1}.</td>
                             <td style={td} align="center">{row.sifra}</td>
                             <td style={td} align="center">{row.ime}</td>
                             <td style={td} align="center">{row.kolicina}</td>
                             <td style={td} align="center">{row.cena}</td>
                             <td style={td} align="center">{row.poreska_osnovica}</td>
                             <td style={td} align="center">{row.rabat}</td>
                             <td style={td} align="center">{row.iznos_pdv}</td>
                             <td style={td} align="center">{row.ukupna_vrednost}</td>
                          </tr>
                        ))
                        setStavkeFakture(stavkeFakture);


                        //setujemo-punimo redux promenljive
                        const izabranaFaktura: Faktura = {
                          ime: data.admin[0].ime, 
                          adresa :data.admin[0].adresa, 
                          mestoOtpreme:data.admin[0].mesto_otpreme,
                          pib:data.admin[0].pib,
                          maticniBr:data.admin[0].maticni_br,
                          sifraDelatnosti:data.admin[0].sifra_delatnosti,
                          zr:data.admin[0].ziro_racun,
                          opis:data.admin[0].opis,
                          telefon:data.admin[0].telefon,
                          fixtelefon:data.admin[0].fix_telefon,
                          email:data.admin[0].email,
                          imeKlijenta:data.faktura_klijent[0].ime,
                          adresaKlijenta:data.faktura_klijent[0].adresa,
                          mestoKlijenta:data.faktura_klijent[0].mesto,
                          pibKlijenta:data.faktura_klijent[0].pib,
                          maticniBrKlijenta:data.faktura_klijent[0].maticni_br,
                          telefonKlijenta:data.faktura_klijent[0].telefon,
                          brojFakture:data.faktura_klijent[0].broj,
                          datumFakture:data.faktura_klijent[0].datum_pravljenja_fakture,
                          datumDospeca:data.faktura_klijent[0].datum_dospeca,
                          ukupnaPdvFak:data.faktura_klijent[0].ukupan_pdv,
                          poreskaOsnovica:data.faktura_klijent[0].poreska_osnovica,
                          ukupnaVrednostFak:data.faktura_klijent[0].ukupna_vrednost
                        }; 
                        dispatch(dodajUFakturu(izabranaFaktura));

                        data.stavke_fakture.map((red:any, indeks:any) => {
                          const stavkeFakture = {
                            id: red.id,
                            id_artikla: red.id_artikla,
                            sifra: red.sifra,
                            ime: red.ime, 
                            unitCode: red.unitCode,
                            kolicina: red.kolicina,
                            cena: red.cena,
                            poreska_osnovica: red.poreska_osnovica,
                            ukupna_vrednost: red.ukupna_vrednost
                          };
                          return dispatch(dodajStavkeFakture(stavkeFakture));    //OBAVEZNO OVAKO JEDNU PO JEDNU STAVKU DA DODAJEMO U IZ OBJEKTA U NOVI REDUX 
                      })


                      }              
                  })
  }  

    useEffect(() => { 
      PodaciFakture();  
    },[]);
  
    const stampajFakturu = () => { 
      const content = document.getElementById('faktura');
      const printWindow = window.open('', '', 'width=1200,height=1000');
        if (printWindow) {
            if (content) {
              printWindow.document.write('<html><head><title>Faktura</title></head><body>');
        printWindow.document.write(content.innerHTML);
        printWindow.document.write('</body></html>');
        printWindow.print();
        printWindow.close();
            }
        }
    }


    const stampajOtpremnicu = () => { 

      Swal.fire({
        title: 'Izaberite opciju',
        input: 'select',
        inputOptions: {
          '1': 'Momčilo Stanisavljević - Kombi - BG 111 AA',
          '2': 'Momčilo Stanisavljević - Caddy - BG 222 BB',
        },
       // inputPlaceholder: 'Izaberite opciju',
        showCancelButton: true,
        confirmButtonText: 'Submit',
        inputValidator: (value) => {
          return new Promise((resolve, reject) => {
            if (value === '1' || value === '2' || value === '3') {
              //resolve();
             // alert(value);


              dispatch(setVozilo(value));

              setTimeout(() => {
                    const content = document.getElementById('otpremnica');  //id diva gadjamo propsom u otpremnica komponenti
                    const printWindow = window.open('', '', 'width=1200,height=1000');
                      if (printWindow) {
                          if (content) {
                            printWindow.document.write('<html><head><title>Otpremnica</title></head><body>');
                      printWindow.document.write(content.innerHTML);
                      printWindow.document.write('</body></html>');
                      printWindow.print();
                      printWindow.close();
                          }
                      }
              }, 200);
              

            } else {
              reject('Morate izabrati validnu opciju.');
            }
          });
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const selectedOption = result.value;
          console.log(result.value);
        }
      });
      
      // const content = document.getElementById('otpremnica');  //id diva gadjamo propsom u otpremnica komponenti
      // const printWindow = window.open('', '', 'width=1200,height=1000');
      //   if (printWindow) {
      //       if (content) {
      //         printWindow.document.write('<html><head><title>Otpremnica</title></head><body>');
      //   printWindow.document.write(content.innerHTML);
      //   printWindow.document.write('</body></html>');
      //   printWindow.print();
      //   printWindow.close();
      //       }
      //   }
    }


    useEffect(() => {
      
      setPrikaziDugmePosaljiNaEFakture(statusFakture == 'New');
      setPrikaziDugmePosaljiEmailom(statusFakture == 'New' || statusFakture == 'Sent' || statusFakture == 'Approved');       //dugme posalji emailom prikazi za fakture na cekanju, Poslate na E-fakture i za izvrsene (sve osim kad su stornirane)
      setPrikaziDugmeStorniraj( statusFakture == 'Approved');           //dugme storniraj prikazi samo u slucaju kada je status fakture na cekanju
      setPrikaziDugmeObrisi(statusFakture == 'New');              //dugme obrisi prikazi samo u slucaju kada je status fakture stornirana
      
}, [statusFakture]);



    var statusStyle = {
      padding: '5px',
      background: 'darkgray',
      width: '100%',
      color: 'white',
      textAlign: 'center' as TextAlign, // Horizontalno centriranje teksta
      lineHeight: '2em', // Vertikalno centriranje teksta
      fontSize: '24px'
    };

    if (statusFakture === 'Approved') {
      statusStyle.background = 'linear-gradient(to right, green, red)';
      statusFakturePrevod = 'Prihvaćena';
    } else if (statusFakture === 'Sent') {
      statusStyle.background = 'green';
      statusFakturePrevod = 'Poslata';
    } else if (statusFakture === 'New') {
      statusStyle.background = 'gray';
      statusFakturePrevod = 'Na čekanju';
    } else if (statusFakture === 'Sending') {
      statusStyle.background = 'gray';
      statusFakturePrevod = 'Šalje se';
    } else if (statusFakture === 'Rejected') {
      statusStyle.background = 'brown';
      statusFakturePrevod = 'Odbijena';
    } else if (statusFakture === 'Mistake') {
      statusStyle.background = 'purple';
      statusFakturePrevod = 'Greška';
    } else if (statusFakture === 'Draft') {
      statusStyle.background = 'gray';
      statusFakturePrevod = 'Nacrt';
    } else if (statusFakture === 'Cancelled') {
      statusStyle.background = 'gray';
      statusFakturePrevod = 'Otkazana';
    }else{ //storno
      statusStyle.background = 'orange';
      statusFakturePrevod = 'Storno';
    }



    const posaljiNaEmail = () => { 

      const element = document.getElementById('faktura');
      const odakle_se_salje = "faktura";

      if (element) {
        const htmlContent = element.innerHTML; // Dobijanje sadržaja HTML elementa kao string

        // Slanje podataka na server (primer korišćenja fetch API-ja)
        fetch(`${defaultniUrl}posaljiEmail`, {
          method: 'POST',
          body: JSON.stringify({ pdfData: htmlContent, odakle_se_salje: odakle_se_salje  }),
          headers: {
              'Content-Type': 'application/json'
          }
      })
      .then(response => {
          // Obrada odgovora od servera
          if (response.ok) {

                 //     if(data.poruka == "uspesno"){

                        MySwal.fire({
                            title: 'Faktura poslata na email',
                            //text: '',
                            //icon: 'info',
                            timer: 4000,
                            showCancelButton: false,
                            showConfirmButton: false,
                            icon: 'success',
                            //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                        })
                        
                     // } 
              // Uspešno poslato
              //console.log('Podaci uspešno poslati na server.');
          } else {
              // Greška prilikom slanja
              console.error('Došlo je do greške prilikom slanja podataka na server.');
          }
      })
      .catch(error => {
          console.error('Došlo je do greške:', error);
      });



      }else {
        console.error('Element sa ID-em "faktura" nije pronađen.');
        // Dodajte kod za obradu greške ako je potrebno
    }
    }
      
return (
    <div  style={okvir}>
      <div style={statusStyle}><b>{statusFakturePrevod}</b></div>
              <div id="faktura">
                      <div style={gornjiDeo}>
                          <div style={mojiPodaci}>
                              <h2 style={faktura}>FAKTURA</h2>
                              <div style={mojiPodaci1}>
                                      <span style={mojeImeText}>{ime}</span>
                              </div>
                              <div style={mojiPodaci2}>
                                      <p style={mojiPodaciText}>{adresa}</p>
                                      <p style={mojiPodaciText}>USTANICKA 188/44/4</p>
                                      <p style={mojiPodaciText}>ZIRO RACUN: {zr}</p>
                                      <p style={mojiPodaciText}>PIB: {pib} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   PDV: 0000000</p>
                              </div>
                          </div>

                          <div style={prazanMedjuDiv}></div>

                          <div style={klijentoviPodaci}>
              
                              <div style={datumBroj}> 
                                        <div style={datumBrojGornjiRed}>
                                          <div style={datumFak}>
                                              <p>Datum<br/>Fakture</p>
                                          </div>
                                          <div style={datumDosp}>
                                              <p>Datum<br/>dospeca</p>
                                          </div>
                                          <div style={Broj}>
                                              <p>Broj</p> 
                                          </div>

                                        </div>


                                        <div style={datumBrojDonjiRed}>
                                              <div style={datumFakIspis}>                      
                                                  {datumFakture}
                                              </div>
                                              <div style={datumDospIspis}>
                                                  {datumDospeca}
                                              </div>
                                              <div style={brojIspis}>
                                                  {brojFakture}
                                              </div>
                                        </div>
                              </div>



                              <div>
                                  <div style={klijentoviPodaci2}>
                                          <span style={kupac}>KUPAC</span>
                                  </div>
                                  <div style={klijentoviPodaci3}>
                                          <p>{imeKlijenta}</p>
                                          <p>{adresaKlijenta}</p>
                                          <p>{mestoKlijenta}</p>
                                          <p>PIB: {pibKlijenta} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Telefon: {telefonKlijenta}</p>
                                  </div>
                              </div>
                          </div>
                      </div>





                      <div style={donjiDeo}>
                              <table style={tabela} cellSpacing ="0">
                                  
                                      <thead>
                                          <tr>
                                              <th style={{...th, borderLeft: '1px solid black'}}>R.B</th>
                                              <th style={th}>SIFRA</th>
                                              <th style={th}>ARTIKAL</th>
                                              <th style={th}>KOLICINA</th>
                                              <th style={th}>CENA</th>
                                              <th style={th}>P.OSNOVICA</th>
                                              <th style={th}>RABAT</th>
                                              <th style={th}>PDV</th>
                                              <th style={th}>UKUPNO</th>
                                          </tr>
                                      </thead>

                                      <tbody>
                                        {stavkeFakture}
                                      </tbody>
                                  
                              </table>
                      </div>


                      <div style={footer}>
                          <div style={footerLevo}>
                              MAT.BROJ {maticniBr}, <br/>   SIFRA DELATNOSTI {sifraDelatnosti}
                          </div>
                          <div style={footerSredina}>

                          </div>
                          <div style={footerDesno}>
                              <div style={footerDesnoGore}>
                                  <span>PDV</span>      {ukupnaPdvFak}
                              </div>
                              <div style={footerDesnoDole}>
                                  <b>UKUPNO</b>   Din: {ukupnaVrednostFak}
                              </div>
                          </div>
                      </div>   
              </div> 

        <OtpremnicaTemplate id="otpremnica"/> 

        <div> 
            {prikaziDugmePosaljiNaEFakture && <PosaljiNaEFakture/>}
            <Button style={{ width: '150px'}} variant="contained" color="info"  onClick={() => stampajFakturu()}>Štampaj</Button>&nbsp;
            <Button style={{ width: '150px'}} variant="contained" color="success"  onClick={() => stampajOtpremnicu()}>Otpremnica</Button>&nbsp; 
            {prikaziDugmePosaljiEmailom && <><Button style={{ width: '150px'}} variant="contained" color="primary"  onClick={() => posaljiNaEmail()}>Pošalji na email</Button>&nbsp;</>}
            {prikaziDugmeStorniraj  && <StornirajFakturu/>}
            { prikaziDugmeObrisi    && <ObrisiFakturu/> }
            <NazadNaFakture/> 
        </div>                 
    </div>
);

  
}

export default PrikazFakture;