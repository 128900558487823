
import { SifarnikTipovi } from '../selectSifarnici/tipovi';
//import { SifarnikStatusi } from '../../components/selectSifarnici/statusi';
import { SifarnikRabat } from '../selectSifarnici/rabat';
import { SifarnikPdv } from '../selectSifarnici/pdv';
import { SifarnikKlijenti } from '../selectSifarnici/klijenti';
import { SifarnikArtikli } from '../selectSifarnici/Artikli';
import { SifarnikCene } from '../selectSifarnici/cene';
import { DatumDospeca } from '../selectSifarnici/datumDospeca';
import { DatumPravljenja } from '../selectSifarnici/datumPravljenja';
//import { NacinPlacanja } from '../selectSifarnici/nacinPlacanja';

import { TabelaStavkiPrivremena } from './tabela_stavki_privremena';
import { TabelaSumiranihStavki } from './tabela_sumiranih_stavki_privremena';
import { Buttoni_i_funkcije } from './buttoni_i_funkcije';
import { FizickoLiceInputi } from './fizicko_lice_inputi';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from "react";
import { Box, Container, Grid ,Typography} from '@mui/material';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setIdArtikla, setImeArtikla, setJedinicaMere, setMaloprodajnaCena, setVeleprodajnaCena, setStanje, setDonjiLimit, dodajStavkuURacun, isprazniStavkeSkroz, setTip, setIdOdabranogKlijenta, /*setNacinPlacanja,*/ setDatumPravljenjaFakture, setDatumDospecaFakture, setOdabraniRabatId, setOdabraniRabatVrednost, setFlImeIPrezime,  setFlAdresa, setFlMesto, setFlLicnaKarta, setFlTelefon, setReloadKomponenteArtikli, setDisejbluj, setReloadKomponenteRabat } from '../../redux/stranice/maloprodaja';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

interface StavkeRacuna {
  idArtikla: number;
  imeArtikla: string;
  jedinicaMere: string;
  kolicina: number;
  cenaStavke: number;
  rabatStavke: number;
  rabatStavkeVrednost: number;
  odabraniRabatCifra: number; 
  pdvStavke: number;
  pdvStavkeVrednost: number;
  poreskaOsnovicaStavke: number;
  iznosPdvStavke: number;
  ukpVrednostStavke: number;
  novoStanje: number;
  idReda: number;
}

interface SumaStavkiRacuna {
  sumaSvihStavkiPoreskaOsnovicaStavke: number;
  sumaSvihStavkiIznosPdvStavke: number;
  sumaSvihStavkiUkpVrednostStavke: number;
}


export const Racun = (props:any) => {

    const MySwal = withReactContent (Swal); //sweetalert2
    const idArtikla = useSelector((state: RootState) => state.maloprodaja.idArtikla);
    const imeArtikla = useSelector((state: RootState) => state.maloprodaja.imeArtikla);
    const jedinicaMere = useSelector((state: RootState) => state.maloprodaja.jedinicaMere);
    const stanje = useSelector((state: RootState) => state.maloprodaja.stanje);
    const donjiLimit = useSelector((state: RootState) => state.maloprodaja.donjiLimit);
    const tip = useSelector((state: RootState) => state.maloprodaja.tip);
    const odabranaCena = useSelector((state: RootState) => state.maloprodaja.odabranaCena);
    const odabraniRabatId = useSelector((state: RootState) => state.maloprodaja.odabraniRabatId);
    const odabraniRabatVrednost = useSelector((state: RootState) => state.maloprodaja.odabraniRabatVrednost);
    const odabraniPdvId = useSelector((state: RootState) => state.maloprodaja.odabraniPdvId);
    const odabraniPdvVrednost = useSelector((state: RootState) => state.maloprodaja.odabraniPdvVrednost);

    const idOdabranoglijenta = useSelector((state: RootState) => state.maloprodaja.idOdabranoglijenta);
    const reloadKomponenteArtikli = useSelector((state: RootState) => state.maloprodaja.reloadKomponenteArtikli);
    const reloadKomponenteRabat = useSelector((state: RootState) => state.maloprodaja.reloadKomponenteRabat);

    const stavkeRacuna = useSelector((state: RootState) => state.maloprodaja.stavkeRacuna);

    const daLiJeObjekatPrazan = Object.keys(stavkeRacuna).length === 0;

    const dispatch = useDispatch();
    const [prikazKlijente, setPrikaziKlijente] = useState<any>(true);
    const [prikaziDatumDospeca, setPrikaziDatumDospeca] = useState<any>(true);

    const [prikaziInputeOFizickomLicu, setPrikaziInputeOFizickomLicu] = useState<any>(false);

    const [kolicina, setKolicina] = useState<any>(1);

    const dodajRedURacun = (e:any) => {    
 
      const postojiIdArtikla = stavkeRacuna.some(stavka => stavka.idArtikla === idArtikla); //provera da li je idArtikla vec u objektu
      if (!postojiIdArtikla) {                                                              
        



    if ((tip === 1 && idOdabranoglijenta === 0) || (tip === 2 && idOdabranoglijenta === 0) ){  MySwal.fire({    //za predracun i fakturu i kad nije odabran klijenta
                                title: `Prvo morate odabrati klijenta!`,
                                //text: '',
                                //icon: 'info',
                                timer: 4000,
                                showCancelButton: false,
                                showConfirmButton: false,
                                icon: 'warning',
                                //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                                }) 
                            }
    else  if(kolicina > stanje){ MySwal.fire({
                                title: `Nemate dovoljno ${imeArtikla} na stanju!`,
                                //text: '',
                                //icon: 'info',
                                timer: 4000,
                                showCancelButton: false,
                                showConfirmButton: false,
                                icon: 'warning',
                                //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                                }) 
                                setKolicina(1);
                              }
    else if (idArtikla === 0){  MySwal.fire({
                                title: `Izaberite artikal ${tip},${idOdabranoglijenta}`,
                                //text: '',
                                //icon: 'info',
                                timer: 4000,
                                showCancelButton: false,
                                showConfirmButton: false,
                                icon: 'warning',
                                //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                                }) 
                             }
    else if (kolicina === 0){  MySwal.fire({
                              title: `Polje količina ne može biti prazno`,
                              //text: '',
                              //icon: 'info',
                              timer: 4000,
                              showCancelButton: false,
                              showConfirmButton: false,
                              icon: 'warning',
                              //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                              }) 
                           }
  
     else{  
          var brojStavkiUObjektu = Object.keys(stavkeRacuna).length
          
          var ukpVrednost = kolicina * odabranaCena;
              ukpVrednost = Number(ukpVrednost.toFixed(1));
          var poreskaOsnovica = ukpVrednost/  (100+odabraniPdvVrednost) * 100;   //kod ive je bilo ovako ukpVrednost/120 * 100
              poreskaOsnovica = Number(poreskaOsnovica.toFixed(1));
          var iznosPdv = ukpVrednost - poreskaOsnovica;
              iznosPdv = Number(iznosPdv.toFixed(1));
          var novoStanje = stanje - kolicina;


          //RABAT
          if(odabraniRabatVrednost !== 0){ //PITANJE JE DA LI TREBA RABAT OBRACUNAVATI SA SVE OVE IZNOSE ILI SAMO NA UKUPNU VREDNOST
                var rabatNaUkpVrednost = ukpVrednost * (odabraniRabatVrednost/100);
                ukpVrednost = ukpVrednost - rabatNaUkpVrednost;
                ukpVrednost = Number(ukpVrednost.toFixed(1));
                

                var rabatNaPoreskaOsnovica = poreskaOsnovica * (odabraniRabatVrednost/100);
                poreskaOsnovica = poreskaOsnovica - rabatNaPoreskaOsnovica; 
                poreskaOsnovica = Number(poreskaOsnovica.toFixed(1));  //????????????????? pitanje da li treba

                var rabatNaIznosPdv = iznosPdv * (odabraniRabatVrednost/100);
                iznosPdv = iznosPdv - rabatNaIznosPdv; //????????????????? pitanje da li treba
                iznosPdv = Number(iznosPdv.toFixed(1));

                const novaStavkaRacuna: StavkeRacuna = {idArtikla: idArtikla, imeArtikla :imeArtikla, jedinicaMere:jedinicaMere, kolicina:kolicina, cenaStavke:odabranaCena,rabatStavke:odabraniRabatId,rabatStavkeVrednost:odabraniRabatVrednost,   odabraniRabatCifra:rabatNaUkpVrednost,      pdvStavke:odabraniPdvId,pdvStavkeVrednost:odabraniPdvVrednost,poreskaOsnovicaStavke:poreskaOsnovica,iznosPdvStavke:iznosPdv,ukpVrednostStavke:ukpVrednost,novoStanje:novoStanje,idReda:brojStavkiUObjektu}; 
                dispatch(dodajStavkuURacun(novaStavkaRacuna));
          }
          //RABAT
          else{
                 const novaStavkaRacuna: StavkeRacuna = {idArtikla: idArtikla, imeArtikla :imeArtikla, jedinicaMere:jedinicaMere, kolicina:kolicina, cenaStavke:odabranaCena,rabatStavke:odabraniRabatId,rabatStavkeVrednost:odabraniRabatVrednost,   odabraniRabatCifra:0,     pdvStavke:odabraniPdvId,pdvStavkeVrednost:odabraniPdvVrednost,poreskaOsnovicaStavke:poreskaOsnovica,iznosPdvStavke:iznosPdv,ukpVrednostStavke:ukpVrednost,novoStanje:novoStanje,idReda:brojStavkiUObjektu}; 
                 dispatch(dodajStavkuURacun(novaStavkaRacuna));
          }  
            //bez obzira da li je stavka sa rabatom ili bez uvek posle dodavanja stavke u objekat praznimo vrednosti
            dispatch(setIdArtikla(0));
            dispatch(setImeArtikla(''));
            dispatch(setJedinicaMere(''));
            dispatch(setMaloprodajnaCena(0));
            dispatch(setVeleprodajnaCena(0));
            dispatch(setStanje(0));
            dispatch(setDonjiLimit(0));
            setKolicina(1);
            dispatch(setOdabraniRabatId(1));
            dispatch(setOdabraniRabatVrednost(0)); 
            dispatch(setReloadKomponenteArtikli(reloadKomponenteArtikli+1));  
            dispatch(setReloadKomponenteRabat(reloadKomponenteRabat+1)); 
            //bez obzira da li je stavka sa rabatom ili bez uvek posle dodavanja stavke u objekat praznimo vrednosti  
            
            dispatch(setDisejbluj(true)); 
     }

          // console.log(`idArtikla ${idArtikla} ne postoji u objektu stavkeRacuna.`);
      } else {
        //console.log(`idArtikla ${idArtikla} postoji u objektu stavkeRacuna.`);
            MySwal.fire({
              title: `Artikal ${imeArtikla} je već dodat!`, 
              //text: '',
              //icon: 'info',
              timer: 4000,
              showCancelButton: false,
              showConfirmButton: false,
              icon: 'warning',
              //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
              }) 
        
      }


  };



      //OVO UVEK KADA SE DODJE NA STRANICU RESETUJE PRVO SVE PROMENLJIVE U REDUXU....PA POSLE NEK RADI KAKO HOCE
      const location = useLocation();
      useEffect(() => {
        if (location.pathname === '/') {
            dispatch(setIdArtikla(0));
            dispatch(setImeArtikla(''));
            dispatch(setJedinicaMere(''));
            dispatch(setMaloprodajnaCena(0));
            dispatch(setVeleprodajnaCena(0));
            dispatch(setStanje(0));
            dispatch(setDonjiLimit(0));
            dispatch(isprazniStavkeSkroz());
            dispatch(setIdOdabranogKlijenta(0));
            //dispatch(setNacinPlacanja('Bez gotovinsko'));
            dispatch(setDatumPravljenjaFakture(''));
            dispatch(setDatumDospecaFakture(''));
           

            dispatch(setFlImeIPrezime(''));
            dispatch(setFlAdresa(''));
            dispatch(setFlMesto(''));
            dispatch(setFlLicnaKarta(''));
            dispatch(setFlTelefon(''));

            dispatch(setTip(3));//uvek kao fiskalna kasa 3
        }
      }, [location.pathname]);
     //OVO UVEK KADA SE DODJE NA STRANICU RESETUJE PRVO SVE PROMENLJIVE U REDUXU....PA POSLE NEK RADI KAKO HOCE


    useEffect(() => {
      
          setPrikaziKlijente(tip == 1 || tip == 2); //prikazi select klijenti samo kada je tip 1 ili 2 tj. predracun ili faktura....ovo setuje na true
          setPrikaziDatumDospeca(tip === 2);
          setPrikaziInputeOFizickomLicu(tip == 1 && idOdabranoglijenta == 1);
      
          if(tip === 3){
            dispatch(setIdOdabranogKlijenta(0));  //ako je tip racuna fiskalna kasa onda obrisi klijent id tj setuj na nulu
            //dispatch(setNacinPlacanja('Bez gotovinsko'));
            dispatch(setDatumPravljenjaFakture(''));
            dispatch(setDatumDospecaFakture(''));
           }
           else if(tip === 1){ //ako je predracun...isprazni datum pravljenja i datum dospeca
            dispatch(setDatumPravljenjaFakture(''));
            dispatch(setDatumDospecaFakture(''));
           }
    }, [tip, idOdabranoglijenta]);



    useEffect(() => {
      if(idOdabranoglijenta !== 1){   //na svaku promenu selecta klijenti praznimo polja vezana za licne podatke kupca
        dispatch(setFlImeIPrezime(''));
        dispatch(setFlAdresa(''));
        dispatch(setFlMesto(''));
        dispatch(setFlLicnaKarta(''));
        dispatch(setFlTelefon(''));
      }
      
    }, [idOdabranoglijenta]);
    
    useEffect(() => {
      if(tip === 1){   
        dispatch(setIdOdabranogKlijenta(0)); 
      }
      
    }, [tip]);
   
   


    const poljeKolicina = (event:any) => {
        var trenutnaKolicina = event.target.value;
        setKolicina(trenutnaKolicina);
        if(imeArtikla == ''){

          MySwal.fire({
            title: 'Prvo odaberite artikal.',
            //text: '',
            //icon: 'info',
            timer: 4000,
            showCancelButton: false,
            showConfirmButton: false,
            icon: 'warning',
            //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
        })

           // alert(`Prvo odaberite artikal.`);        
        }
        else if(trenutnaKolicina > stanje){
          MySwal.fire({
            title: `Nemate dovoljno ${imeArtikla} na stanju!`,
            //text: '',
            //icon: 'info',
            timer: 4000,
            showCancelButton: false,
            showConfirmButton: false,
            icon: 'warning',
            //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
        })
        setKolicina(1);
        }
      };
   
      if(stanje <= donjiLimit && stanje != 0){   //ako je ispod donjeg limita ali nije nula pokazuje trenutno stanje
        var stanjePrikaz =  <TextField 
                                // onChange={() => setKolicina(e.target.value)}
                                InputProps={{
                                    readOnly: true,
                                  }}
                                error
                                color="secondary" 
                                focused
                                label="Stanje"
                                variant="outlined"
                                value={stanje === 0 ? '' : stanje} //ako je stanje 0 onda da isprazni u suprotnom da prikaze vrednost
                                style={{ width: '130px'}}
                                helperText="Proveri stanje"
                            />
      }
      else if( stanje == 0 && idArtikla != 0){  //ako je nula ali je izabran artikal onda pokazi nulu (po defaultu je u reduxu zakucana nula)   pokazuje nulu
        var stanjePrikaz =  <TextField 
                                // onChange={() => setKolicina(e.target.value)}
                                InputProps={{
                                  readOnly: true,
                                }}  
                                error                        
                              label="Stanje"
                              variant="outlined"
                              value={0} //ako je stanje 0 onda da isprazni u suprotnom da prikaze vrednost
                              style={{ width: '130px'}}
                              helperText="Nema na stanju"
                            />
      }  
      else{
        var stanjePrikaz =  <TextField                 //ako postoji stanje sto je ovde slucaj onda pokazuje trenutno stanje
                                // onChange={() => setKolicina(e.target.value)}
                                InputProps={{
                                    readOnly: true,
                                  }}                          
                                label="Stanje"
                                variant="outlined"
                                value={stanje === 0 ? '' : stanje} //ako je stanje 0 onda da isprazni u suprotnom da prikaze vrednost
                                style={{ width: '130px'}}
    />
      }

      
    return(
 
        <>



            <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexWrap: 'wrap',
                  m:1
                }}
            >
              <Typography
                sx={{ m: 1 }}
                variant="h4"
              >
                RAČUN
              </Typography>  
            </Box>

            
            <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexWrap: 'wrap',
                  m:3,
                }}
            >       
              <SifarnikTipovi/>&nbsp;
              {prikazKlijente && <SifarnikKlijenti lokacija="racun"/>} 
              {prikaziDatumDospeca && <> <DatumPravljenja/>&nbsp;<DatumDospeca />&nbsp;{/*<NacinPlacanja/>*/}  </>}
              {prikaziInputeOFizickomLicu &&  <FizickoLiceInputi/>}

            </Box>







            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 4
                }}
                >
                <Container maxWidth={false}> 
                    <Grid container spacing={3} sx={{ paddingBottom: '50px' }}>
                        <Grid item lg={12} md={12} xl={12} xs={12}  sx={{ display: 'flex', padding: '10px', justifyContent: 'space-between', height: '90px' }}>
                            <SifarnikArtikli lokacija="racun"/>

                            {stanjePrikaz}

                            <TextField 
                            // onChange={() => setKolicina(e.target.value)}
                            InputProps={{
                                readOnly: true,
                              }} 
                            label="JM"
                            variant="outlined"
                            value={jedinicaMere}
                            style={{ width: '130px'}}
                            />

                            <SifarnikCene/>

                            <TextField 
                            onChange={poljeKolicina}
                            label="Količina"
                            variant="outlined"
                            required
                            type="number"
                            value={kolicina === 0 ? '' : kolicina} //ako je kolicina 0 onda da isprazni u suprotnom da prikaze vrednost
                            style={{ width: '130px'}}
                            />

                            <SifarnikRabat lokacija="racun"/>
                            <SifarnikPdv/>

                            <Button style={{ width: '150px'}} variant="contained" endIcon={<SendIcon />} onClick={(e:any) => dodajRedURacun(e)}>
                                Dodaj
                            </Button >
                        
                        </Grid>                  
                    </Grid>

                    {daLiJeObjekatPrazan ? (  null ) : ( //ako smo ubacili neke stavke u objekat onda prikazujemo tabelu sa stavkama a plus kao dodatnu proveru imamo koji je tip racuna i na osnovu nje znamo koje dugme da prikazemo
                      <>
                      
                        <TabelaStavkiPrivremena/>
                        <br/>
                        <hr/>
                        <br/>
                        <TabelaSumiranihStavki/>
                        <br/>
                        <Buttoni_i_funkcije/>

                        
                      </>

)}

                    



                </Container>
            </Box>
        </>
     )
}

export default Racun;