import { useState, useEffect } from "react";
import { Button, Box, Container, Grid, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setFlImeIPrezime,  setFlAdresa, setFlMesto, setFlLicnaKarta, setFlTelefon } from '../../redux/stranice/maloprodaja';


export const FizickoLiceInputi = (props:any) => {

    const dispatch = useDispatch();
    const flImeIPrezime = useSelector((state: RootState) => state.maloprodaja.flImeIPrezime);
    const flAdresa = useSelector((state: RootState) => state.maloprodaja.flAdresa);
    const flMesto = useSelector((state: RootState) => state.maloprodaja.flMesto);
    const flLicnaKarta = useSelector((state: RootState) => state.maloprodaja.flLicnaKarta);
    const flTelefon = useSelector((state: RootState) => state.maloprodaja.flTelefon);
    

    const handleChangeFlImeIPrezime = (event:any) => {dispatch(setFlImeIPrezime(event.target.value));};
    const handleChangeFlAdresa = (event:any) => {dispatch(setFlAdresa(event.target.value));};
    const handleChangeFlMesto = (event:any) => {dispatch(setFlMesto(event.target.value));};
    const handleChangeFlLicnaKarta = (event:any) => {dispatch(setFlLicnaKarta(event.target.value));};
    const handleChangeFlTelefon = (event:any) => {dispatch(setFlTelefon(event.target.value));};

    

    const ukloniStavkuIzObjekta = (idReda:any) => {     
       // dispatch(setIzbaciStavkuIzRacuna(idReda));
      };




    return(

        <>
            <TextField
                    onChange={handleChangeFlImeIPrezime}
                    label="Ime i prezime"
                    variant="outlined"
                    value={flImeIPrezime}
            />&nbsp;
            <TextField
                    onChange={handleChangeFlAdresa}
                    label="Adresa"
                    variant="outlined"
                    value={flAdresa}
            />&nbsp;
            <TextField
                    onChange={handleChangeFlMesto}
                    label="Mesto"
                    variant="outlined"
                    value={flMesto}
            />&nbsp;
            <TextField
                    onChange={handleChangeFlLicnaKarta}
                    label="Lična karta"
                    variant="outlined"
                    value={flLicnaKarta}
            />&nbsp;
            <TextField
                    onChange={handleChangeFlTelefon}
                    label="Telefon"
                    variant="outlined"
                    value={flTelefon}
            />
  

            
        </>
     )
}

export default FizickoLiceInputi;