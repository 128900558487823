import { createSlice, PayloadAction } from '@reduxjs/toolkit';


//U StavkeRacuna  su privremene stavke fakture/racuna/predracuna      
//A u ostale promenljive su vezane za polja i na osnovu njih se racunaju stavke fakture/racuna/predracuna
interface StavkeRacuna {
  idArtikla: number;
  imeArtikla: string;
  jedinicaMere: string;
  kolicina: number;
  cenaStavke: number;
  rabatStavke: number;           //id: 1,2,3...
  rabatStavkeVrednost: number;   //vrednosti 5%, 10%, 15%...
  odabraniRabatCifra: number;   //cifre tipa 1450 din....
  pdvStavke: number;
  pdvStavkeVrednost: number;
  poreskaOsnovicaStavke: number;
  iznosPdvStavke: number;
  ukpVrednostStavke: number;
  novoStanje: number;
  idReda: number;
}

interface Maloprodaja {
  stranica: number;
  reloadKomponenteRacun: number;
  reloadKomponenteArtikli: number;
  reloadKomponenteRabat: number;
  idArtikla: number;
  imeArtikla: string;
  jedinicaMere: string;
  kolicina: number;
  maloprodajnaCena: number,
  veleprodajnaCena: number,
  odabranaCena: number,   //cena koju operater izabere iz selecta maloprodajna ili veleprodajna
  stanje: number;
  tip: number;
  odabraniRabatId: number;  //id: 1,2,3...
  odabraniRabatVrednost: number;  //vrednosti 5%, 10%, 15%...
  odabraniPdvId: number;
  odabraniPdvVrednost: number;
  donjiLimit: number;
  idTrenutnogReda: number;
  stavkeRacuna: StavkeRacuna[];
  idOdabranoglijenta: number;
  //nacinPlacanja: string;
  datumPravljenjaFakture: string,
  datumDospecaFakture: string,
  flImeIPrezime: string,
  flAdresa: string,
  flMesto: string,
  flLicnaKarta: string,
  flTelefon: string,
  disejbluj: boolean
}

const initialState: Maloprodaja = {
  stranica: 1,
  reloadKomponenteRacun:0,
  reloadKomponenteArtikli:0,
  reloadKomponenteRabat: 1,
  idArtikla: 0,
  imeArtikla: '',
  jedinicaMere: '',
  kolicina: 0,
  maloprodajnaCena: 0,
  veleprodajnaCena: 0,
  odabranaCena: 0,
  stanje: 0,
  tip: 3,  //defaltno kao fiskalna kasa
  odabraniRabatId:1,
  odabraniRabatVrednost: 0,
  odabraniPdvId: 3, //3 je id od 20% u sifarniku
  odabraniPdvVrednost: 20,
  donjiLimit: 0,
  idTrenutnogReda: 0,
  stavkeRacuna: [],
  idOdabranoglijenta: 0,  //1 je id fizickog lica
  //nacinPlacanja: 'Bez gotovinsko',
  datumPravljenjaFakture: '',
  datumDospecaFakture: '',
  flImeIPrezime: '',
  flAdresa: '',
  flMesto: '',
  flLicnaKarta: '',
  flTelefon: '',
  disejbluj: false
};









const maloprodaja = createSlice({
  name: 'maloprodaja',
  initialState,
  reducers: {
    setStranica: (state, action: PayloadAction<number>) => {
      state.stranica = action.payload;
    },
    setReloadKomponenteRacun: (state, action: PayloadAction<number>) => {
      state.reloadKomponenteRacun = action.payload;
    },
    setReloadKomponenteArtikli: (state, action: PayloadAction<number>) => {
      state.reloadKomponenteArtikli = action.payload;
    },
    setReloadKomponenteRabat: (state, action: PayloadAction<number>) => {
      state.reloadKomponenteRabat = action.payload;
    },
    
    setIdArtikla: (state, action: PayloadAction<number>) => {
      state.idArtikla = action.payload;
    },
    setImeArtikla: (state, action: PayloadAction<string>) => {
      state.imeArtikla = action.payload;
    },
    setJedinicaMere: (state, action: PayloadAction<string>) => {
      state.jedinicaMere = action.payload;
    },
    setKolicina: (state, action: PayloadAction<number>) => {
      state.kolicina = action.payload;
    },
    setMaloprodajnaCena: (state, action: PayloadAction<number>) => {
      state.maloprodajnaCena = action.payload;
    },
    setVeleprodajnaCena: (state, action: PayloadAction<number>) => {
      state.veleprodajnaCena = action.payload;
    },
    setOdabranaCena: (state, action: PayloadAction<number>) => {
      state.odabranaCena = action.payload;
    },
    setStanje: (state, action: PayloadAction<number>) => {
      state.stanje = action.payload;
    },
    setTip: (state, action: PayloadAction<number>) => {
      state.tip = action.payload;
    },
    setOdabraniRabatId: (state, action: PayloadAction<number>) => {
      state.odabraniRabatId = action.payload;
    },
    setOdabraniRabatVrednost: (state, action: PayloadAction<number>) => {
      state.odabraniRabatVrednost = action.payload;
    },


   // setOdabraniRabatCifra: (state, action: PayloadAction<number>) => {
   //   state.odabraniRabatCifra = action.payload;
   // },

    
    setOdabraniPdvId: (state, action: PayloadAction<number>) => {
      state.odabraniPdvId = action.payload;
    },
    setPdvVrednost: (state, action: PayloadAction<number>) => {
      state.odabraniPdvVrednost = action.payload;
    },
    setDonjiLimit: (state, action: PayloadAction<number>) => {
      state.donjiLimit = action.payload;
    },
    setIdTrenutnogReda: (state, action: PayloadAction<number>) => {
      state.idTrenutnogReda = action.payload;
    },


 
    dodajStavkuURacun: (state, action: PayloadAction<StavkeRacuna>) => { //SA OVOM FCJ PUNIMO OBJEKAT TJ DODAJEMO STAVKU PO STAVKU
      state.stavkeRacuna.push(action.payload);
    },
    isprazniStavkeSkroz: (state) => { //SA OVOM FCJ PRAZNIMO OBJEKAT TJ BRISEMO SVE STAVKE
      state.stavkeRacuna = [];
    },
    setIzbaciStavkuIzRacuna: (state, action: PayloadAction<number>) => {  //SA OVOM FCJ BRISEMO RED PO RED...STAVKU PO STAVKU
      const idRedaZaIzbacivanje = action.payload;
      state.stavkeRacuna = state.stavkeRacuna.filter(stavka => stavka.idReda != idRedaZaIzbacivanje);
    },



    setIdOdabranogKlijenta: (state, action: PayloadAction<number>) => {
      state.idOdabranoglijenta = action.payload;
    },
    // setNacinPlacanja: (state, action: PayloadAction<string>) => {
    //   state.nacinPlacanja = action.payload;
    // },

    

    setDatumPravljenjaFakture: (state, action: PayloadAction<string>) => {
      state.datumPravljenjaFakture = action.payload;
    },
    setDatumDospecaFakture: (state, action: PayloadAction<string>) => {
      state.datumDospecaFakture = action.payload;
    },
    setFlImeIPrezime: (state, action: PayloadAction<string>) => {
      state.flImeIPrezime = action.payload;
    },
    setFlAdresa: (state, action: PayloadAction<string>) => {
      state.flAdresa = action.payload;
    },
    setFlMesto: (state, action: PayloadAction<string>) => {
      state.flMesto = action.payload;
    },
    setFlLicnaKarta: (state, action: PayloadAction<string>) => {
      state.flLicnaKarta = action.payload;
    },
    setFlTelefon: (state, action: PayloadAction<string>) => {
      state.flTelefon = action.payload;
    },
    setDisejbluj: (state, action: PayloadAction<boolean>) => {
      state.disejbluj = action.payload;
    },



  }
});






































interface SumaStavkiRacuna {
  sumaSvihStavkiRabataStavke: number;
  sumaSvihStavkiPoreskaOsnovicaStavke: number;
  sumaSvihStavkiIznosPdvStavke: number;
  sumaSvihStavkiUkpVrednostStavke: number;
}


const initialStateSuma: SumaStavkiRacuna = {
  sumaSvihStavkiRabataStavke: 0,
  sumaSvihStavkiPoreskaOsnovicaStavke: 0,
  sumaSvihStavkiIznosPdvStavke: 0,
  sumaSvihStavkiUkpVrednostStavke: 0
};

const sumaSlice = createSlice({
  name: 'Suma',
  initialState: initialStateSuma,
  reducers: {
    setSumaSvihStavkRabataStavke: (state, action: PayloadAction<number>) => {
      state.sumaSvihStavkiRabataStavke = action.payload;
    },
    setSumaSvihStavkiPoreskaOsnovicaStavke: (state, action: PayloadAction<number>) => {
      state.sumaSvihStavkiPoreskaOsnovicaStavke = action.payload;
    },
    setSumaSvihStavkiIznosPdvStavke: (state, action: PayloadAction<number>) => {
      state.sumaSvihStavkiIznosPdvStavke = action.payload;
    },
    setSumaSvihStavkiUkpVrednostStavke: (state, action: PayloadAction<number>) => {
      state.sumaSvihStavkiUkpVrednostStavke = action.payload;
    },
  },
});




export const { setStranica, setReloadKomponenteRacun, setIdArtikla, setImeArtikla, setJedinicaMere, setKolicina, setMaloprodajnaCena, setVeleprodajnaCena, setOdabranaCena, setStanje, setTip, setOdabraniRabatId, setOdabraniRabatVrednost,/* setOdabraniRabatCifra,*/ setOdabraniPdvId, setPdvVrednost, setDonjiLimit, setIdTrenutnogReda, dodajStavkuURacun, isprazniStavkeSkroz, setIzbaciStavkuIzRacuna, setIdOdabranogKlijenta, /*setNacinPlacanja,*/ setDatumPravljenjaFakture, setDatumDospecaFakture, setFlImeIPrezime, setFlAdresa, setFlMesto, setFlLicnaKarta, setFlTelefon, setReloadKomponenteArtikli, setDisejbluj, setReloadKomponenteRabat } = maloprodaja.actions;
export const maloprodajaReducer = maloprodaja.reducer;

export const { setSumaSvihStavkRabataStavke, setSumaSvihStavkiPoreskaOsnovicaStavke, setSumaSvihStavkiIznosPdvStavke, setSumaSvihStavkiUkpVrednostStavke } = sumaSlice.actions;
export const sumaStavkiReducer = sumaSlice.reducer;


