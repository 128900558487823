import { Glavna_izvZbirniArtikli } from '../components/izvestaji/izvZbirniArtikli/glavna_izvZbirniArtikli';
function IzvZbirniArtikli() {

return(

   <Glavna_izvZbirniArtikli/>

)
}

export default IzvZbirniArtikli;