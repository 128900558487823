import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const saveProveraStanjaToSessionStorage = (value: number) => {
    // Čuvanje vrednosti u sessionStorage
    sessionStorage.setItem('proveraStanja', JSON.stringify(value));
  };

interface Logovanje {
  proveraStanja: number;
}

const initialState: Logovanje = {
    proveraStanja: 0
};

// Inicijalno postavljanje vrednosti proveraStanja ako postoji u sessionStorage
const savedProveraStanja = sessionStorage.getItem('proveraStanja');
if (savedProveraStanja) {
  initialState.proveraStanja = JSON.parse(savedProveraStanja);
}


const logovanje = createSlice({
  name: 'logovanje',
  initialState,
  reducers: {
    setProveraStanja: (state, action: PayloadAction<number>) => {
      state.proveraStanja = action.payload;
      saveProveraStanjaToSessionStorage(action.payload); // Sačuvaj u sessionStorage kada se vrednost promeni 
    },
  }
});

export const { setProveraStanja } = logovanje.actions;
export const logovanjeReducer = logovanje.reducer;




//AUTO LOGOUT
/*****************************************************************************************************************************************/

//REDUX
// import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// interface Logovanje {
//   proveraStanja: number | null; // Promenjeno u null kako bismo omogućili resetovanje
//   vremeIsteka: number | null; // Dodali smo polje za praćenje vremena isteka
// }

// const initialState: Logovanje = {
//   proveraStanja: null, // Postavljamo na null kada nije postavljeno
//   vremeIsteka: null, // Vreme isteka inicijalno null
// };

// const logovanje = createSlice({
//   name: 'logovanje',
//   initialState,
//   reducers: {
//     setProveraStanja: (state, action: PayloadAction<number>) => {
//       state.proveraStanja = action.payload;
//       state.vremeIsteka = Date.now() + 10 /** 60 * 1000*/; // Postavljanje vremena isteka za 10 minuta
//     },
//     resetProveraStanja: (state) => {
//       state.proveraStanja = null;
//       state.vremeIsteka = null;
//     },
//   },
// });

// export const { setProveraStanja, resetProveraStanja } = logovanje.actions;
// export const logovanjeReducer = logovanje.reducer;

// // Funkcija za proveru isteka vremena
// export const proveriVremeIsteka = () => {
//   return (dispatch: any, getState: any) => {
//     const { vremeIsteka } = getState().logovanje;
//     if (vremeIsteka && Date.now() > vremeIsteka) {
//       dispatch(resetProveraStanja());
//     }
//   };
// };
//REDUX








//POSEBNA STRANICA ZA LOGOUT LOGIKU
// useAutoLogout.js
// import { useEffect } from 'react';
// import { useDispatch } from 'react-redux';
// import { setProveraStanja } from '../redux/stranice/login';

// const TIMEOUT = 600000; // 10 minuta u milisekundama

// const useAutoLogout = () => {
//   const dispatch = useDispatch();

//   const proveriVremeIsteka = () => {
//     setTimeout(() => {
//       dispatch(setProveraStanja(0)); // Resetuj proveraStanja na 0 nakon 10 minuta
//     }, TIMEOUT);
//   };

//   useEffect(() => {
//     proveriVremeIsteka();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   return null; // Ovde se vraća null jer ovaj Hook ne vraća nikakav JSX
// };

// export default useAutoLogout;












//POZIV TE LOGIKE U NASIM KOMPONENTAMA
//import useAutoLogout from './useAutoLogout';
//useAutoLogout();
