import FormControl from '@mui/material/FormControl';
import { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setIdArtikla, setImeArtikla, setJedinicaMere, setKolicina, setMaloprodajnaCena, setVeleprodajnaCena, setOdabranaCena, setStanje, setDonjiLimit } from '../../redux/stranice/maloprodaja';
import { setIdArtiklaDodajNovi } from '../../redux/stranice/predracuni';

interface Artikal {
    ime: string;
    id: string;
    value:number;
  }

export const SifarnikArtikli = (props:any) => {

    const tip = useSelector((state: RootState) => state.maloprodaja.tip);
    const defaultniUrl = useSelector((state: RootState) => state.defaultPodaci.defaultniUrl);
    const idOdabranoglijenta = useSelector((state: RootState) => state.maloprodaja.idOdabranoglijenta);
    const idArtikla = useSelector((state: RootState) => state.maloprodaja.idArtikla);
    const reloadKomponenteArtikli = useSelector((state: RootState) => state.maloprodaja.reloadKomponenteArtikli);
    //const [prikaz, setPrikaz] = useState<any>('');
    const [data, setData] = useState<Artikal[]>([]); 
    const dispatch = useDispatch();

    const PrikazSelecta = async () => {

            fetch(`${defaultniUrl}sifarnik_artikli`, {
                    method: 'POST', 
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                    
                            })        
                        }).then(res => res.json())
                        .then(data => {
                            if(data !== ""){   
                                setData(data);
                            }              
                        })
    }

    useEffect(() => { 
        PrikazSelecta();   
    },[]);


    const odabirArtikla = (idArtikla:number) => {  
        
        fetch(`${defaultniUrl}odredjeni_artikal`, {
                    method: 'POST', 
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        idArtikla:idArtikla
                            })        
                        }).then(res => res.json())
                        .then(data => {
                            if(data !== ""){  
                                dispatch(setImeArtikla(data[0].ime));
                                dispatch(setJedinicaMere(data[0].jedinica_mere));


                                if(tip == 1 && idOdabranoglijenta == 1){   //ako je predracun za fizicko lice onda je maloprodajna cena
                                    dispatch(setOdabranaCena(data[0].maloprodajna_cena));  
                                }else if(tip == 2){
                                    dispatch(setOdabranaCena(data[0].veleprodajna_cena)); 
                                }else if(tip == 3){
                                    dispatch(setOdabranaCena(data[0].maloprodajnaCena));
                                }else{
                                    dispatch(setOdabranaCena(data[0].veleprodajna_cena));
                                }
                                
                                dispatch(setMaloprodajnaCena(data[0].maloprodajna_cena));
                                dispatch(setVeleprodajnaCena(data[0].veleprodajna_cena));
                                dispatch(setStanje(data[0].stanje));
                                dispatch(setDonjiLimit(data[0].donji_limit));
                            }              
                        })               
    };


    return(       
        <FormControl style={{ width: '300px'}}>
            <Autocomplete   
                key={reloadKomponenteArtikli}                               
                id="artikalId"
                options={data.map((option:any, index:any) => ({  label: `${option.ime} - ${option.sifra}`, value: option.id }))}
                renderInput={(params) => <TextField {...params} label="Artikli" />}
                
                    onChange={(event, newValue:{ label: string; value: any; } | null) => {
                    
                    if (newValue) {  
                            if(props.lokacija == "racun"){

                                dispatch(setIdArtikla(newValue.value));
                                odabirArtikla(newValue.value);

                            }else if(props.lokacija == "predracun"){

                                dispatch(setIdArtiklaDodajNovi(newValue.value));
                                
                            }
                    }else {   
                        dispatch(setIdArtikla(0));
                        dispatch(setImeArtikla(''));
                        dispatch(setJedinicaMere(''));
                        dispatch(setKolicina(0));
                        dispatch(setOdabranaCena(0));
                        dispatch(setMaloprodajnaCena(0));
                        dispatch(setVeleprodajnaCena(0));
                        dispatch(setStanje(0));
                        dispatch(setDonjiLimit(0));
                        // Kada se izvrši brisanje (clear)
                        }
                    }}                                                   
            />
        </FormControl>  
    )
}

export default SifarnikArtikli;