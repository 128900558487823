import DataTable from "react-data-table-component";
import TextField from '@mui/material/TextField';
import { Button, Box, Typography } from '@mui/material';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setStranica, setIdArtikla } from '../../redux/stranice/magacin';

type Artikl = {
  id: number;
  ime: string;
  sifra: string;
  stanje: number;
  jedinica_mere: string;
  maloprodajna_cena: number;
  maloprodajna_vrednost: number;
  veleprodajna_cena: number;
  veleprodajna_vrednost: number;
  donji_limit: number;
  stanjeStyle: any;
};


export const TabelaMagacin = (props:any) => {

    const dispatch = useDispatch();

    const defaultniUrl = useSelector((state: RootState) => state.defaultPodaci.defaultniUrl);
    const [data, setData] = useState<Artikl[]>([]);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState<Artikl[]>([]);

    const dodajNovoiArtikal = () => {
      dispatch(setStranica(2));
    };

    const IzmeniArtikal = (row:any) => {
        
      dispatch(setIdArtikla(row.id));
      dispatch(setStranica(3));
      };


    const columns:any = [
        // {
        //     name: 'RB.',
        //     cell: (row:any, index: any) => index+1+'.',
        //    // width: "10%"
        // },
        {
          name: "ŠIFRA",
          selector: (row: Artikl) => row.sifra.toString(),
         // width: "10%",
      },
        {
          name: "NAZIV",
          selector: (row: Artikl) => row.ime.toString(),
          //format: (row: Artikl) => <b key={row.id}>{row.ime}</b>,
         // width: "30%",
        },
        // {
        //     name: "STANJE",
        //     selector: 'stanje',
        //     //selector: (row: any) => <b id={row.id}>{row.stanje}</b>,
        //     //sortable: true
        //     width: "10%"
        // },
        {
          name: 'STANJE',
          cell: (row: Artikl) => (
            <div style={row.stanjeStyle}>{row.stanje}</div>
          ),
        //  width: '10%',
        },

        {
            name: "JEDINICA MERE",
            selector: (row: Artikl) => row.jedinica_mere.toString(),
            //selector: (row: any) => <b id={row.id}>{row.jedinica_mere}</b>,
            //sortable: true
          //  width: "10%"
        },
        {
            name: "MP CENA",
            selector: (row: Artikl) => row.maloprodajna_cena.toString(),
            //selector: (row: any) => <b id={row.id}>{row.maloprodajna_cena}</b>,
            //sortable: true
          //  width: "15%"
        },
        {
          name: "MP VREDNOST",
          selector: (row: Artikl) => row.maloprodajna_vrednost.toString(),
          //selector: (row: any) => <b id={row.id}>{row.maloprodajna_vrednost}</b>,
          //sortable: true
        //  width: "15%"
        },
        {
            name: "VP CENA",
            selector: (row: Artikl) => row.veleprodajna_cena.toString(),
            //selector: (row: any) => <b id={row.id}>{row.veleprodajna_cena}</b>,
            //sortable: true
         //   width: "15%"
        },
        {
          name: "VP VREDNOST",
          selector: (row: Artikl) => row.veleprodajna_vrednost.toString(),
          //selector: (row: any) => <b id={row.id}>{row.veleprodajna_vrednost}</b>,
          //sortable: true
        //  width: "15%"
        },
    ];
    //const tableClassName = 'centered-table';

    const Artikli = async () => {
 
            fetch(`${defaultniUrl}sifarnik_artikli`, {
                    method: 'POST', 
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                    
                            })        
                        }).then(res => res.json())
                        .then(data => {
                          //setData(data);
                          //setFilter(data);

                           // Provera i postavljanje stila za svaki red pri prvom inicijalnom dobijanju podataka
                           const updatedData = data.map((row: Artikl) => {
                            if (row.stanje <= row.donji_limit) {
                              row.stanjeStyle = { width: '100%',  /*backgroundColor: 'red',*/  border: '2px solid red' };
                            }
                            return row;
                          });

                          setData(updatedData);
                          setFilter(updatedData);                                     
                        })
    }


    useEffect(() => { 
        Artikli();  
    },[]);


    useEffect(() => { 
      const result = data.filter((item)=>{
        return (
          item.ime.toLowerCase().includes(search.toLowerCase()) || // Pretražujemo po imenu
          item.sifra.toLowerCase().includes(search.toLowerCase())  // Pretražujemo po šifri
        )
      }) 
      setFilter(result);
    },[search]);

    

    return(

      <>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                m:2
              }}
            >
                <Typography sx={{ m: 1 }} variant="h4">MAGACIN</Typography>
                <Box sx={{ m: 1 }}>
                    {/* <Button color="error" variant="contained" >Povrat robe </Button>
                    &nbsp; */}
                    <Button onClick={dodajNovoiArtikal} color="success" variant="contained">Dodaj novi artikal</Button>
                </Box>
            </Box>
    
            <br/>

            <Box style={{padding:"20px"}}>
                  <DataTable
                        columns={columns}
                        data={filter}
                        pagination
                        fixedHeader
                        highlightOnHover
                        //selectableRowsHighlight
                        //fixedHeaderScrollHeight='450px'
                        // selectableRows
                        //  actions={<button className='btn btn-sm btn-info'>Dogadjaj</button>}
                        onRowClicked={IzmeniArtikal}
                        subHeader
                        subHeaderComponent={
                          <TextField 
                                size="small"
                                onChange={(e)=>setSearch(e.target.value)}
                                label="Pretraži"
                                variant="outlined"
                                value={search}
                                style={{ width: '300px'}}
                                
                            />
                        }
                        // className={tableClassName}
                    />
            </Box>
      </>
     )
}

export default TabelaMagacin;