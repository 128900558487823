import './App.css';
import DashboardLayout from './components/dashboard-layout';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import dashboardPage from './pages/dashboardPage';
import Error404 from './pages/404';

import Magacin from './pages/magacin';
import Maloprodaja from './pages/maloprodaja';
import Klijenti from './pages/klijenti';
import MojiPodaci from './pages/moji_podaci';

import izlazneFakture from './pages/izlazneFakture';
import ulazneFakture from './pages/ulazneFakture';
import predracuni from './pages/predracuni';
import izvZbirniArtikli from './pages/izvZbirniArtikli';
import izvZbirniKlijenti from './pages/izvZbirniKlijenti';

import Login from './pages/login';

import { Provider } from 'react-redux';
import store from './redux/store';
import { RootState } from './redux/store';

import { useSelector } from 'react-redux';


import { Navigate } from 'react-router-dom';
import React from 'react';



function App() {


 const ProtectedRoute = ({ component: Component, ...rest }: { component: React.ElementType }) => {
  const proveraStanja = useSelector((state: RootState) => state.logovanje.proveraStanja);
  
  return proveraStanja === 1 ? (
    <DashboardLayout page={<Component />} {...rest} />
  ) : (
    <Navigate to="/" />
  );
};
 

  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<Login/>} /> 
          <Route path="/racun" element={<ProtectedRoute component={Maloprodaja}/>} />     
       
  
          <Route path="/404" element={<Error404/>} />  
          

          <Route path="/magacin" element={<ProtectedRoute component={Magacin}/>} />
          <Route path="/klijenti" element={<ProtectedRoute component={Klijenti}/>} />
          <Route path="/moji_podaci" element={<ProtectedRoute component={MojiPodaci}/>} />

          <Route path="/ulzFaklture" element={<ProtectedRoute component={ulazneFakture}/>} />
          <Route path="/izlFaklture" element={<ProtectedRoute component={izlazneFakture}/>} />
          <Route path="/predracuni" element={<ProtectedRoute component={predracuni}/>} />

          <Route path="/izvZbirniArtikli" element={<ProtectedRoute component={izvZbirniArtikli}/>} />
          <Route path="/izvZbirniKlijenti" element={<ProtectedRoute component={izvZbirniKlijenti}/>} />

           

        </Routes>
      </Router>
    </Provider>
  );






  // return (
  //   <Provider store={store}>
  //     <Router>
  //       <Routes>
  //         <Route path="/login" element={<Login/>} /> 
  //         <Route path="/racun" element={<DashboardLayout page={Maloprodaja()}/>} />     
       
  
  //         <Route path="/404" element={<Error404/>} />  

  //         <Route path="/magacin" element={<DashboardLayout page={Magacin()}/>} />
  //         <Route path="/klijenti" element={<DashboardLayout page={Klijenti()}/>} />
  //         <Route path="/moji_podaci" element={<DashboardLayout page={MojiPodaci()}/>} />

  //         <Route path="/ulzFaklture" element={<DashboardLayout page={ulazneFakture()}/>} />
  //         <Route path="/izlFaklture" element={<DashboardLayout page={izlazneFakture()}/>} />
  //         <Route path="/predracuni" element={<DashboardLayout page={predracuni()}/>} />

  //         <Route path="/izvZbirniArtikli" element={<DashboardLayout page={izvZbirniArtikli()}/>} />
  //         <Route path="/izvZbirniKlijenti" element={<DashboardLayout page={izvZbirniKlijenti()}/>} />

           

  //       </Routes>
  //     </Router>
  //   </Provider>
  // );
}

export default App;
