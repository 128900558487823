
import { useState, useEffect } from "react";
import { Button, Box, Container, TextField, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

export const Podaci = () => {

    const MySwal = withReactContent (Swal); //sweetalert2
    const defaultniUrl = useSelector((state: RootState) => state.defaultPodaci.defaultniUrl);
    const [ime, setIme] = useState('');
    const [adresa, setAdrese] = useState('');
    const [mestoOtpreme, setMestoOtpreme] = useState('');
    const [pib, setPib] = useState(0);
    const [maticniBr, setMaticniBr] = useState(0);
    const [sifraDelatnosti, setSifraDelatnosti] = useState(0);
    const [zr, setZr] = useState('');
    const [opis, setOpis] = useState('');
    const [telefon, setTelefon] = useState(0);
    const [fixtelefon, setFixTelefon] = useState(0);
    const [email, setEmail] = useState('');
    const [logo, setLogo] = useState('');


    const handleChangeIme = (event:any) => {setIme(event.target.value);};
    const handleChangeAdresa = (event:any) => {setAdrese(event.target.value);};
    const handleChangeMestoOtpreme = (event:any) => {setMestoOtpreme(event.target.value);};
    const handleChangePib = (event:any) => {setPib(event.target.value);};
    const handleChangeMaticniBr = (event:any) => {setMaticniBr(event.target.value);};
    const handleChangeSifraDelatnosti = (event:any) => {setSifraDelatnosti(event.target.value);};
    const handleChangeZr = (event:any) => {setZr(event.target.value);};
    const handleChangeOpis = (event:any) => {setOpis(event.target.value);};
    const handleChangeTelefon = (event:any) => {setTelefon(event.target.value);};
    const handleChangeFixTelefon = (event:any) => {setFixTelefon(event.target.value);};
    const handleChangeEmail = (event:any) => {setEmail(event.target.value);};



    const izmeniMojePodatke = async () => {

        if(ime !== "" && adresa !== "" && pib !== 0  && maticniBr !== 0 && sifraDelatnosti !== 0 && zr !== "" && email !== "" ){

        
          fetch(`${defaultniUrl}izm_moje_podatke`, {
              method: 'POST', 
              headers: {
              'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                ime:ime,
                adresa:adresa,
                mestoOtpreme:mestoOtpreme,
                pib:pib,
                maticniBr:maticniBr,
                sifraDelatnosti:sifraDelatnosti,
                zr:zr,
                opis:opis,
                telefon:telefon,
                fixtelefon:fixtelefon,
                email:email,

                      })        
                  }).then(res => res.json())
                  .then(data => {
                      
                      // console.log(data)
                      if(data.poruka == "uspesno"){

                        MySwal.fire({
                            title: 'Podaci uspešno promenjeni',
                            //text: '',
                            //icon: 'info',
                            timer: 4000,
                            showCancelButton: false,
                            showConfirmButton: false,
                            icon: 'success',
                            //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                        }) 
                      }              
                  })
                }
                else{
                    MySwal.fire({
                        title: `Popunite obavezna polja: Ime, Adresu, Pib, Matični broj, Žiro račun i Email`,
                        //text: '',
                        //icon: 'info',
                        timer: 5000,
                        showCancelButton: false,
                        showConfirmButton: false,
                        icon: 'warning',
                        //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                    }) 
                }
        }

    const MojiPodaci = async () => {
 
            fetch(`${defaultniUrl}moji_podaci`, {
                    method: 'POST', 
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                    
                            })        
                        }).then(res => res.json())
                        .then(data => {
                            if(data !== ""){ 
                               setIme(data[0].ime);
                               setAdrese(data[0].adresa);
                               setMestoOtpreme(data[0].mesto_otpreme);
                               setPib(data[0].pib);
                               setMaticniBr(data[0].maticni_br);
                               setSifraDelatnosti(data[0].sifra_delatnosti)
                               setZr(data[0].ziro_racun);
                               setOpis(data[0].opis);
                               setTelefon(data[0].telefon);
                               setFixTelefon(data[0].fix_telefon);
                               setEmail(data[0].email);
                               setLogo(data[0].logo);
                            }              
                        })
    }

    useEffect(() => { 
        MojiPodaci();  
    },[]);

    return(
        <>   

<Container maxWidth={false}
  sx={{
    flexGrow: 1,
    py: 8
  }}>

    <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          m: 0
        }}
    >
        <Typography
          sx={{ m: 1 }}
          variant="h4"
        >
         Moji podaci
        </Typography>
        <Box sx={{ m: 1 }}>
            {/* <Button  //onClick={}
                color="warning"
                variant="contained"
            >
                Nazad                       
            </Button> */}
        </Box>
    </Box>

    <Box
        component="main"
        sx={{
            flexGrow: 1,
            py: 3,
            width: '500px'
        }}
        > 
            
                <TextField
                    size="small"
                    fullWidth
                    onChange={handleChangeIme}
                    label="Ime"
                    variant="outlined"
                    value={ime}
                />
                <br/>
                <br/>
                <TextField
                    size="small"
                    fullWidth
                    onChange={handleChangeAdresa}
                    label="Adresa"
                    variant="outlined"
                    value={adresa}
                />
                <br/>
                <br/>
                <TextField
                    size="small"
                    fullWidth
                    onChange={handleChangeMestoOtpreme}
                    label="Mesto otpreme"
                    variant="outlined"
                    value={mestoOtpreme}
                />
                <br/>
                <br/>
                <TextField
                    size="small"
                    fullWidth
                    onChange={handleChangePib}
                    label="Pib"
                    variant="outlined"
                    value={pib}
                />
                <br/>
                <br/>
                <TextField
                    size="small"
                    fullWidth
                    onChange={handleChangeMaticniBr}
                    label="Matični broj"
                    variant="outlined"
                    value={maticniBr}
                />
                <br/>
                <br/>
                <TextField
                    size="small"
                    fullWidth
                    onChange={handleChangeSifraDelatnosti}
                    label="Šifra delatnosti"
                    variant="outlined"
                    value={sifraDelatnosti}
                />
                <br/>
                <br/>
                <TextField
                    size="small"
                    fullWidth
                    onChange={handleChangeZr}
                    label="Žiro račun"
                    variant="outlined"
                    value={zr}
                />
                <br/>
                <br/>
                <TextField
                    size="small"
                    fullWidth
                    onChange={handleChangeOpis}
                    label="Opis"
                    variant="outlined"
                    value={opis}
                />
                <br/>
                <br/>
                <TextField
                    size="small"
                    fullWidth
                    onChange={handleChangeTelefon}
                    label="Mobilni telefon"
                    variant="outlined"
                    value={telefon}
                    />
                <br/>
                <br/>
                <TextField
                    size="small"
                    fullWidth
                    onChange={handleChangeFixTelefon}
                    label="Fix telefon"
                    variant="outlined"
                    value={fixtelefon}
                    />
                <br/>
                <br/>
                <TextField
                    size="small"
                    fullWidth
                    onChange={handleChangeEmail}
                    label="Email"
                    variant="outlined"
                    value={email}
                    />
                <br/>
                <br/>
                <Button fullWidth color="success" variant="contained" onClick={  (event) => { izmeniMojePodatke(); } } > Sačuvaj </Button>


    </Box>
</Container>
        </>
    )
}

export default Podaci;