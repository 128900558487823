import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {  useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

export const TabelaSumiranihStavki = (props:any) => {

    const sumaUkpVrednostRabat = useSelector((state: RootState) => state.sumaStavki.sumaSvihStavkiRabataStavke);
    const sumaPoreskeOsnovice = useSelector((state: RootState) => state.sumaStavki.sumaSvihStavkiPoreskaOsnovicaStavke);
    const sumaUkpIznosPdv = useSelector((state: RootState) => state.sumaStavki.sumaSvihStavkiIznosPdvStavke);
    const sumaUkpVrednost = useSelector((state: RootState) => state.sumaStavki.sumaSvihStavkiUkpVrednostStavke);

    return(
        <>      
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                        <TableCell align="center">&nbsp;</TableCell>
                        <TableCell align="center">&nbsp;</TableCell>
                        <TableCell align="center">&nbsp;</TableCell>
                        <TableCell align="center">&nbsp;</TableCell>
                            <TableCell align="right">Rabat ukupno</TableCell>
                            <TableCell align="right">Poreska osnovica ukupno</TableCell>
                            <TableCell align="right">Iznos PDV-a ukupno</TableCell>
                            <TableCell align="right">Ukupno ukupno</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="center">&nbsp;</TableCell>
                            <TableCell align="center">&nbsp;</TableCell>
                            <TableCell align="center">&nbsp;</TableCell>
                            <TableCell align="center">&nbsp;</TableCell>
                            <TableCell align="right"><b>{sumaUkpVrednostRabat}</b></TableCell>
                            <TableCell align="right"><b>{sumaPoreskeOsnovice}</b></TableCell>
                            <TableCell align="right"><b>{sumaUkpIznosPdv}</b></TableCell>
                            <TableCell align="right"><b>{sumaUkpVrednost}</b></TableCell>                                       
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>      
        </>
    )
}

export default TabelaSumiranihStavki;