import { TabelaUlazneFak } from './tabela_ulazne';
import { PrikazFaktureUlazne } from './prikaz_fakture_ulazne';
import {  useEffect } from "react";
import { useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

import { setStranica } from '../../../redux/stranice/ulazneFakture';


export const GlavnaUlazneFakture = () => {

  const dispatch = useDispatch();
  const stranica = useSelector((state: RootState) => state.ulazneFakture.stranica);


 

//OVO UVEK KADA SE DODJE NA STRANICU RESETUJE PRVO SVE PROMENLJIVE U REDUXU....PA POSLE NEK RADI KAKO HOCE
const location = useLocation();
useEffect(() => {
  if (location.pathname === '/ulzFaklture') {
      dispatch(setStranica(1));  
  }
}, [location.pathname]);
//OVO UVEK KADA SE DODJE NA STRANICU RESETUJE PRVO SVE PROMENLJIVE U REDUXU....PA POSLE NEK RADI KAKO HOCE




 return(
   <>
      {stranica === 1 && <TabelaUlazneFak/>}
      {stranica === 2 && <PrikazFaktureUlazne/>}
   </>
 )
};


export default GlavnaUlazneFakture;