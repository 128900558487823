import TextField from '@mui/material/TextField';
import { Button, Box, Typography } from '@mui/material';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { SifarnikJedinicaMere } from '../selectSifarnici/jedinica_mere';
import { RootState } from '../../redux/store';
import { setStranica, setJedinicaMereId } from '../../redux/stranice/magacin';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


export const DodajNoviArtikal = (props:any) => {

    const MySwal = withReactContent (Swal); //sweetalert2   
    const dispatch = useDispatch();
    const defaultniUrl = useSelector((state: RootState) => state.defaultPodaci.defaultniUrl);
    const [sifra, setSifra] = useState('');
    const [ime, setIme] = useState('');
    const [maloprodajnaCena, setMaloprodajnaCena] = useState('');
    const [veleprodajnaCena, setVeleprodajnaCena] = useState('');
    const [stanje, setStanje] = useState('');
    const jm = useSelector((state: RootState) => state.magacin.jedinicaMereId);
    const [donjiLimit, setDonjiLimit] = useState('');

    const handleChangeSifra = (event:any) => {setSifra(event.target.value);};
    const handleChangeIme = (event:any) => {setIme(event.target.value);};
    const handleChangeMaloprodajnaCena = (event:any) => {setMaloprodajnaCena(event.target.value);};
    const handleChangeVeleprodajnaCena = (event:any) => {setVeleprodajnaCena(event.target.value);};
    const handleChangeStanje = (event:any) => {setStanje(event.target.value);};
    const handleDonjiLimit = (event:any) => {setDonjiLimit(event.target.value);};


    const dodajNovArtikal = () => {
      if(stanje == ""){ 
        var novoStanje = 0;
      }else{
        var novoStanje = parseInt(stanje);
      }
                  fetch(`${defaultniUrl}dodaj_novi_artikal`, {
                    method: 'POST', 
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                      sifra:sifra,
                      ime:ime,
                      maloprodajnaCena:maloprodajnaCena,
                      veleprodajnaCena:veleprodajnaCena,
                      stanje:novoStanje,
                      jm:jm,
                      donjiLimit:donjiLimit
                            })        
                        }).then(res => res.json())
                        .then(data => {

                                        if(data.poruka == "uspesno"){ 
                                          MySwal.fire({
                                          title: `Uspešno dodat novi artikal`,
                                          //text: '',
                                          //icon: 'info',
                                          timer: 4000,
                                          showCancelButton: false,
                                          showConfirmButton: false,
                                          icon: 'success',
                                          //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                                          })
                                                dispatch(setStranica(1));
                                                dispatch(setJedinicaMereId(0));
                                        }else{
                                                MySwal.fire({
                                                title: `Greška`,
                                                //text: '',
                                                //icon: 'info',
                                                timer: 4000,
                                                showCancelButton: false,
                                                showConfirmButton: false,
                                                icon: 'error',
                                                //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                                                })
                                        }

                        })
     
    };

    const naTabeluArtikala = () => {
      dispatch(setStranica(1));
    };


    return(
      <>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                m:2
              }}
            >
              <Typography sx={{ m: 1 }} variant="h4">DODAJ NOVI ARTIKAL</Typography>
              <Box sx={{ m: 1 }}>
                  <Button  onClick={naTabeluArtikala} color="warning" variant="contained">Nazad</Button>
              </Box>
            </Box>
            <br/>
            <Box style={{padding:"20px", width: '30%'}}> 
                <TextField   
                        size="small"
                        required                    
                        fullWidth
                        onChange={handleChangeSifra}
                        label="Šifra"
                        variant="outlined"
                        //value={ime}
                />
                <br/><br/>
                <TextField   
                        size="small"
                        required                    
                        fullWidth
                        onChange={handleChangeIme}
                        label="Naziv"
                        variant="outlined"
                        //value={ime}
                />
                <br/><br/>
                <TextField   
                        size="small"
                        required                    
                        fullWidth
                        onChange={handleChangeMaloprodajnaCena}
                        label="Maloprodajna cena"
                        variant="outlined"
                        //value={ime}
                />
                <br/><br/>
                <TextField   
                        size="small"
                        required                    
                        fullWidth
                        onChange={handleChangeVeleprodajnaCena}
                        label="Veleprodajna cena"
                        variant="outlined"
                        //value={ime}
                />
                <br/><br/>
                <TextField   
                        size="small"
                        required                    
                        fullWidth
                        onChange={handleChangeStanje}
                        label="Stanje"
                        variant="outlined"
                        //value={ime}
                />
                <br/><br/>
                <SifarnikJedinicaMere/>
                <br/><br/>
                <TextField   
                        size="small"
                        required                    
                        fullWidth
                        onChange={handleDonjiLimit}
                        label="Donji limit"
                        variant="outlined"
                        //value={ime}
                />
                <br/>
                <br/>
                <Button fullWidth color="success" variant="contained" onClick={  (event) => { dodajNovArtikal(); } } > Sačuvaj </Button>
            </Box>
      </>   
     )
}

export default DodajNoviArtikal;