import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useState, useEffect } from "react";

import { useDispatch, useSelector } from 'react-redux';
//import { RootState } from '../../redux/store';
import { setTip, setIdOdabranogKlijenta } from '../../redux/stranice/maloprodaja';
import { RootState } from '../../redux/store';



interface TipoviData {
    id: string;
    vrednost: string;
  }

export const SifarnikTipovi = () => {

    const defaultniUrl = useSelector((state: RootState) => state.defaultPodaci.defaultniUrl);
    const idArtikla = useSelector((state: RootState) => state.maloprodaja.idArtikla);
    const disejbluj = useSelector((state: RootState) => state.maloprodaja.disejbluj);
    const dispatch = useDispatch();
    const [prikaz, setPrikaz] = useState('');
    //const [selectedValue, setSelectedValue] = useState<number>(1); 

    const [data, setData] = useState<TipoviData[]>([]); 

    const handleChange = (event:any) => {
       dispatch(setTip(event.target.value));
       dispatch(setIdOdabranogKlijenta(0));
       //setSelectedValue(event.target.value);


  


      };

    const PrikazSelecta = async () => {
 
            fetch(`${defaultniUrl}sifarnik_tipovi`, {
                    method: 'POST', 
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                    
                            })        
                        }).then(res => res.json())
                        .then(data => {
                            if(data !== ""){  
                                
                                setData(data);

                            }              
                        })
    }

    useEffect(() => { 
        PrikazSelecta();  
    },[]);


    // useEffect(() => {
    //    // setSelectedValue(1); 
    // }, [idArtikla]);

    return(      
            <FormControl  style={{ width: '200px'}}  >
                <InputLabel id="demo-simple-select-label">Tip</InputLabel>
                    <Select
                        disabled={disejbluj}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        defaultValue={3}
                        //value={selectedValue}
                        label="Tip"
                        onChange={handleChange}
                    >
                        {data.map((row, index) => {                                                
                                return   <MenuItem key={index} value={row.id}>{row.vrednost}</MenuItem>
                        })}
                    </Select>
            </FormControl>    
    )
}

export default SifarnikTipovi;