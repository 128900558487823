import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import TextField from '@mui/material/TextField';
import { Box, Typography } from '@mui/material';
import { setStranica, setIdFakture } from '../../../redux/stranice/ulazneFakture';
import Button from '@mui/material/Button';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import SyncIcon from '@mui/icons-material/Sync';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

type FaktureIzlazne = {
  id: number;
  broj_fakture: string;
  ime_prodavca: string;
  datum_dospeca: string;
  ukupan_iznos_fakture: string;
  status: string;
  pdf_putanja: string;
};


export const TabelaUlazneFak = (props:any) => {

    const MySwal = withReactContent (Swal); //sweetalert2
    const dispatch = useDispatch();
    const [data, setData] = useState<FaktureIzlazne[]>([]);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState<FaktureIzlazne[]>([]);
    const defaultniUrl = useSelector((state: RootState) => state.defaultPodaci.defaultniUrl);

    const columns:any = [
      // {
      //     name: 'RB.',
      //     //cell: (row: any, index: number) => startingRowNumber + index + '.',
      //     cell: (row:any, index: any) => index+1+'.',
      // },
      {
        name: "BROJ FAKTURE",
        selector: (row: FaktureIzlazne) => row.broj_fakture.toString(),
      },
      
      {
        name: "KLIJENT",
        selector: (row: FaktureIzlazne) => row.ime_prodavca.toString(),
      },
      { 
          name: "DATUM DOSPEĆA",
          selector: (row: FaktureIzlazne) => row.datum_dospeca.toString(),
      },
      // {
      //     name: "NAČIN PLAĆANJA",
      //     selector: (row: FaktureIzlazne) => row.nacin_placanja.toString(),
      // },
      {
          name: "VREDNOST FAKTURE",
          selector: (row: FaktureIzlazne) => row.ukupan_iznos_fakture.toString(),
      },
    //   {
    //     name: "ORIGINALNA FAKTURA",
    //     cell: (row: FaktureIzlazne) => (
    //         <a href={`/pdf/1922491.pdf`} target="_blank" rel="noopener noreferrer">
    //             {row.pdf_putanja}
    //         </a>
    //     ),
    // },
      {
          name: "STATUS",
          selector: (row: FaktureIzlazne) => {
            if (row.status === 'Approved') {
              return 'Prihvaćena';
            }else if(row.status === 'Sent') {
              return 'Poslata';
            }else if(row.status === 'New') {
              return 'Na čekanju';
            }else if(row.status === 'Sending') {
              return 'Šalje se';
            }else if(row.status === 'Rejected') {
              return 'Odbijena';
            }else if(row.status === 'Mistake') {
              return 'Greška';
            }else if(row.status === 'Draft') {
              return 'Nacrt';
            }else if(row.status === 'Cancelled') {
              return 'Otkazana';
            }else {
              return row.status.toString();  //storno
            }
          },
      },
      // {
      //   name: "PROVERI STATUS",
      //   cell: (row: FaktureIzlazne) => (
      //     <Button onClick={() => proveraStatusa(row.id, row.e_fakture_id, row.status)} variant="contained" color="error" style={{ margin: 'auto' }}><SyncIcon/></Button>
      //   ),
      // }
    ];


//     const proveraStatusa = async (idFakture:number,e_fakture_id:number, status: string) => {

//       if(e_fakture_id != 0){ 
//                 fetch('http://localhost:5000/proveri_status_poslete_fakture_na_e_fakture', {
//                   method: 'POST', 
//                   headers: {
//                   'Content-Type': 'application/json'
//                   },
//                   body: JSON.stringify({
//                     idFakture:idFakture,
//                     idFaktureEFakture:e_fakture_id
//                           })        
//                       }).then(res => res.json())
//                       .then(data => {
//                           if(data.status != status){  
//                               MySwal.fire({
//                                 title: 'Status je azuriran',
//                                 //text: '',
//                                 //icon: 'info',
//                                 timer: 4000,
//                                 showCancelButton: false,
//                                 showConfirmButton: false,
//                                 icon: 'success',
//                                 //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
//                             })
//                             izlazneFakture(); 
//                           }else{
//                                 MySwal.fire({
//                                   title: 'Status je ne promenjen',
//                                   //text: '',
//                                   //icon: 'info',
//                                   timer: 4000,
//                                   showCancelButton: false,
//                                   showConfirmButton: false,
//                                   icon: 'warning',
//                                   //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
//                               })
//                           }              
//                       })
//       }else{
//         MySwal.fire({
//           title: 'Izabrana faktura jos uvek nije poslata na E-sef!',
//           //text: '',
//           //icon: 'info',
//           timer: 4000,
//           showCancelButton: false,
//           showConfirmButton: false,
//           icon: 'warning',
//           //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
//       })
//       }
// }

        const preuzimanjeIdevaNovihFaktura = async () => {
          //prvo preuzimamo sve id-eve novih faktura ukoliko postoje
          //zatim loop-ujemo sve te id-ele i za svaki od njih saljemo zahtev novi API za preuzimanje fakture...kad preuzmemo XML prvo ga parsiramo zatim unosimo novu fakturu kod nas u bazu i njene stavke
          //zatim bi trebalo svakoj to fakturi promeniti stanje

                  try {
                    const response = await fetch(`${defaultniUrl}purchase_invoice_id`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            status: "Seen"
                        })
                    });
            
                    var data = await response.json();
                    //console.log(data);
                    //data = data.PurchaseInvoiceIds;

                                                data.map((idFakture: any, index: any) => {

                                                      try {
                                                        fetch(`${defaultniUrl}preuzmi_dolaznu_fakturu_sa_e_fakture`, {
                                                            method: 'POST', 
                                                            headers: {
                                                            'Content-Type': 'application/json'
                                                            },
                                                            body: JSON.stringify({
                                                              idFakture:idFakture
                                                                    })        
                                                                })
                                                                
                                                                //??????????????????????????????????????????????????????????????????????????????????????????????????????
                                                                .then(res => res.json())
                                                                .then(data => {
                                                                    if (data.poruka == "uspesno") {
                                                                        ulazneFakture(); // Poziva ulazneFakture() samo ako nema greške
                                                                    }
                                                                })
                                                                .catch(error => {
                                                                    ulazneFakture();
                                                                   // console.error('Došlo je do greške:', error);
                                                                });
                                                                //??????????????????????????????????????????????????????????????????????????????????????????????????????
                                                    } catch (error) {
                                                        console.error('Došlo je do greške:', error);
                                                    }

                                                    // Ovde možete manipulisati ili raditi sa podacima u "row"
                                                });
          
                } catch (error) {
                    console.error('Došlo je do greške pri preuzimanju id-eva:', error);
                            MySwal.fire({
                                title: 'Servis E-faktura je trenutno ne dostupan!!!',
                                //text: '',
                                //icon: 'info',
                                timer: 4000,
                                showCancelButton: false,
                                showConfirmButton: false,
                                icon: 'error',
                                //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                            })
                }
        }

      const ulazneFakture = async () => {

            fetch(`${defaultniUrl}ulazne_fakture`, {
                    method: 'POST', 
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                    //  startIndex:startIndex,
                   //   endIndex:endIndex
                            })        
                        }).then(res => res.json())
                        .then(data => {                            
                            if(data !== ""){
                             
                              setData(data);
                              setFilter(data); 
                            }              
                        })
    }


    useEffect(() => { 
      preuzimanjeIdevaNovihFaktura();
      ulazneFakture();  
    },[/*currentPage, itemsPerPage*/]);

    useEffect(() => { 
      const result = data.filter((item)=>{
        return (
          item.broj_fakture.toLowerCase().includes(search.toLowerCase()) || // Pretražujemo po broju fakture
          item.ime_prodavca.toLowerCase().includes(search.toLowerCase()) || // Pretražujemo po imenu klijenta
          item.datum_dospeca.toLowerCase().includes(search.toLowerCase()) || // Pretražujemo po datumu dospeca fakture
          item.ukupan_iznos_fakture.toString().toLowerCase().includes(search.toLowerCase()) || // Pretražujemo po ukupnoj vrednosti
          item.status.toLowerCase().includes(search.toLowerCase())  // Pretražujemo po statusu
        )
      }) 
      setFilter(result);
    },[search]);

    function otvaranjeFakture (row:any) {    
      //alert(row.id)    
       dispatch(setIdFakture(row.id));
      // dispatch(setIdFaktureEFakture(row.e_fakture_id));
       dispatch(setStranica(2));     
    }



    const customRowStyles = [
      {
        when: (row: FaktureIzlazne) => row.status === 'New',
        style: {
          background: 'darkgray',
          color: 'white',
        },
      },
      {
        when: (row: FaktureIzlazne) => row.status === 'Approved',
        style: {
          background: 'linear-gradient(to right, green, red)',
          color: 'white',
        },
      },
      {
        when: (row: FaktureIzlazne) => row.status === 'Storno',
        style: {
          background: 'orange',
          color: 'black',
        },
      },
      {
        when: (row: FaktureIzlazne) => row.status === 'Sent',
        style: {
          background: 'green',
          color: 'white',
        },
      },
    ];


      return (
      <>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            m:2
          }}
        >
          <Typography sx={{ m: 1 }} variant="h4">ULAZNE FAKTURE</Typography>
          {/* <Box sx={{ m: 1 }}>
              <Button  onClick={} color="success" variant="contained">Dodaj novog klijenta</Button>
          </Box> */}
        </Box>
      
        <br/>
        <Box sx={{ padding:"20px" }}>
        <DataTable
                    columns={columns}
                    data={filter}
                    pagination
                    fixedHeader
                    highlightOnHover
                    onRowClicked={otvaranjeFakture}
                    subHeader
                    subHeaderComponent={
                      <TextField 
                            size="small"
                            onChange={(e)=>setSearch(e.target.value)}
                            label="Pretraži"
                            variant="outlined"
                            value={search}
                            style={{ width: '300px'}}   
                        />
                      
                    }
                    conditionalRowStyles={customRowStyles}
                    // className={tableClassName}
              />
        </Box>
      </>
  );


}

export default TabelaUlazneFak;