import { GlavnaMojiPodaci } from '../components/mojiPodaci/glavna_moji_podaci';

function MojiPodaci() {

return(

   <GlavnaMojiPodaci/>

)
}

export default MojiPodaci;