import { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { setStranica } from '../../../redux/stranice/predracuni';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import CancelIcon from '@mui/icons-material/Cancel';
import { SifarnikArtikli } from '../../selectSifarnici/Artikli';
import { SifarnikRabat } from '../../selectSifarnici/rabat';
import TextField from '@mui/material/TextField';
import jsPDF from 'jspdf';
//import { OtpremnicaTemplate } from './otpremnica_template';

import { dodajStavkeFakture, isprazniStavkeFakture, setIdArtiklaDodajNovi, setOdabraniRabatIdNaPredracunu, setOdabraniRabatVrednostNaPredracunu } from '../../../redux/stranice/predracuni';
import { setReloadKomponenteArtikli, setReloadKomponenteRabat } from '../../../redux/stranice/maloprodaja'; 
interface Faktura {
  ime: '';
  adresa: '';
  mestoOtpreme: '';
  pib: 0;
  maticniBr: 0;
  sifraDelatnosti: 0;           
  zr: '';   
  opis: '';   
  telefon: 0;
  fixtelefon: '';
  email: '';

  imeKlijenta: '';
  adresaKlijenta: '';
  mestoKlijenta: '';
  pibKlijenta: 0;
  telefonKlijenta: '';

  brojFakture: '';
  datumFakture: '';
  datumDospeca: '';
  ukupnaPdvFak: 0;
  ukupnaVrednostFak: 0;
}

interface StavkeFakture {
  id: number;
  id_artikla: number;
  sifra: string;
  ime: string;
  kolicina: number;
  cena: number;
  poreska_osnovica: number; 
  ukupna_vrednost: number;           
}

export const PrikazPredracuna = (props:any) => {

  const defaultniUrl = useSelector((state: RootState) => state.defaultPodaci.defaultniUrl);
  const idPredracuna = useSelector((state: RootState) => state.predracuni.idPredracuna);
  const stavkeFaktureZbogStanja = useSelector((state: RootState) => state.predracuni.stavkeFakture);

  const idArtiklaDodajNovi = useSelector((state: RootState) => state.predracuni.idArtiklaDodajNovi);
  const odabraniRabatIdNaPredracunu = useSelector((state: RootState) => state.predracuni.odabraniRabatIdNaPredracunu);
  const odabraniRabatVrednostNaPredracunu = useSelector((state: RootState) => state.predracuni.odabraniRabatVrednostNaPredracunu);
  const [kolicinaDodajNovi, setKolicinaDodajNovi] = useState(1);
  const reloadKomponenteArtikli = useSelector((state: RootState) => state.maloprodaja.reloadKomponenteArtikli);
  const reloadKomponenteRabat = useSelector((state: RootState) => state.maloprodaja.reloadKomponenteRabat);
  

  const MySwal = withReactContent (Swal); //sweetalert2
  const dispatch = useDispatch();

  const okvir = {
    padding: '25px'
  };

    const gornjiDeo = {
        display: 'flex',
        padding: '15px'
      };
    const mojiPodaci = {
        width: '40%',
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'space-between',
        height: '100%',
      };
    const faktura = {
        fontSize: '30px'
      };  
    const mojiPodaci1 = {
        borderLeft: '1px solid black',
        borderTop: '1px solid black',
        borderRight: '1px solid black',
      }; 
    const mojeImeText = {
        fontSize: '21px',
        paddingLeft: '10px'
      };   
    const mojiPodaci2 = {
        border: '1px solid black',
        flexGrow: 1,
      };   
    const mojiPodaciText = {
        fontSize: '16px',
        paddingLeft: '10px'
      };
    const prazanMedjuDiv = {
        width: '10%'
      };
    const klijentoviPodaci = {
        width: '50%',
        display: 'flex',
        flexDirection: 'column' as const,
        height: '100%'
      };
    type TextAlign = 'left' | 'center' | 'right' | 'justify' | 'initial' | 'inherit';
    const datumBroj = {
        //display: 'flex',
        flexWrap: 'wrap' as const,
        width: '90%',
        marginLeft: 'auto', /* Koristimo margin-left: auto; kako bismo pomaknuli datumBroj s desne strane. */
        textAlign: 'center' as TextAlign,
        //padding: '5px'
        marginBottom: '5px'
      };  
      
      const datumBrojGornjiRed = {
        display: 'flex'
      };  
      const datumBrojDonjiRed = {
        display: 'flex',
        minHeight: '30px',
        
      }; 
      const datumFak = {
        width: '33.3333%',
        border: '1px solid black', 
        borderRight: 'none'
      };
      const datumDosp = {
        width: '33.3333%',
        border: '1px solid black', 
      };
    const Broj = {
        width: '33.3333%',
        border: '1px solid black',
        borderLeft: 'none' /* Uklanjamo lijevu granicu za Broj. */
      };
    const datumFakIspis = {
      width: '33.3333%',
        border: '1px solid black',
        borderRight: 'none',
        borderTop: 'none'
      };

    const datumDospIspis = {
        width: '33.3333%',
          border: '1px solid black',
          borderTop: 'none'
        };
    const brojIspis = {
        width: '33.3333%',
        border: '1px solid black',
        borderLeft: 'none', /* Uklanjamo lijevu granicu za brojIspis. */
        borderTop: 'none' /* Uklanjamo gornju granicu za brojIspis. */
      };

    const klijentoviPodaci2 = {
        borderLeft: '1px solid black',
        borderTop: '1px solid black',
        borderRight: '1px solid black',
        paddingLeft: '10px'
      };
    const kupac = {
        fontSize: '21px'
      };
    const klijentoviPodaci3 = {
        border: '1px solid black',
        padding: '5px'
      };
    const donjiDeo = {
        padding: '15px'
      };
    const tabela = {
        width: '100%'
      };
    const th = {
      border: '1px solid black',
      borderLeft: 'none'
      };
    const td = {
        border: '1px solid black',
        borderLeft: 'none',
        borderTop: 'none'
       };
    const footer = {
      flex: 1,
      padding: '15px',
      display: 'flex', 
      height: '100px',      
       };
    const footerLevo = {
      //flex: 1, 
      border: '1px solid black',
      width: '50%',
      
         };
    const footerSredina = {
    //flex: 1, 
    width: '10%',
        };
         
    const footerDesno: React.CSSProperties = {
      display: 'flex',
      flexDirection: 'column',
      //flex: 1,
      width: '40%',
      };
    const footerDesnoGore = {
     // flex: 1, 
     fontSize: '20px',
      paddingLeft: '20px',
      paddingRight: '20px',
      border: '1px solid black',
      margin: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    };
    const footerDesnoDole = {
      flex: 1,
      fontSize: '19px',
      paddingLeft: '20px',
      paddingRight: '20px',
      border: '1px solid black',
      margin: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    };

    const divDodavanjeStavke = {
      display: 'flex',
      background: 'lightgrey',
      padding: '10px'
    };
    
       
    //moji admin podaci
    const [ime, setIme] = useState('');
    const [adresa, setAdrese] = useState('');
    const [mestoOtpreme, setMestoOtpreme] = useState('');
    const [pib, setPib] = useState(0);
    const [maticniBr, setMaticniBr] = useState(0);
    const [sifraDelatnosti, setSifraDelatnosti] = useState(0);
    const [zr, setZr] = useState('');
    const [opis, setOpis] = useState('');
    const [telefon, setTelefon] = useState(0);
    const [fixtelefon, setFixTelefon] = useState(0);
    const [email, setEmail] = useState('');  
    //moji admin podaci

    //klijentovi podaci
    const [idKlijenta, setIdKlijenta] = useState(0);
    const [tipKlijenta, setTipKlijenta] = useState('');
    const [imeKlijenta, setImeKlijenta] = useState('');
    const [adresaKlijenta, setAdresaKlijenta] = useState('');
    const [mestoKlijenta, setMestoKlijenta] = useState('');
    const [pibKlijenta, setPibKlijenta] = useState('');
    const [maticniBrKlijenta, setMaticniBrKlijenta] = useState('');
    const [telefonKlijenta, setTelefonKlijenta] = useState('');
    //klijentovi podaci

    //podaci o fizickom licu
    const [flImePrezime, setFlImePrezime] = useState('');
    const [flAdresa, setFlAdresa] = useState('');
    const [flMesto, setFlMesto] = useState('');
    const [flLicnaKarta, setFlLicnaKarta] = useState('');
    const [flTelefon, setFlTelefon] = useState('');
    //podaci o fizickom licu

    //faktura podaci
    const [brojFakture, setBrojFakture] = useState('');
    const [datumFakture, setDatumFakture] = useState('');
    const [datumDospeca, setDatumDospeca] = useState('');
    const [ukupnaPdvFak, setUkupnaPdvFak] = useState(0);
    const [ukupnaVrednostFak, setUkupnaVrednostFak] = useState(0);

    //faktura podaci
    const [stavkeFakture, setStavkeFakture] = useState([]);


    const [prikaziDugmeProglasiZaFakturu, setPrikaziDugmeProglasiZaFakturu] = useState<any>(false);
    const [prikaziDugmeFiskalniPrinter, setPrikaziDugmeFiskalniPrinter] = useState<any>(false);

    const PodaciPredracuna = async () => {     
      fetch(`${defaultniUrl}podaci_fakture`, {
              method: 'POST', 
              headers: {
              'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                idFakture:idPredracuna
                      })        
                  }).then(res => res.json())
                  .then(data => {
                      if(data !== ""){  
                         //admin podaci                 
                         setIme(data.admin[0].ime);
                         setAdrese(data.admin[0].adresa);
                         setMestoOtpreme(data.admin[0].mesto_otpreme);
                         setPib(data.admin[0].pib);
                         setMaticniBr(data.admin[0].maticni_br);
                         setSifraDelatnosti(data.admin[0].sifra_delatnosti);
                         setZr(data.admin[0].ziro_racun);
                         setOpis(data.admin[0].opis);
                         setTelefon(data.admin[0].telefon);
                         setFixTelefon(data.admin[0].fix_telefon);
                         setEmail(data.admin[0].email);

                         //klijent podaci
                         setIdKlijenta(data.faktura_klijent[0].id_klijenta);
                         setTipKlijenta(data.faktura_klijent[0].tip_klijenta);
                         setImeKlijenta(data.faktura_klijent[0].ime);
                         setAdresaKlijenta(data.faktura_klijent[0].adresa);
                         setMestoKlijenta(data.faktura_klijent[0].mesto);
                         setPibKlijenta(data.faktura_klijent[0].pib);
                         setMaticniBrKlijenta(data.faktura_klijent[0].maticni_br);
                         setTelefonKlijenta(data.faktura_klijent[0].telefon);


                         //fizicko lice podaci
                         setFlImePrezime(data.faktura_klijent[0].fl_ime_prezime);
                         setFlAdresa(data.faktura_klijent[0].fl_adresa);
                         setFlMesto(data.faktura_klijent[0].fl_mesto);
                         setFlLicnaKarta(data.faktura_klijent[0].fl_licna_karta);
                         setFlTelefon(data.faktura_klijent[0].fl_telefon);


                         //faktura podaci
                         setBrojFakture(data.faktura_klijent[0].broj);
                         setDatumFakture(data.faktura_klijent[0].datum_pravljenja_fakture);
                         setDatumDospeca(data.faktura_klijent[0].datum_dospeca);
                         setUkupnaPdvFak(data.faktura_klijent[0].ukupan_pdv);
                         setUkupnaVrednostFak(data.faktura_klijent[0].ukupna_vrednost);


                         //stavke fakture                      
                         
                         const stavkeFakture = data.stavke_fakture.map((row:any, index:any) =>  (
                          <tr key={row.id} id={row.id}>
                             <td style={{...td, borderLeft: '1px solid black'}} align="center">{index+1}.</td>
                             <td style={td} align="center">{row.sifra}</td>
                             <td style={td} align="center">{row.ime}</td>
                             <td style={td} align="center">{/*row.kolicina*/} <span className="prikazKolicine" style={{display:"none"}} >{row.kolicina}</span>  <input className="kolonaUkloni" style={{ width: "70px" }} type="text" placeholder={row.kolicina} /> </td>
                             <td style={td} align="center">{row.cena}</td>
                             <td style={td} align="center">{row.poreska_osnovica}</td>
                             <td style={td} align="center">{row.rabat}</td>
                             <td style={td} align="center">{row.iznos_pdv}</td>
                             <td style={td} align="center">{row.ukupna_vrednost}</td>
                             <td style={td} align="center" className="kolonaUkloni"><Button style={{ }} size="small" onClick={() => izmeniStavkuIzPredracuna(row.id)}  variant="contained" color="primary" >Izmeni</Button></td>
                             <td style={td} align="center" className="kolonaUkloni"><Button style={{ }} size="small" onClick={() => ukloniStavkuIzPredracuna(row.id)}  variant="contained" color="error" ><CancelIcon/></Button></td>
                          </tr>
                        ))
                        setStavkeFakture(stavkeFakture);

                        data.stavke_fakture.map((red:any, indeks:any) => {  //punimo redux
                            const stavkeFakture = {
                              id: red.id,
                              id_artikla: red.id_artikla,
                              sifra: red.sifra,
                              ime: red.ime, 
                              kolicina: red.kolicina,
                              cena: red.cena,
                              poreska_osnovica: red.poreska_osnovica,
                              ukupna_vrednost: red.ukupna_vrednost,
                              stanje: red.stanje,
                              iznos_rabata:red.rabat,
                              iznos_pdv:red.iznos_pdv
                            };
                            return dispatch(dodajStavkeFakture(stavkeFakture));    //OBAVEZNO OVAKO JEDNU PO JEDNU STAVKU DA DODAJEMO U IZ OBJEKTA U NOVI REDUX 
                        })
  
                      }              
                  })
  }  

    useEffect(() => { 
      PodaciPredracuna(); 
    },[]);

    const izmeniStavkuIzPredracuna = (id_reda:number) => { 

      const tabelaStavke = document.getElementById('tabelaStavke') as HTMLElement;    //gadjam tabelu sa stavkama
      const red = tabelaStavke.querySelector(`tr[id="${id_reda}"]`);                  //gadjam red
      const treciElement = red?.querySelectorAll('td')[3];                            //gadjam 4 element (kolicina)
      const inputElement = treciElement?.querySelector('input');                      //u njemu pronalazim input
      const novaKolicina = inputElement?.value;                                       //uzimam njegovu vrednost
 

      fetch(`${defaultniUrl}izmeni_stavku_iz_predracuna`, {
        method: 'POST', 
        headers: {
        'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id_reda:id_reda,
          novaKolicina:novaKolicina
                })        
            }).then(res => res.json())
            .then(data => {

                if(data.poruka == "uspesno"){
                  PodaciPredracuna();
                }
            })
    }

  
    const ukloniStavkuIzPredracuna = (id_reda:any) => { 

      fetch(`${defaultniUrl}ukloni_stavku_iz_predracuna`, {
        method: 'POST', 
        headers: {
        'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id_reda:id_reda
                })        
            }).then(res => res.json())
            .then(data => {

                if(data.poruka == "uspesno"){
                  PodaciPredracuna(); 
                }
            })
    }


    const dodajStavkuUPredracun = () => { 

            const postojiIdArtikla = stavkeFaktureZbogStanja.some(stavka => stavka.id_artikla === idArtiklaDodajNovi); //provera da li je idArtikla vec u objektu
            
            if (!postojiIdArtikla) {
                    fetch(`${defaultniUrl}dodaj_stavku_u_predracun`, {
                      method: 'POST', 
                      headers: {
                      'Content-Type': 'application/json'
                      },
                      body: JSON.stringify({
                        idPredracuna:idPredracuna,
                        idArtiklaDodajNovi:idArtiklaDodajNovi,
                        kolicinaDodajNovi:kolicinaDodajNovi,
                        odabraniRabatIdNaPredracunu:odabraniRabatIdNaPredracunu,
                        odabraniRabatVrednostNaPredracunu:odabraniRabatVrednostNaPredracunu,
                        tipKlijenta:tipKlijenta
                              })        
                          }).then(res => res.json())
                          .then(data => {

                              if(data.poruka == "uspesno"){
                                dispatch(setReloadKomponenteArtikli(reloadKomponenteArtikli+1));  
                                dispatch(setReloadKomponenteRabat(reloadKomponenteRabat+1)); 
                                dispatch(setIdArtiklaDodajNovi(0));
                                dispatch(setOdabraniRabatIdNaPredracunu(1));
                                dispatch(setOdabraniRabatVrednostNaPredracunu(0));
                                setKolicinaDodajNovi(1);
                                PodaciPredracuna(); 
                                
                              }
                          })
            } else {
                const trazenaStavka = stavkeFaktureZbogStanja.find(stavka => stavka.id_artikla === idArtiklaDodajNovi);    //uzimamo ime na osnovu id artikla
                  if (trazenaStavka) {
                      var ime_a =  trazenaStavka.ime        
                      MySwal.fire({
                        title: `Artikal ${ime_a} je već dodat!`, 
                        //text: '',
                        //icon: 'info',
                        timer: 4000,
                        showCancelButton: false,
                        showConfirmButton: false,
                        icon: 'warning',
                        //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                        }) 
                  }
            }
    }

    const stampajFakturu = () => { 

      const kolonaUkloniList = document.getElementsByClassName('kolonaUkloni') as HTMLCollectionOf<HTMLElement>;
      const prikazKolicine = document.getElementsByClassName('prikazKolicine') as HTMLCollectionOf<HTMLElement>;
      const divDodavanjeStavke = document.getElementById('divDodavanjeStavke') as HTMLElement;
      
      
      if (kolonaUkloniList.length > 0) {                             //krijemo kolonu UKLONI
          for (let i = 0; i < kolonaUkloniList.length; i++) {
              kolonaUkloniList[i].style.display = 'none';
          }
      }
      if (prikazKolicine.length > 0) {                             //prikazujemo zamenski span umesto inputa za izmenu kolicine....po default-u span je skriven a input prikazan
        for (let i = 0; i < prikazKolicine.length; i++) {
          prikazKolicine[i].style.display = 'block';
        }
    }
    divDodavanjeStavke.style.display = 'none';

      const content = document.getElementById('faktura');
      const printWindow = window.open('', '', 'width=1200,height=1000');
        if (printWindow) {
            if (content) {
              printWindow.document.write('<html><head><title>Faktura</title></head><body>');
        printWindow.document.write(content.innerHTML);
        printWindow.document.write('</body></html>');
       
        printWindow.print();
        printWindow.close();
        for (let i = 0; i < kolonaUkloniList.length; i++) {           //vracamo kolonu UKLONI
          kolonaUkloniList[i].style.display = '';
      }
      for (let i = 0; i < prikazKolicine.length; i++) {
        prikazKolicine[i].style.display = 'none';
      }
      divDodavanjeStavke.style.display = '';
            }
        }
    }


    useEffect(() => {
      
      setPrikaziDugmeProglasiZaFakturu(tipKlijenta == "Pravno")       //dugme posalji emailom prikazi za fakture na cekanju, Poslate na E-fakture i za izvrsene (sve osim kad su stornirane)
      setPrikaziDugmeFiskalniPrinter(tipKlijenta == "Fizičko");           //dugme storniraj prikazi samo u slucaju kada je status fakture na cekanju
      
}, [tipKlijenta]);



function konvertDatuma(datum:any){
            const selectedDateOnly = new Date(datum);
            const day = String(selectedDateOnly.getDate()).padStart(2, '0');
            const month = String(selectedDateOnly.getMonth() + 1).padStart(2, '0');
            const year = selectedDateOnly.getFullYear();
            const datumFakture = `${day}/${month}/${year}`;
            return datumFakture;
}

function openSwal() {
      Swal.fire({
        title: 'Obavezni podaci',
        showCancelButton: true,
        cancelButtonText: 'Odustani',
        confirmButtonText: 'Sačuvaj',
        confirmButtonColor: '#14B8A6', //#00FF00
        html: `
          <div style='display: grid'>
            <span>Datum fakture</span>
            <input type="date" id="input1" class="swal2-input" placeholder="Input 1" required><br/>
            <span>Datum dospeća</span>
            <input type="date" id="input2" class="swal2-input" placeholder="Input 2" required>
          </div>
        `,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const input1Element = document.getElementById('input1') as HTMLInputElement;
          const input2Element = document.getElementById('input2') as HTMLInputElement;
    
          if (input1Element.checkValidity() && input2Element.checkValidity()) {

            const datumFakture = konvertDatuma(input1Element.value);
            const datumDospeca = konvertDatuma(input2Element.value);

                                                    fetch(`${defaultniUrl}predracun_postaje_faktura`, {
                                                        method: 'POST', 
                                                        headers: {
                                                        'Content-Type': 'application/json'
                                                        },
                                                        body: JSON.stringify({
                                                            idPredracuna:idPredracuna,
                                                            stavkeFaktureZbogStanja:stavkeFaktureZbogStanja,
                                                            datumFakture:datumFakture,
                                                            datumDospeca:datumDospeca
                                                                })        
                                                            }).then(res => res.json())
                                                            .then(data => {
                                                                
                                                                if(data.poruka == "uspesno"){

                                                                    MySwal.fire({
                                                                        title: 'Faktura uspešno kreirana',
                                                                        //text: '',
                                                                        //icon: 'info',
                                                                        timer: 4000,
                                                                        showCancelButton: false,
                                                                        showConfirmButton: false,
                                                                        icon: 'success',
                                                                        //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                                                                    })
                                                                    dispatch(isprazniStavkeFakture());//obavezno svaki put isprazniti
                                                                    dispatch(setStranica(1)); 
                                                                  }                        
                                                    }) 
            // console.log('Unesena vrednost input1:', datumFakture);
            // console.log('Unesena vrednost input2:', datumDospeca);
          } else {
            Swal.close(); // Zatvori dijalog ako polja nisu validna
            openSwal(); // Ponovno otvori dijalog
          }
        }
      });
    }


const proglasiZaFakturu = () => { 

  Swal.fire({
    title: 'Da li želite da proglasite za fakturu?',
    icon: 'question',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Da',
    cancelButtonText: 'Otkaži',
  }).then((result) => {
    if (result.isConfirmed) {


            const daLiJeObjekatPrazan = Object.keys(stavkeFaktureZbogStanja).length === 0;
            //zakomentarisano za sada da vidim prvo sta zeli - prvo provera ako nije predracun na fizicko lice nego na nekog drugog klijenta onda se moze pretvoriti u fakturu koja ce se mozda slati na e-fakture
            //zatim ako je faktura za fizicko lice onda vracamo alert
            //zatim proveravamo da li faktura ima stavke
            //zatim otvaramo sweetalert i iz njega preuzimamo podatke o datumu fakture i datumu dospeca
            //zatim provrtimo sve stavke predracuna i proveravamo da li svih stavki ima na stanju
            //ako ih ima na stanju onda promeni iz predracun u faktura 
            //a ako ih nema vrati alert koje stavke nama na stanju
            //OVA PROVERA NAM TREBA JER SE PRILIKOM PRAVLJENJA PREDRACUNA NE ZADUZUJE STANJE (kao sto je slucaj sa fakturom)
            
              //if(tipKlijenta !== "Fizičko"){  //da nije fizicko lice
                
                if(!daLiJeObjekatPrazan){
                
                                  var imenaRedova = '';
                                  console.log(stavkeFaktureZbogStanja);
                                      stavkeFaktureZbogStanja.map((red:any, indeks:any) => {  
                                        if(parseInt(red.kolicina) >= parseInt(red.stanje)){ 
                                            imenaRedova += red.ime + ', <br/>';
                                                        }
                                      })
                                      imenaRedova = imenaRedova.slice(0, -7);
                                  if(imenaRedova == ""){
                                      openSwal(); // Pokreni dijalog
                                  }else{
                                      MySwal.fire({
                                        title: 'Nemate dovoljno: <br/>'+ imenaRedova +'<br/> na stanju',
                                        //text: '',
                                        //icon: 'info',
                                        //timer: 4000,
                                        showCancelButton: false,
                                        showConfirmButton: false,
                                        icon: 'error',
                                        //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                                    })
                                  }
                                                                                                            
                                    

                }else{
                  MySwal.fire({
                    title: 'Ova faktura nema stavke',
                    //text: '',
                    //icon: 'info',
                    timer: 4000,
                    showCancelButton: false,
                    showConfirmButton: false,
                    icon: 'error',
                    //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                    })
                }
    
      } 
      // else {
      //   // Ako je korisnik otkazao
      //   Swal.fire('Otkazano', '', 'error');
      // }
    });
}


const posaljiNaEmail = () => { 

  const kolonaUkloniList = document.getElementsByClassName('kolonaUkloni') as HTMLCollectionOf<HTMLElement>;
  const prikazKolicine = document.getElementsByClassName('prikazKolicine') as HTMLCollectionOf<HTMLElement>;
  const divDodavanjeStavke = document.getElementById('divDodavanjeStavke') as HTMLElement;

  if (kolonaUkloniList.length > 0) {                             //krijemo kolonu UKLONI
    for (let i = 0; i < kolonaUkloniList.length; i++) {
        kolonaUkloniList[i].style.display = 'none';
    }
  }
  divDodavanjeStavke.style.display = 'none';                    //krijemo opciju za dodavanje stavke
  if (prikazKolicine.length > 0) {                             //prikazujemo zamenski span umesto inputa za izmenu kolicine....po default-u span je skriven a input prikazan
    for (let i = 0; i < prikazKolicine.length; i++) {
      prikazKolicine[i].style.display = 'block';
    }
}

  
  const element = document.getElementById('faktura');



  if (element) {

      const htmlContent = element.innerHTML; // Dobijanje sadržaja HTML elementa kao string
      const odakle_se_salje = "predracun";

              // Slanje podataka na server (primer korišćenja fetch API-ja)
              fetch(`${defaultniUrl}posaljiEmail`, {
                  method: 'POST',
                  body: JSON.stringify({ pdfData: htmlContent, odakle_se_salje: odakle_se_salje  }),
                  headers: {
                      'Content-Type': 'application/json'
                  }
              })
              .then(response => {
                  // Obrada odgovora od servera
                  if (response.ok) {

                         //     if(data.poruka == "uspesno"){

                                MySwal.fire({
                                    title: 'Predračun poslat na email',
                                    //text: '',
                                    //icon: 'info',
                                    timer: 4000,
                                    showCancelButton: false,
                                    showConfirmButton: false,
                                    icon: 'success',
                                    //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                                })
                                
                             // } 
                      // Uspešno poslato
                      //console.log('Podaci uspešno poslati na server.');


                      for (let i = 0; i < kolonaUkloniList.length; i++) {          //vracamo kolonu UKLONI
                        kolonaUkloniList[i].style.display = '';
                       }
                      divDodavanjeStavke.style.display = '';                       //vracamo opciju za dodavanje stavke
                      for (let i = 0; i < prikazKolicine.length; i++) {
                        prikazKolicine[i].style.display = 'none';                  //uklanjamo zamenski span
                      }

                  } else {
                      // Greška prilikom slanja
                      console.error('Došlo je do greške prilikom slanja podataka na server.');

                      for (let i = 0; i < kolonaUkloniList.length; i++) {          //vracamo kolonu UKLONI
                        kolonaUkloniList[i].style.display = '';
                       }
                      divDodavanjeStavke.style.display = '';                       //vracamo opciju za dodavanje stavke
                      for (let i = 0; i < prikazKolicine.length; i++) {
                        prikazKolicine[i].style.display = 'none';                  //uklanjamo zamenski span
                      }
                  }
              })
              .catch(error => {
                  console.error('Došlo je do greške:', error);
              });
          
  } else {
      console.error('Element sa ID-em "faktura" nije pronađen.');
      // Dodajte kod za obradu greške ako je potrebno
  }
  
}





    const nazadNaPredracune = () => { 
        dispatch(isprazniStavkeFakture());
        dispatch(setStranica(1));   

        dispatch(setIdArtiklaDodajNovi(0));
        dispatch(setOdabraniRabatIdNaPredracunu(1));
        dispatch(setOdabraniRabatVrednostNaPredracunu(0));
        setKolicinaDodajNovi(1);

      }


    const obrisiPredracun = () => { 

              fetch(`${defaultniUrl}obrisi_fakturu`, {
                    method: 'POST', 
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                      idFakture:idPredracuna
                            })        
                        }).then(res => res.json())
                        .then(data => {
                            
                            if(data.poruka == "uspesno"){

                                MySwal.fire({
                                    title: 'Predračun obrisan',
                                    //text: '',
                                    //icon: 'info',
                                    timer: 4000,
                                    showCancelButton: false,
                                    showConfirmButton: false,
                                    icon: 'success',
                                    //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                                })
                                dispatch(isprazniStavkeFakture());//obavezno svaki put isprazniti
                                dispatch(setStranica(1)); 
                              }                        
                        }) 
    }
    
    
    var statusStyle = {
      padding: '5px',
      background: 'darkgray',
      width: '100%',
      color: 'white',
      textAlign: 'center' as TextAlign, // Horizontalno centriranje teksta
      lineHeight: '2em', // Vertikalno centriranje teksta
      fontSize: '24px'
    };

    
      
return (
    <div  style={okvir}>
        <div style={statusStyle}><b>{tipKlijenta}</b></div>
              <div id="faktura">
                      <div style={gornjiDeo}>
                          <div style={mojiPodaci}>
                              <h2 style={faktura}>PREDRACUN</h2> 
                              <div style={mojiPodaci1}>
                                      <span style={mojeImeText}>{ime}</span>
                              </div>
                              <div style={mojiPodaci2}>
                                      <p style={mojiPodaciText}>{adresa}</p>
                                      <p style={mojiPodaciText}>USTANICKA 188/44/4</p>
                                      <p style={mojiPodaciText}>ZIRO RACUN: {zr}</p>
                                      <p style={mojiPodaciText}>PIB: {pib} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   PDV: 0000000</p>
                              </div>
                          </div>

                          <div style={prazanMedjuDiv}></div>

                          <div style={klijentoviPodaci}>
              
                              <div style={datumBroj}> 
                                        <div style={datumBrojGornjiRed}>
                                          <div style={datumFak}>
                                              <p>Datum<br/>Fakture</p>
                                          </div>
                                          <div style={datumDosp}>
                                              <p>Datum<br/>dospeca</p>
                                          </div>
                                          <div style={Broj}>
                                              <p>Broj</p> 
                                          </div>

                                        </div>


                                        <div style={datumBrojDonjiRed}>
                                              <div style={datumFakIspis}>                      
                                                  {datumFakture}
                                              </div>
                                              <div style={datumDospIspis}>
                                                  {datumDospeca}
                                              </div>
                                              <div style={brojIspis}>
                                                  {brojFakture}
                                              </div>
                                        </div>
                              </div>



                              <div>
                                  <div style={klijentoviPodaci2}>
                                          <span style={kupac}>KUPAC</span>
                                  </div>
                                  <div style={klijentoviPodaci3}>
                                          <p>{imeKlijenta ? imeKlijenta : flImePrezime}</p> 
                                          <p>{adresaKlijenta ? adresaKlijenta : flAdresa}</p>
                                          <p>{mestoKlijenta ? mestoKlijenta : flMesto}</p>

                                          {pibKlijenta ? ( <p>PIB: {pibKlijenta} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Telefon: {telefonKlijenta}</p>
                                                ) : (<p>L.K. {flLicnaKarta} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Telefon: {flTelefon}</p>)}

                                          
                                  </div>
                              </div>
                          </div>
                      </div>





                      <div style={donjiDeo}>
                              <table style={tabela} cellSpacing ="0" id="tabelaStavke">
                                  
                                      <thead>
                                          <tr>
                                              <th style={{...th, borderLeft: '1px solid black'}}>R.B</th>
                                              <th style={th}>SIFRA</th>
                                              <th style={th}>ARTIKAL</th>
                                              <th style={th}>KOLICINA</th>
                                              <th style={th}>CENA</th>
                                              <th style={th}>P.OSNOVICA</th>
                                              <th style={th}>RABAT</th>
                                              <th style={th}>PDV</th>
                                              <th style={th}>UKUPNO</th>
                                              <th style={th} className="kolonaUkloni">IZMENI</th>
                                              <th style={th} className="kolonaUkloni">UKLONI</th>
                                          </tr>
                                      </thead>

                                      <tbody>
                                        {stavkeFakture}
                                      </tbody>
                                  
                              </table>
          
                              <br/>

                              <div id="divDodavanjeStavke" style={divDodavanjeStavke}>
                                <SifarnikArtikli lokacija="predracun"/> &nbsp;&nbsp;
                                <TextField 
                                  onChange={(e:any) => { setKolicinaDodajNovi(e.target.value);  } }
                                  label="Količina"
                                  variant="outlined"
                                  required
                                  type="number"
                                  value={kolicinaDodajNovi} //ako je kolicina 0 onda da isprazni u suprotnom da prikaze vrednost
                                  style={{ width: '130px'}}                 
                                  />
                                  &nbsp;&nbsp;
                                  <SifarnikRabat lokacija="predracun"/>&nbsp;&nbsp;
                                  <Button size="large" onClick={() => dodajStavkuUPredracun()} variant="contained" color="success" >Dodaj</Button>
                              </div>


                      </div>


                      <div style={footer}>
                          <div style={footerLevo}>
                              MAT.BROJ {maticniBr}, <br/>   SIFRA DELATNOSTI {sifraDelatnosti}
                          </div>
                          <div style={footerSredina}>

                          </div>
                          <div style={footerDesno}>
                              <div style={footerDesnoGore}>
                                  <span>PDV</span>      {ukupnaPdvFak}
                              </div>
                              <div style={footerDesnoDole}>
                                  <b>UKUPNO</b>   Din: {ukupnaVrednostFak}
                              </div>
                          </div>
                      </div>   
              </div> 


        <div> 
            
            { prikaziDugmeProglasiZaFakturu    && <><Button style={{ width: '200px'}} variant="contained" color="success"  onClick={() => proglasiZaFakturu()}>Proglasi za fakturu</Button>&nbsp;</> }


            
            { prikaziDugmeFiskalniPrinter    && <><Button style={{ width: '200px'}} variant="contained" color="primary"  /*onClick={() => proglasiZaFakturu()}*/>Fiskalni printer</Button>&nbsp;</> }
            <Button style={{ width: '150px'}} variant="contained" color="info"  onClick={() => stampajFakturu()}>Štampaj</Button>&nbsp;
            <Button style={{ width: '150px'}} variant="contained" color="error"  onClick={() => obrisiPredracun()}>Obriši</Button>&nbsp; 
            <Button style={{ width: '150px'}} variant="contained" color="primary"  onClick={() => posaljiNaEmail()}>Pošalji na email</Button>&nbsp;
            <Button style={{ width: '150px'}} variant="contained" sx={{color:'black'}} color="warning"  onClick={() => nazadNaPredracune()}>Nazad</Button> 
        </div>                 
    </div>
);

   
}

export default PrikazPredracuna;