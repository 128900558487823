 import { TabelaMagacin } from './tabela_magacin';
 import { DodajNoviArtikal } from './dodaj_novi_artikal';
 import { IzmeniArtikal } from './izmeni_artikal';
 import { useLocation } from 'react-router-dom';


import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import {  useEffect } from "react";
import { setStranica } from '../../redux/stranice/magacin';

export const Glavna_magacin = () => {

   const stranica = useSelector((state: RootState) => state.magacin.stranica);
   const dispatch = useDispatch();

//OVO UVEK KADA SE DODJE NA STRANICU RESETUJE PRVO SVE PROMENLJIVE U REDUXU....PA POSLE NEK RADI KAKO HOCE
const location = useLocation();
useEffect(() => {
  if (location.pathname === '/magacin') {
      dispatch(setStranica(1));  
  }
}, [location.pathname]);
//OVO UVEK KADA SE DODJE NA STRANICU RESETUJE PRVO SVE PROMENLJIVE U REDUXU....PA POSLE NEK RADI KAKO HOCE

 


 return(
   <>
     {stranica === 1 && <TabelaMagacin/>}
     {stranica === 2 && <DodajNoviArtikal/>}
     {stranica === 3 && <IzmeniArtikal/>}
   </>
 )
};


export default Glavna_magacin;