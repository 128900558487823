import DataTable from "react-data-table-component";
import TextField from '@mui/material/TextField';
import { Button, Box, Typography } from '@mui/material';
import { useState, useEffect } from "react";


import { useDispatch, useSelector } from 'react-redux';
//import { RootState } from '../../redux/store';
import { setStranica, setIdKlijenta } from '../../redux/stranice/klijenti';
import { RootState } from '../../redux/store';

type Klijenti = {
  id: number;
  ime: string;
  adresa: string;
  mesto: string;
  pib: string;
  telefon: string;
  ziro_racun: string;
};


export const TabelaKlijenti = (props:any) => {

    const dispatch = useDispatch();
    const [data, setData] = useState<Klijenti[]>([]);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState<Klijenti[]>([]);
    const defaultniUrl = useSelector((state: RootState) => state.defaultPodaci.defaultniUrl);

    const dodajNovogKlijenta = () => {
      dispatch(setStranica(2));
    };

    const izmeniKlijenta = (row:any) => {
        
        const idKlijenta = row.id;
        dispatch(setIdKlijenta(idKlijenta));
        dispatch(setStranica(3));
    };

        

      const columns:any = [
        // {
        //     name: 'RB.',
        //     cell: (row:any, index: any) => index+1+'.',
        // },
        {
          name: "IME",
          selector: (row: Klijenti) => row.ime.toString(),
        },
        {
          name: "ADRESA",
          selector: (row: Klijenti) => row.adresa.toString(),
        },
        { 
            name: "MESTO",
            selector: (row: Klijenti) => row.mesto.toString(),
        },
        {
            name: "PIB",
            selector: (row: Klijenti) => row.pib.toString(),
        },
        {
            name: "TELEFON",
            selector: (row: Klijenti) => row.telefon.toString(),
        },
        {
            name: "ŽIRO RAČUN",
            selector: (row: Klijenti) => row.ziro_racun.toString(),
        }
      ];


    const Klijenti = async () => {
 
            fetch(`${defaultniUrl}klijenti`, {
                    method: 'POST', 
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                    
                            })        
                        }).then(res => res.json())
                        .then(data => {
                            const filteredData = data.filter((item: any) => item.id !== 1);  //filterom uklanjamo klijenta sa ID-em 1....to je Fizicko lice
                            setData(filteredData);
                            setFilter(filteredData);                           
                        })
    }

    useEffect(() => { 
        Klijenti();  
    },[]);


    useEffect(() => { 
      const result = data.filter((item)=>{
        return (
          item.ime.toLowerCase().includes(search.toLowerCase()) || // Pretražujemo po imenu
          item.adresa.toLowerCase().includes(search.toLowerCase()) || // Pretražujemo po adresi
          item.mesto.toLowerCase().includes(search.toLowerCase()) || // Pretražujemo po mestu
          item.pib.toString().toLowerCase().includes(search.toLowerCase()) || // Pretražujemo po pib-u
          item.telefon.toLowerCase().includes(search.toLowerCase()) || // Pretražujemo po telefonu
          item.ziro_racun.toLowerCase().includes(search.toLowerCase())
        )
      }) 
      setFilter(result);
    },[search]);

    return(
      <>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                m:2
              }}
            >
              <Typography sx={{ m: 1 }} variant="h4">KLIJENTI</Typography>
              <Box sx={{ m: 1 }}>
                  <Button  onClick={dodajNovogKlijenta} color="success" variant="contained">Dodaj novog klijenta </Button>
              </Box>
            </Box>
          
            <br/>
            <Box style={{padding:"20px"}}>              
                <DataTable
                      columns={columns}
                      data={filter}
                      pagination
                      fixedHeader
                      highlightOnHover
                      //selectableRowsHighlight
                      //fixedHeaderScrollHeight='450px'
                      // selectableRows
                      //  actions={<button className='btn btn-sm btn-info'>Dogadjaj</button>}
                      onRowClicked={izmeniKlijenta}
                      subHeader
                      subHeaderComponent={
                        <TextField 
                              size="small"
                              onChange={(e)=>setSearch(e.target.value)}
                              label="Pretraži"
                              variant="outlined"
                              value={search}
                              style={{ width: '300px'}}   
                          />
                        
                      }
                      // className={tableClassName}
                />
            </Box>
      </>   
     )
}

export default TabelaKlijenti;