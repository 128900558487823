import { GlavnaPredracuni } from '../components/fakture/predracuni/glavna_predracuni';

function predracuni() {

return(

   <GlavnaPredracuni/>

)
}

export default predracuni;