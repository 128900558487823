import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useState, useEffect } from "react";
import { Button, Box, Container, TextField, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { SifarnikKlijenti } from '../../selectSifarnici/klijenti';
import { setIdKlijentaZaIzv, setImeKlijentaZaIzv } from '../../../redux/stranice/izvZbirniArtikli';

export const Filteri_izvZbirniArtikli = (props:any) => {

    const dispatch = useDispatch();
    const defaultniUrl = useSelector((state: RootState) => state.defaultPodaci.defaultniUrl);
    const MySwal = withReactContent (Swal); //sweetalert2
    const idKlijentaZaIzv = useSelector((state: RootState) => state.izvZbirniArtikli.idKlijentaZaIzv);
    const imeKlijentaZaIzv = useSelector((state: RootState) => state.izvZbirniArtikli.imeKlijentaZaIzv);
    const [datumOd, setDatumOd] = useState<Date | null>(null);
    const [datumDo, setDatumDo] = useState<Date | null>(null);

    const [datumOdFormatiran, setDatumOdFormatiran] = useState('');
    const [datumDoFormatiran, setDatumDoFormatiran] = useState('');

    const [status, setStatus] = useState('');
    const [stavkeIzvestaja, setStavkeIzvestaja] = useState('');
    const odabirStatusa = (event:any) => { setStatus(event.target.value); };

    const [ukupnaPoreskaOsnovica, setUkupnaPoreskaOsnovica] = useState(0); 
    const [ukupanRabat, setUkupanRabat] = useState(0); 
    const [ukupanIznosPdv, setUkupanIznosPdv] = useState(0);
    const [ukupnoSve, setUkupnoSve] = useState(0);
    const [staSePokazuje, setStaSePokazuje] = useState(true);
    const [prevodStatusa, setPrevodStatusa] = useState('');
    

    useEffect(() => { 
        if (datumOd !== null) {
                const selectedDateOnly = new Date(datumOd);
                const day = String(selectedDateOnly.getDate()).padStart(2, '0');
                const month = String(selectedDateOnly.getMonth() + 1).padStart(2, '0');
                const year = selectedDateOnly.getFullYear();
                const formattedDateOd = `${day}/${month}/${year}`;  
                setDatumOdFormatiran(formattedDateOd);  
        }

        if (datumDo !== null) {
            const selectedDateOnly = new Date(datumDo);
            const day = String(selectedDateOnly.getDate()).padStart(2, '0');
            const month = String(selectedDateOnly.getMonth() + 1).padStart(2, '0');
            const year = selectedDateOnly.getFullYear();
            const formattedDateDo = `${day}/${month}/${year}`;
            setDatumDoFormatiran(formattedDateDo);    
    }
    }, [datumOd, datumDo]);


    const td = {
        border: '1px solid black',
        borderLeft: 'none',
        borderTop: 'none'
       };

       useEffect(() => {
            if(status === 'New'){
                setPrevodStatusa('Na čekanju'); 
            }
            else if(status === 'Approved'){ //ako je predracun...isprazni datum pravljenja i datum dospeca
                setPrevodStatusa('Izvršena'); 
            }
            else if(status === 'Storno'){ //ako je predracun...isprazni datum pravljenja i datum dospeca
                setPrevodStatusa('Stornirane'); 
            }
         }, [status]);

       
    const nazad = async () => {
        setStaSePokazuje(true);
        dispatch(setImeKlijentaZaIzv('')); 
        dispatch(setIdKlijentaZaIzv(0)); 
        setPrevodStatusa('');
        setUkupnaPoreskaOsnovica(0);
        setUkupanRabat(0);
        setUkupanIznosPdv(0);
        setUkupnoSve(0);
    }


    const prikaziIzvestaj = async () => {


        if(datumOdFormatiran != "" || datumDoFormatiran != ""){
            
            fetch(`${defaultniUrl}izvZbirniArtikla`, {
                method: 'POST', 
                headers: {
                'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    datumOd:datumOdFormatiran,
                    datumDo:datumDoFormatiran,
                    status:status,
                    idKlijentaZaIzv:idKlijentaZaIzv
                        })        
                    }).then(res => res.json())
                    .then(data => {                            
                        if(data != ""){
                            var ukupna_poreska_osnovica = 0;
                            var ukupan_rabat = 0;
                            var ukupan_iznos_pdv = 0;
                            var ukupno_sve = 0;

                            const stavkeIzvestaja = data.map((row:any, index:any) =>  (
                                <tr key={row.id} style={{ borderTop: '1px solid black'}} >
                                    <td style={{...td, borderLeft: '1px solid black'}} align="center">{index+1}.</td>
                                    <td style={td} align="center">{row.ime_artikla}</td>
                                    <td style={td} align="center">{row.idArtikla}</td>
                                    <td style={td} align="center">{row.kolicina}</td>
                                    <td style={td} align="center">{row.cena}</td>
                                    <td style={td} align="center">{row.poreska_osnovica.toFixed(1)}</td>
                                    <td style={td} align="center">{row.rabat.toFixed(1)}</td>
                                    <td style={td} align="center">{row.iznos_pdv.toFixed(1)}</td>
                                    <td style={td} align="center">{row.ukupna_vrednost.toFixed(1)}</td>
                                </tr>

                                
                            ))
                            setStavkeIzvestaja(stavkeIzvestaja);

                            data.forEach((row:any) => {
                                setUkupnaPoreskaOsnovica(ukupna_poreska_osnovica += parseFloat(row.poreska_osnovica.toFixed(1)));
                                setUkupanRabat(ukupan_rabat += parseFloat(row.rabat.toFixed(1)));
                                setUkupanIznosPdv(ukupan_iznos_pdv += parseFloat(row.iznos_pdv.toFixed(1)));
                                setUkupnoSve(ukupno_sve += parseFloat(row.ukupna_vrednost.toFixed(1)));
                            });

                            setStaSePokazuje(false); 

                        }else{
                            MySwal.fire({
                                title: 'Nema rezultata pretrage!',
                                //text: '',
                                //icon: 'info',
                                timer: 4000,
                                showCancelButton: false,
                                showConfirmButton: false,
                                icon: 'warning',
                                //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                            })
                        }             
                })

        }else{
            MySwal.fire({
                title: 'Datum dospeća OD i DO su obavezni!',
                //text: '',
                //icon: 'info',
                timer: 4000,
                showCancelButton: false,
                showConfirmButton: false,
                icon: 'warning',
                //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
            })
        }

    }

return(
    <>     
        <Container maxWidth={false} sx={{ flexGrow: 1, py: 8 }}>

            <Box
                sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                m: 0
                }}
            >
                <Typography
                sx={{ m: 1 }}
                variant="h4"
                >
                Zbirni prikaz
                </Typography>
                <Box sx={{ m: 1 }}>
                    {/* <Button  //onClick={}
                        color="warning"
                        variant="contained"
                    >
                        Nazad                       
                    </Button> */}
                </Box>
            </Box>

        {staSePokazuje?  
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 3,
                    width: '500px'
                }}
                > 
            
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker 
                        label="Datum dospeća OD" 
                        value={datumOd}
                        onChange={(newDate) => setDatumOd(newDate)}
                        sx={{ width: '100%'}}
                    />
                </LocalizationProvider>

                <br/>
                <br/>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker 
                        label="Datum dospeća DO" 
                        value={datumDo}
                        onChange={(newDate) => setDatumDo(newDate)}
                        sx={{ width: '100%'}}
                    />
                </LocalizationProvider>

        
                <br/>
                <br/>
                <SifarnikKlijenti lokacija="izvestajZbirniArtikli"/>
                <br/>

                <FormControl  sx={{ width: '100%'}}>  
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select
                        key={1}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select99"
                        label="Status"
                        onChange={odabirStatusa} 
                    >
                        <MenuItem  value={''}>--</MenuItem>
                        <MenuItem  value={'New'}>Na čekanju</MenuItem>
                        <MenuItem  value={'Approved'}>Izvršena</MenuItem>
                        <MenuItem  value={'Storno'}>Storno</MenuItem>
                        
                    </Select>
                </FormControl>


                <br/>
                <br/>
                <Button fullWidth color="success" variant="contained" onClick={  (event) => { prikaziIzvestaj(); } } > Sačuvaj </Button>


            </Box>

        :

                <>
                    <Button fullWidth color="warning" variant="contained" sx={{width:"20%"}} onClick={  (event) => { nazad(); } } > Nazad </Button>
                    <br/>
                    <br/>
                    <p>Za period od {datumOdFormatiran} do {datumDoFormatiran}  {imeKlijentaZaIzv? <><br/> Klijent: {imeKlijentaZaIzv}</>: ''}  {prevodStatusa? <><br/> Status: {prevodStatusa}</>: ''} </p>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table"  /*stickyHeader*/>
                            <TableHead>
                                <TableRow  sx={{ backgroundColor: 'lightgray' }}>
                                    <TableCell align="center">R.B</TableCell>
                                    <TableCell align="center">Artikal</TableCell>
                                    <TableCell align="center">ID artikla</TableCell>
                                    <TableCell align="center">Količina</TableCell>
                                    <TableCell align="center">Cena</TableCell>
                                    <TableCell align="center">Poreska osnovica</TableCell>
                                    <TableCell align="center">Rabat</TableCell>
                                    <TableCell align="center">Iznos PDV</TableCell>
                                    <TableCell align="center">Ukupno</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>                    
                                {stavkeIzvestaja}                                      
                                <TableRow  sx={{ backgroundColor: 'lightgray' }}>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"><b>{ukupnaPoreskaOsnovica.toFixed(1)}</b></TableCell>
                                    <TableCell align="center"><b>{ukupanRabat.toFixed(1)}</b></TableCell>
                                    <TableCell align="center"><b>{ukupanIznosPdv.toFixed(1)}</b></TableCell>
                                    <TableCell align="center"><b>{ukupnoSve.toFixed(1)}</b></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer> 
                </>
        }
                    

        </Container>
    </>
    )
}

export default Filteri_izvZbirniArtikli;