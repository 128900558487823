import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import DataTable from "react-data-table-component";
import TextField from '@mui/material/TextField';
import { Button, Box, Typography } from '@mui/material';
import { setStranica, setIdPredracuna } from '../../../redux/stranice/predracuni';

type Predracuni = {
  id: number;
  broj: string;
  ime: string;
  datum_pravljenja_fakture: string;
  datum_dospeca: string;
  valuta: string;
  nacin_placanja: string;
  ukupna_vrednost: string;
  status: string;
  fl_ime_prezime: string;
  tip_klijenta:string;
};

export const TabelaPredracuni = (props:any) => {

    const dispatch = useDispatch();
    const [data, setData] = useState<Predracuni[]>([]);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState<Predracuni[]>([]);
    const defaultniUrl = useSelector((state: RootState) => state.defaultPodaci.defaultniUrl);

    const columns:any = [
      // {
      //     name: 'RB.',
      //     cell: (row:any, index: any) => index+1+'.',
      // },
      // {
      //   name: "BROJ",
      //   selector: (row: Predracuni) => row.broj.toString(),
      // },
      {
        name: "VRSTA KUPCA",
        selector: (row: Predracuni) => row.tip_klijenta.toString(),
      },
      {
        name: "KUPAC",
        selector: (row: Predracuni) => row.ime ? row.ime.toString() : row.fl_ime_prezime.toString(),
      },
      // {
      //   name: "DATUM FAKTURE",
      //   selector: (row: Predracuni) => row.datum_pravljenja_fakture.toString(),
      // },
      // { 
      //     name: "DATUM DOSPEĆA",
      //     selector: (row: Predracuni) => row.datum_dospeca.toString(),
      // },
      {
          name: "VALUTA",
          selector: (row: Predracuni) => row.valuta.toString(),
      },
      // {
      //     name: "NAČIN PLAĆANJA",
      //     selector: (row: Predracuni) => row.nacin_placanja.toString(),
      // },
      {
          name: "VREDNOST FAKTURE",
          selector: (row: Predracuni) => row.ukupna_vrednost.toString(),
      },
      {
          name: "STATUS",
          selector: (row: Predracuni) => row.status.toString(),
      }
    ];

      const predracuni = async () => {
            fetch(`${defaultniUrl}predracuni`, {
                    method: 'POST', 
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                    
                            })        
                        }).then(res => res.json())
                        .then(data => {
                          if(data !== ""){
                            setData(data);
                            setFilter(data); 
                          }            
                        })
      }

    useEffect(() => { 
        predracuni();  
    },[]);

    useEffect(() => { 
      const result = data.filter((item)=>{
        return (
          //item.broj.toLowerCase().includes(search.toLowerCase()) || // Pretražujemo po broju fakture
          item.tip_klijenta.toString().toLowerCase().includes(search.toLowerCase()) ||
          (item.ime && item.ime.toLowerCase().includes(search.toLowerCase())) || // Pretražujemo po imenu klijenta - firmi ako nije prazno
          (item.fl_ime_prezime && item.fl_ime_prezime.toLowerCase().includes(search.toLowerCase())) || // Pretražujemo po imenu kupca fizickog lica ako nije prazno
          //item.datum_pravljenja_fakture.toLowerCase().includes(search.toLowerCase()) || // Pretražujemo po datumu kreiranja fakture
          //item.datum_dospeca.toLowerCase().includes(search.toLowerCase()) || // Pretražujemo po datumu dospeca fakture
          item.ukupna_vrednost.toString().toLowerCase().includes(search.toLowerCase()) || // Pretražujemo po ukupnoj vrednosti
          item.status.toLowerCase().includes(search.toLowerCase())  // Pretražujemo po statusu
        )
      }) 
      setFilter(result);
    },[search]);

      function otvaranjePredracuna (row:any) {  
        const idPredracuna = row.id;
        dispatch(setIdPredracuna(idPredracuna));
        dispatch(setStranica(2));       
      }

      return (
        <>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              m:2
            }}
          >
            <Typography sx={{ m: 1 }} variant="h4">PREDRAČUNI</Typography>
            {/* <Box sx={{ m: 1 }}>
                <Button  onClick={} color="success" variant="contained">Dodaj novog klijenta</Button>
            </Box> */}
          </Box>
        
          <br/>
          <Box sx={{ padding:"20px" }}>
          <DataTable
                      columns={columns}
                      data={filter}
                      pagination
                      fixedHeader
                      highlightOnHover
                      //selectableRowsHighlight
                      //fixedHeaderScrollHeight='450px'
                      // selectableRows
                      //  actions={<button className='btn btn-sm btn-info'>Dogadjaj</button>}
                      onRowClicked={otvaranjePredracuna}
                      subHeader
                      subHeaderComponent={
                        <TextField 
                              size="small"
                              onChange={(e)=>setSearch(e.target.value)}
                              label="Pretraži"
                              variant="outlined"
                              value={search}
                              style={{ width: '300px'}}   
                          />
                        
                      }
                      // className={tableClassName}
                />
          </Box>
        </>
      );

}

export default TabelaPredracuni;