import { Glavna_magacin } from '../components/magacin/glavna_magacin';

function Magacin() {

return(

   <Glavna_magacin/>

)
}

export default Magacin;
