import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Drawer, Typography, useMediaQuery, ListItem } from '@mui/material';
//import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ChartBar as ChartBarIcon } from '../icons/chart-bar';
import { Cog as CogIcon } from '../icons/cog';
import { Lock as LockIcon } from '../icons/lock';
import { Selector as SelectorIcon } from '../icons/selector';
import { ShoppingBag as ShoppingBagIcon } from '../icons/shopping-bag';
import { User as UserIcon } from '../icons/user';
import { UserAdd as UserAddIcon } from '../icons/user-add';
import { Users as UsersIcon } from '../icons/users';
import { XCircle as XCircleIcon } from '../icons/x-circle';
//import { Logo } from './logo';
import Logo from '../slike/interm_logo.png';
import { Link } from 'react-router-dom';

import { NavItem } from './nav-item';
import { CollapseNavItemKlijenti } from './collapse-nav-item-klijenti';
import { CollapseNavItemSifarnici } from './collapse-nav-item-sifarnici';
import { CollapseNavItemFakture } from './collapse-nav-item-fakture';
import { CollapseNavItemIzvestaji } from './collapse-nav-item-izvestaji';

import WarehouseIcon from '@mui/icons-material/Warehouse';
import DescriptionIcon from '@mui/icons-material/Description';
import AssessmentIcon from '@mui/icons-material/Assessment';

// import { makeStyles } from '@material-ui/core/styles';
// import Avatar from '@material-ui/core/Avatar';

export const DashboardSidebar = (props) => {

const items = [
  {
    href: '/racun',
    icon: (<ChartBarIcon fontSize="small" />),
    title: 'Račun'
  },
  {
    href: '/magacin',
    icon: (<WarehouseIcon fontSize="small" />),
    title: 'Magacin'
  },
  // {
  //   href: '/povratRobe',
  //   icon: (<ChartBarIcon fontSize="small" />),
  //   title: 'Povrat robe'
  // },
  {
    href: '/menu',
    icon: (<DescriptionIcon fontSize="small" />),
    title: 'Fakture'
  },
  {
    href: '/klijenti',
    icon: (<UsersIcon fontSize="small" />),
    title: 'Klijenti'
  },

  
  {
    href: '/moji_podaci',
    icon: (<UserIcon fontSize="small" />),
    title: 'Moji podaci'
  },

  // {
  //   href: '/settings',
  //   icon: (<CogIcon fontSize="small" />),
  //   title: 'Podešavanje'
  // },
  // {
  //   href: '/login',
  //   icon: (<LockIcon fontSize="small" />),
  //   title: 'Login'
  // },
  // {
  //   href: '/register',
  //   icon: (<UserAddIcon fontSize="small" />),
  //   title: 'Register'
  // },
  // {
  //   href: '/404',
  //   icon: (<XCircleIcon fontSize="small" />),
  //   title: 'Error'
  // },
  // {
  //   href: '/grrrr',
  //   icon: (<XCircleIcon fontSize="small" />),
  //   title: 'Šifarnici'
  // },
  {
    href: '/izvestaji',
    icon: (<AssessmentIcon fontSize="small" />),
    title: 'Izveštaji'
  },
  {
    href: '/',
    icon: (<AssessmentIcon fontSize="small" />),
    title: 'Izadji',
  }
];


  const { open, onClose } = props;
   const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
    defaultMatches: true,
    noSsr: false
  });






  const content = (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        
        <div>
          <Box sx={{mx: 'auto', width: 100, pt:2}}>
            <Link to="/" >
                
                {/* <Avatar alt="Remy Sharp" src={Logo} /> */}
                <img src={Logo}  style={{ height: 60, width: 150 }}/>
                {/* <Logo sx={{ height: 42, width: 42 }} /> */}
             
            </Link>
          </Box>
        </div>







        <Divider
          sx={{
            borderColor: '#2D3748',
            my: 3
          }}
        />
        <Box sx={{ flexGrow: 1 }}>
  
            {items.map((item) => {
                
                if(item.title == 'Šifarnici'){
                  return(
                    <CollapseNavItemSifarnici
                      key={item.title}
                      icon={item.icon}
                      href={item.href}
                      title={item.title}
                    />
                  )
                }
                // else if (item.title == 'Klijenti'){
                //   return(
                   
                //     <CollapseNavItemKlijenti
                //       key={item.title}
                //       icon={item.icon}
                //       href={item.href}
                //       title={item.title}
                //     />

                //   )
                // }
                else if (item.title == 'Fakture'){
                  return(
                   
                    <CollapseNavItemFakture
                      key={item.title}
                      icon={item.icon}
                      href={item.href}
                      title={item.title}
                    />

                  )
                }
                else if (item.title == 'Izveštaji'){
                  return(
                   
                    <CollapseNavItemIzvestaji
                      key={item.title}
                      icon={item.icon}
                      href={item.href}
                      title={item.title}
                    />

                  )
                }
                else{
                  return(
                    <NavItem
                      key={item.title}
                      icon={item.icon}
                      href={item.href}
                      title={item.title}
                    />
                  )
                }
    
            })} 
        </Box>

      </Box>
    </>
  );


  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.900',
            color: '#FFFFFF',
            width: 280
          }
        }}
        variant="permanent"
      >
        {/* <Router> */}
  
          {content}

        {/* </Router> */}
      </Drawer>
    );
  }


  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF', 
          width: 280
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}    
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
