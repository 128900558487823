import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// interface Stavka {
//   trenutnaCena: number;
// }

interface Magacin {
  stranica: number;
  jedinicaMereId: number;
  idArtikla: number;
}

const initialState: Magacin = {
    stranica: 1,
    jedinicaMereId: 0,
    idArtikla: 0
};

const magacin = createSlice({
  name: 'maloprodaja',
  initialState,
  reducers: {
    setStranica: (state, action: PayloadAction<number>) => {
      state.stranica = action.payload;
    },
    setJedinicaMereId: (state, action: PayloadAction<number>) => {
      state.jedinicaMereId = action.payload;
    },
    setIdArtikla: (state, action: PayloadAction<number>) => {
      state.idArtikla = action.payload;
    },
  }
});

export const { setStranica, setJedinicaMereId, setIdArtikla } = magacin.actions;
export const magacinReducer = magacin.reducer;


