import { Glavna_maloprodaja } from '../components/maloprodaja/glavna_maloprodaja';

function Maloprodaja() {

return(

   <Glavna_maloprodaja/>

)
}

export default Maloprodaja;