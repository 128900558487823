import FormControl from '@mui/material/FormControl';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setOdabraniRabatId, setOdabraniRabatVrednost } from '../../redux/stranice/maloprodaja';
import { setOdabraniRabatIdNaPredracunu, setOdabraniRabatVrednostNaPredracunu } from '../../redux/stranice/predracuni';
import { RootState } from '../../redux/store';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';

interface RabatData {
    id: string;
    vrednost: string;
  }
  
export const SifarnikRabat = (props:any) => {
    const defaultniUrl = useSelector((state: RootState) => state.defaultPodaci.defaultniUrl);
    const idArtikla = useSelector((state: RootState) => state.maloprodaja.idArtikla);
    const reloadKomponenteRabat = useSelector((state: RootState) => state.maloprodaja.reloadKomponenteRabat);
    const [data, setData] = useState<RabatData[]>([]); 
    const dispatch = useDispatch();

    const PrikazSelecta = async () => {
        fetch(`${defaultniUrl}sifarnik_rabat`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({})        
        })
        .then(res => res.json())
        .then(data => {
            if (data !== "") {
                setData(data);
            }
        })
    }

    useEffect(() => { 
        PrikazSelecta();  
    }, []);


    return (       
            <FormControl style={{ width: '130px'}}>
                <Autocomplete   
                    key={reloadKomponenteRabat}                               
                    id="rabatId"
                    options={data.map((option:any, index:any) => ({  label: `${option.vrednost}%`, id: option.id, vrednost:option.vrednost  }))}
                    renderInput={(params) => <TextField {...params} label="Rabat" />}
                    onChange={(event, newValue: { label: string; id: any; vrednost: number } | null) => {
                        if (newValue) {
                            if(props.lokacija == "racun"){

                                dispatch(setOdabraniRabatId(newValue.id));
                                dispatch(setOdabraniRabatVrednost(newValue.vrednost));

                            }else if(props.lokacija == "predracun"){
                                dispatch(setOdabraniRabatIdNaPredracunu(newValue.id));    
                                dispatch(setOdabraniRabatVrednostNaPredracunu(newValue.vrednost));        
                            }
                            
                        } else {
                            // Kada se izvrši brisanje (clear)
                            dispatch(setOdabraniRabatId(0));
                            dispatch(setOdabraniRabatVrednost(0));
                        }
                    }}                                                   
                />
            </FormControl>
    )
}

export default SifarnikRabat;
















