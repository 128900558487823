import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setOdabraniPdvId, setPdvVrednost } from '../../redux/stranice/maloprodaja';
import { RootState } from '../../redux/store';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';

interface PDVData {
    id: number;        // Promenite ovu liniju na `number`
    vrednost: number;  // Promenite ovu liniju na `number`
    value: number;
}

export const SifarnikPdv = () => {
    const defaultniUrl = useSelector((state: RootState) => state.defaultPodaci.defaultniUrl);
    const idArtikla = useSelector((state: RootState) => state.maloprodaja.idArtikla);
    const [data, setData] = useState<PDVData[]>([]);  
    const dispatch = useDispatch();
    const odabraniPdvVrednost = useSelector((state: RootState) => state.maloprodaja.odabraniPdvVrednost);


    const PrikazSelecta = async () => {
        fetch(`${defaultniUrl}sifarnik_pdv`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({})        
        })
        .then(res => res.json())
        .then(data => {
            if (data !== "") {
                setData(data);
                //console.log(data)
            }
        })
    }

    useEffect(() => { 
        PrikazSelecta();  
    }, []);


    return (       
        <FormControl style={{ width: '130px'}}>
        <Autocomplete
            key={idArtikla}
            id="pdvId"
            readOnly
            value={data.find(option => option.id === 3) ? { id: 3, label: '20%',  vrednost: 123 } : null}  //ovo value ne radi uopste...samo vizuelno povlaci ovaj label....a pdv-e je zakucan u reduxu na  odabraniPdvVrednost: 20
            options={data.map((option) => ({ id: option.id, label: `${option.vrednost}%`,  vrednost: option.vrednost }))}
            renderInput={(params) => <TextField {...params} label="PDV" />}
            getOptionLabel={(option) => option.label}
            onChange={(event, newValue: { label: string; id: any; vrednost: number } | null) => {
                if (newValue) {
                    dispatch(setOdabraniPdvId(newValue.id));
                    dispatch(setPdvVrednost(newValue.vrednost));
                } else {
                    // Kada se izvrši brisanje (clear)
                    dispatch(setOdabraniPdvId(20));
                    dispatch(setPdvVrednost(0));
                }
            }}
        />
    </FormControl>
            )
}

export default SifarnikPdv;