import { Podaci } from './podaci';
// import { Dod_dodataka } from './dod_dodatak';
// import { Izm_dodatak } from './izm_dodatak';


// import { useDispatch, useSelector } from 'react-redux';
// import { RootState } from '../../redux/store';

export const GlavnaMojiPodaci  = () => {

   //const stranica = useSelector((state: RootState) => state.klijenti.stranica);

//   const redNasloviMenia = useSelector((state: RootState) => state.meni.redNasloviMenia);
//   const nazivNaslova = useSelector((state: RootState) => state.meni.nazivNaslova); 
//   const redStavkeMenia = useSelector((state: RootState) => state.meni.redStavkeMenia); 
//   const nazivStavke = useSelector((state: RootState) => state.meni.nazivStavke); 

 


 return(
   <>
   <Podaci/>
     {/* {stranica === 1 && <TabelaKlijenti/>} */}
   </>
 )
};


export default GlavnaMojiPodaci ;