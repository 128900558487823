import { createSlice, PayloadAction } from '@reduxjs/toolkit';



interface UlazneFakture {
  stranica: number;
  idFakture: number;
}

const initialState: UlazneFakture = {
    stranica: 1,
    idFakture: 0
};

const ulazneFakture = createSlice({
  name: 'ulazneFakture',
  initialState,
  reducers: {
    setStranica: (state, action: PayloadAction<number>) => {
      state.stranica = action.payload;
    },
    setIdFakture: (state, action: PayloadAction<number>) => {
      state.idFakture = action.payload;
    },

  }
});

export const { setStranica, setIdFakture } = ulazneFakture.actions;
export const ulazneFaktureReducer = ulazneFakture.reducer;


