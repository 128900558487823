import { Box, Button, ListItem,List,ListSubheader, ListItemButton, ListItemText, ListItemIcon, AppBar} from '@mui/material';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
//import StarBorder from "@mui/icons-material/StarBorder";
import Collapse from "@mui/material/Collapse";
import styled from '@emotion/styled';

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import PaymentsIcon from '@mui/icons-material/Payments';


export const CollapseNavItemIzvestaji = (props) => {
    const { href, icon, title, ...others } = props;
    const active = href ? (window.location.pathname === href) : false;  


    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
      setOpen(!open);
    };

    const boja = styled(AppBar)(({ theme }) => ({
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[3]
      }));


    return (
        <List sx={{ width: "100%", maxWidth: 360, bgcolor: boja, padding: "0px" }} component="nav">
            
            {/* dugme koje otvara  collspan */}
            <ListItemButton  onClick={handleClick} sx={{ paddingTop: "0px",paddingBottom: "0px" }}>
                <Button 
                    //component="a"
                    startIcon={icon}
                    disableRipple
                    sx={{
                    backgroundColor: active && 'rgba(255,255,255, 0.08)',
                    borderRadius: 1,
                    color: active ? 'secondary.main' : 'neutral.300',
                    fontWeight: active && 'fontWeightBold',
                    justifyContent: 'flex-start',
                    px: 3,
                    textAlign: 'left',
                    textTransform: 'none',
                    width: '100%',
                    '& .MuiButton-startIcon': {
                        color: active ? 'secondary.main' : 'neutral.400'
                    },
                    '&:hover': {
                        backgroundColor: 'rgba(255,255,255, 0.08)'
                    }
                    }}
                >
                <Box sx={{ flexGrow: 1 }}>
                    {title}
                </Box>
                {open ? <ExpandLess /> : <ExpandMore />}
                </Button>             
            </ListItemButton>
            {/* dugme koje otvara  collspan */}


            
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{/*backgroundColor:'red',*/ marginLeft:"40px", marginRight:"30px", marginTop:"10px"}}>

                    <Link  key={title} to={'/izvZbirniArtikli'}  style={{ textDecoration: 'none',  color: 'white',  width: '100%' }} >
                        <Button 
                            //component="a"
                            startIcon={<PaymentsIcon />}
                            disableRipple
                            sx={{
                            //pl: 7,   //jebem li ga dal treba
                            backgroundColor: active && 'rgba(255,255,255, 0.08)',
                            borderRadius: 1,
                            color: active ? 'secondary.main' : 'neutral.300',
                            fontWeight: active && 'fontWeightBold',
                            justifyContent: 'flex-start',
                            px: 3,
                            textAlign: 'left',
                            textTransform: 'none',
                            width: '100%',
                            '& .MuiButton-startIcon': {
                                color: active ? 'secondary.main' : 'neutral.400'
                            },
                            '&:hover': {
                                backgroundColor: 'rgba(255,255,255, 0.08)'
                            }
                            }}
                        >
                        <Box sx={{ flexGrow: 1 }}>
                            Zbirni prikaz po artiklima
                        </Box>
                
                        </Button> 
                    </Link>



                    {/* <Link  key={title} to={'/izvZbirniKlijenti'}  style={{ textDecoration: 'none',  color: 'white',  width: '100%' }} >
                        <Button 
                            //component="a"
                            startIcon={icon}
                            disableRipple
                            sx={{
                            //pl: 7,   //jebem li ga dal treba
                            backgroundColor: active && 'rgba(255,255,255, 0.08)',
                            borderRadius: 1,
                            color: active ? 'secondary.main' : 'neutral.300',
                            fontWeight: active && 'fontWeightBold',
                            justifyContent: 'flex-start',
                            px: 3,
                            textAlign: 'left',
                            textTransform: 'none',
                            width: '100%',
                            '& .MuiButton-startIcon': {
                                color: active ? 'secondary.main' : 'neutral.400'
                            },
                            '&:hover': {
                                backgroundColor: 'rgba(255,255,255, 0.08)'
                            }
                            }}
                        >
                        <Box sx={{ flexGrow: 1 }}>
                            Zbirni prikaz po klijentima
                        </Box>
                
                        </Button> 
                    </Link> */}


                </List>
            </Collapse>
        </List>
    );
  };
  
  CollapseNavItemIzvestaji.propTypes = {
    href: PropTypes.string,
    icon: PropTypes.node,
    title: PropTypes.string
  };