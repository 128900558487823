import { TabelaPredracuni } from './tabela_predracuni';
import { PrikazPredracuna } from './prikaz_predracuna';
import {  useEffect } from "react";
import { useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

import { setStranica, setIdArtiklaDodajNovi, setOdabraniRabatIdNaPredracunu, setOdabraniRabatVrednostNaPredracunu} from '../../../redux/stranice/predracuni';


export const GlavnaPredracuni = () => {

 const dispatch = useDispatch();
 const stranica = useSelector((state: RootState) => state.predracuni.stranica);


 

//OVO UVEK KADA SE DODJE NA STRANICU RESETUJE PRVO SVE PROMENLJIVE U REDUXU....PA POSLE NEK RADI KAKO HOCE
const location = useLocation();
useEffect(() => {
  if (location.pathname === '/predracuni') {
      dispatch(setStranica(1));  

      dispatch(setIdArtiklaDodajNovi(0));
      dispatch(setOdabraniRabatIdNaPredracunu(1));
      dispatch(setOdabraniRabatVrednostNaPredracunu(0));
     
  }
}, [location.pathname]);
//OVO UVEK KADA SE DODJE NA STRANICU RESETUJE PRVO SVE PROMENLJIVE U REDUXU....PA POSLE NEK RADI KAKO HOCE




 return(
   <>

      {stranica === 1 && <TabelaPredracuni/>}
      {stranica === 2 && <PrikazPredracuna/>} 
   </>
 )
};


export default GlavnaPredracuni;