import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Faktura {
  ime: string;
  adresa: string;
  mestoOtpreme: string;
  pib: number;
  maticniBr: number;
  sifraDelatnosti: number;           
  zr: string;   
  opis: string;   
  telefon: number;
  fixtelefon: string;
  email: string;

  imeKlijenta: string;
  adresaKlijenta: string;
  mestoKlijenta: string;
  pibKlijenta: number;
  maticniBrKlijenta: number;
  telefonKlijenta: string;

  brojFakture: string;
  datumFakture: string;
  datumDospeca: string;
  ukupnaPdvFak: number;
  poreskaOsnovica: number;
  ukupnaVrednostFak: number;
}


interface StavkeFakture {
   id: number;
   id_artikla: number;
   sifra: string;
   ime: string;
   unitCode: string;
   kolicina: number;
   cena: number;
   poreska_osnovica: number; 
   ukupna_vrednost: number;           
}

interface IzlazneFakture {
  stranica: number;
  idFakture: number;
  idFaktureEFakture: number;
  faktura: Faktura[];
  stavkeFakture: StavkeFakture[];
  vozilo:string;
}

const initialState: IzlazneFakture = {
    stranica: 1,
    idFakture: 0,
    idFaktureEFakture: 0,
    faktura: [],
    stavkeFakture: [],
    vozilo:''
};

const izlazneFakture = createSlice({
  name: 'izlazneFakture',
  initialState,
  reducers: {
    setStranica: (state, action: PayloadAction<number>) => {
      state.stranica = action.payload;
    },
    setIdFakture: (state, action: PayloadAction<number>) => {
      state.idFakture = action.payload;
    },
    setIdFaktureEFakture: (state, action: PayloadAction<number>) => {
      state.idFaktureEFakture = action.payload;
    },

    dodajUFakturu: (state, action: PayloadAction<Faktura>) => { //SA OVOM FCJ PUNIMO OBJEKAT TJ DODAJEMO STAVKU PO STAVKU
      state.faktura.push(action.payload);
    },
    isprazniFakturu: (state) => { //SA OVOM FCJ PRAZNIMO OBJEKAT TJ BRISEMO SVE STAVKE
      state.faktura = [];
    },


    dodajStavkeFakture: (state, action: PayloadAction<StavkeFakture>) => { //SA OVOM FCJ PUNIMO OBJEKAT TJ DODAJEMO STAVKU PO STAVKU
      state.stavkeFakture.push(action.payload);
    },
    isprazniStavkeFakture: (state) => { //SA OVOM FCJ PRAZNIMO OBJEKAT TJ BRISEMO SVE STAVKE
      state.stavkeFakture = [];
    },


    setVozilo: (state, action: PayloadAction<string>) => {
      state.vozilo = action.payload;
    },

  }
});

export const { setStranica, setIdFakture, setIdFaktureEFakture, dodajUFakturu, isprazniFakturu, dodajStavkeFakture, isprazniStavkeFakture, setVozilo } = izlazneFakture.actions;
export const izlazneFaktureReducer = izlazneFakture.reducer;


