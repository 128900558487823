import { Racun } from './racun';
import {  useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

export const Glavna_maloprodaja = () => {

    const stranica = useSelector((state: RootState) => state.maloprodaja.stranica);
    const reloadKomponenteRacun = useSelector((state: RootState) => state.maloprodaja.reloadKomponenteRacun);

 return(
   <>
     {stranica === 1 && <Racun key={reloadKomponenteRacun}/>}{/* reload se radi svaki put kad se promeni stanje ove poromenljive redux...ono se menja u komponenti Buttoni_i_funkcije svaki put kada se uspesno sacuva nova faktura */}
     {/* {stranica === 2 && <Dod_dodataka/>*/}
   </>
 )
};


export default Glavna_maloprodaja;