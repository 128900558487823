import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface DefaultPodaci {
    defaultniUrl: string;
}

const initialState: DefaultPodaci = {
    //defaultniUrl: 'http://192.168.1.88:5000/',
    defaultniUrl: 'https://intermb.ginder.in.rs/',
    
};

const defaultPodaci = createSlice({
  name: 'default',
  initialState,
  reducers: {
    setDefaultniUrla: (state, action: PayloadAction<string>) => {
      state.defaultniUrl = action.payload;
    },
  }
});

export const { setDefaultniUrla } = defaultPodaci.actions;
export const defaultReducer = defaultPodaci.reducer;
