import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setOdabranaCena } from '../../redux/stranice/maloprodaja';
import { useState, useEffect } from "react";

//podaci tj opcije selecta u ovom selectu se menjaju dinamicki svaki put kada se promeni artikal i re-renderuje redux
export const SifarnikCene = () => {

    const dispatch = useDispatch();

    const tip = useSelector((state: RootState) => state.maloprodaja.tip);
    const idOdabranoglijenta = useSelector((state: RootState) => state.maloprodaja.idOdabranoglijenta);

    const maloprodajnaCena = useSelector((state: RootState) => state.maloprodaja.maloprodajnaCena);
    const veleprodajnaCena = useSelector((state: RootState) => state.maloprodaja.veleprodajnaCena);
    const odabranaCena = useSelector((state: RootState) => state.maloprodaja.odabranaCena);
    const idArtikla = useSelector((state: RootState) => state.maloprodaja.idArtikla);
    const [defaultnaCena, setDefaultnaCena] = useState<any>(1);
    
    const handleChange = (event:any) => {
        // if(event.target.value == 1){
        //     dispatch(setOdabranaCena(maloprodajnaCena));
        // }else if(event.target.value == 2){
        //     dispatch(setOdabranaCena(veleprodajnaCena));
        // }
        
      };


      useEffect(() => {
        if(tip === 1 && idOdabranoglijenta === 1){  //ako je predracun za fizicko lice onda je malo prodajna cena

            setDefaultnaCena(1); 
         }
         else if(tip === 2){          //ako je faktura onda veleprodajna
            setDefaultnaCena(2); 
         }else if(tip === 3){        //ako je fiskalna kasa
            setDefaultnaCena(1); 
         }else{
            setDefaultnaCena(2);
         }
  }, [tip,idOdabranoglijenta]);



    return(
        <FormControl  style={{ width: '170px'}}>  
            <InputLabel id="demo-simple-select-label">Cena</InputLabel>
                <Select
                    key={idArtikla}
                    readOnly
                    labelId="demo-simple-select-label"
                    id="demo-simple-select9"
                    label="Cena"
                    onChange={handleChange}
                    value={defaultnaCena}
                >
                    <MenuItem  value={1}>MP. {maloprodajnaCena} RSD</MenuItem>
                    <MenuItem  value={2}>VP. {veleprodajnaCena} RSD</MenuItem>
                    
                </Select>
        </FormControl>
    )
}

export default SifarnikCene;