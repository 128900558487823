import { TabelaKlijenti } from './tabela_klijenti';
import { DodajNovogKlijenta } from './dodaj_novog_klijenta';
import { IzmeniKlijenta } from './izmeni_klijenta';

import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import {  useEffect } from "react";

import { setStranica } from '../../redux/stranice/klijenti';

export const Glavna_klijenti = () => {

   const stranica = useSelector((state: RootState) => state.klijenti.stranica);
   const dispatch = useDispatch();

//OVO UVEK KADA SE DODJE NA STRANICU RESETUJE PRVO SVE PROMENLJIVE U REDUXU....PA POSLE NEK RADI KAKO HOCE
const location = useLocation();
useEffect(() => {
  if (location.pathname === '/klijenti') {
      dispatch(setStranica(1));  
  }
}, [location.pathname]);
//OVO UVEK KADA SE DODJE NA STRANICU RESETUJE PRVO SVE PROMENLJIVE U REDUXU....PA POSLE NEK RADI KAKO HOCE

 


 return(
   <>   
     {stranica === 1 && <TabelaKlijenti/>}
     {stranica === 2 && <DodajNovogKlijenta/>}
     {stranica === 3 && <IzmeniKlijenta/>}
   </>
 )
};


export default Glavna_klijenti;