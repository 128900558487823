import { logovanjeReducer } from './stranice/login';
import { combineReducers } from '@reduxjs/toolkit';
import { maloprodajaReducer } from './stranice/maloprodaja';
import { sumaStavkiReducer } from './stranice/maloprodaja';  
import { magacinReducer } from './stranice/magacin';
import { klijentiReducer } from './stranice/klijenti';
import { izlazneFaktureReducer } from './stranice/izlazneFakture';
import { predracuniReducer } from './stranice/predracuni';
import { ulazneFaktureReducer } from './stranice/ulazneFakture';
import { izvZbirniArtikliReducer } from './stranice/izvZbirniArtikli';
import { izvZbirniKlijentiReducer } from './stranice/izvZbirniKlijenti';
import { defaultReducer } from './stranice/defaultPodaci';


const rootReducer = combineReducers({
    defaultPodaci:defaultReducer,
    logovanje:logovanjeReducer,
    maloprodaja:maloprodajaReducer,
    magacin:magacinReducer,
    klijenti:klijentiReducer,
    izlazneFakture:izlazneFaktureReducer,
    predracuni:predracuniReducer,
    ulazneFakture:ulazneFaktureReducer,
    izvZbirniArtikli:izvZbirniArtikliReducer,
    izvZbirniKlijenti:izvZbirniKlijentiReducer,
    
    sumaStavki: sumaStavkiReducer,
});

export default rootReducer;