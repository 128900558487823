import DataTable from "react-data-table-component";
import TextField from '@mui/material/TextField';
import { Button, Box, Typography } from '@mui/material';
import { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { SifarnikJedinicaMere } from '../selectSifarnici/jedinica_mere';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setStranica, setJedinicaMereId } from '../../redux/stranice/magacin';

export const IzmeniArtikal = (props:any) => {

    const MySwal = withReactContent (Swal); //sweetalert2   
    const dispatch = useDispatch();
    const idArtikla = useSelector((state: RootState) => state.magacin.idArtikla);
    const [sifra, setSifra] = useState('');
    const [ime, setIme] = useState('');
    const [maloprodajnaCena, setMaloprodajnaCena] = useState('');
    const [veleprodajnaCena, setVeleprodajnaCena] = useState('');
    const [stanje, setStanje] = useState('');
    const [dodajNaStanje, setDodajNaStanje] = useState('');
    const jm = useSelector((state: RootState) => state.magacin.jedinicaMereId);
    const [donjiLimit, setDonjiLimit] = useState('');
    const defaultniUrl = useSelector((state: RootState) => state.defaultPodaci.defaultniUrl);

    const handleChangeSifra = (event:any) => {setSifra(event.target.value);};
    const handleChangeIme = (event:any) => {setIme(event.target.value);};
    const handleChangeMaloprodajnaCena = (event:any) => {setMaloprodajnaCena(event.target.value);};
    const handleChangeVeleprodajnaCena = (event:any) => {setVeleprodajnaCena(event.target.value);};
    const handleChangeStanje = (event:any) => {setStanje(event.target.value);};
    const handleDodajNaStanje = (event:any) => {setDodajNaStanje(event.target.value);};
    const handleDonjiLimit = (event:any) => {setDonjiLimit(event.target.value);};

    const naTabeluArtikala = () => {
      dispatch(setStranica(1));
    };

    const PrikazPodatakaOArtiklu = async () => {
 
      fetch(`${defaultniUrl}odredjeni_artikal`, {
              method: 'POST', 
              headers: {
              'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                idArtikla:idArtikla
                      })        
                  }).then(res => res.json())
                  .then(data => {

                    dispatch(setJedinicaMereId(data[0].jedinica_mere_id));
                    setSifra(data[0].sifra);
                    setIme(data[0].ime);
                    setMaloprodajnaCena(data[0].maloprodajna_cena);
                    setVeleprodajnaCena(data[0].veleprodajna_cena);
                    setStanje(data[0].stanje);
                    setDonjiLimit(data[0].donji_limit);
       
                  })
}


useEffect(() => { 
  PrikazPodatakaOArtiklu();  
},[]);



    const izmeniArtikal = () => {
      if(dodajNaStanje == ""){ 
        var novoStanje = parseInt(stanje);
      }else{
        var novoStanje = parseInt(stanje) + parseInt(dodajNaStanje);
      }
      

      fetch(`${defaultniUrl}izmeni_artikal`, {
        method: 'POST', 
        headers: {
        'Content-Type': 'application/json'
        },
        body: JSON.stringify({
                idArtikla:idArtikla,
                sifra:sifra,
                ime:ime,
                maloprodajnaCena:maloprodajnaCena,
                veleprodajnaCena:veleprodajnaCena,
                stanje:novoStanje,
                jm:jm,
                donjiLimit:donjiLimit
                })        
            }).then(res => res.json())
            .then(data => {

                            if(data.poruka == "uspesno"){ 
                              MySwal.fire({
                              title: `Uspešno izmenjen artikal`,
                              //text: '',
                              //icon: 'info',
                              timer: 4000,
                              showCancelButton: false,
                              showConfirmButton: false,
                              icon: 'success',
                              //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                              })
                                    dispatch(setStranica(1));
                                    dispatch(setJedinicaMereId(0));
                            }else{
                                    MySwal.fire({
                                    title: `Greška`,
                                    //text: '',
                                    //icon: 'info',
                                    timer: 4000,
                                    showCancelButton: false,
                                    showConfirmButton: false,
                                    icon: 'error',
                                    //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                                    })
                            }

            })

};




    return(   
      <>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              m:2
            }}
          >
            <Typography sx={{ m: 1 }} variant="h4">IZMENI ARTIKAL</Typography>
            <Box sx={{ m: 1 }}>
                <Button  onClick={naTabeluArtikala} color="warning" variant="contained">Nazad</Button>
            </Box>
          </Box>
          <br/>
          
                      <Box style={{padding:"20px", width: '30%'}}> 
                          <TextField   
                                  size="small"
                                  required                    
                                  fullWidth
                                  onChange={handleChangeSifra}
                                  label="Šifra"
                                  variant="outlined"
                                  value={sifra}
                          />
                          <br/><br/>
                          <TextField   
                                  size="small"
                                  required                    
                                  fullWidth
                                  onChange={handleChangeIme}
                                  label="Naziv"
                                  variant="outlined"
                                  value={ime}
                          />
                          <br/><br/>
                          <TextField   
                                  size="small"
                                  required                    
                                  fullWidth
                                  onChange={handleChangeMaloprodajnaCena}
                                  label="Maloprodajna cena"
                                  variant="outlined"
                                  value={maloprodajnaCena}
                          />
                          <br/><br/>
                          <TextField   
                                  size="small"
                                  required                    
                                  fullWidth
                                  onChange={handleChangeVeleprodajnaCena}
                                  label="Veleprodajna cena"
                                  variant="outlined"
                                  value={veleprodajnaCena}
                          />
                          <br/><br/>
                          <TextField   
                                  size="small"
                                  required  
                                  InputProps={{
                                    readOnly: true,
                                  }}                
                                  fullWidth
                                  onChange={handleChangeStanje}
                                 // label={<b>Trenutno stanje</b>}
                                  label="Trenutno stanje"
                                  variant="outlined"
                                  value={stanje}
                                  style={{width:'49%'}}
                          />&nbsp;
                          <TextField   
                                  size="small"                    
                                  fullWidth
                                  InputProps={{
                                    type:'number'
                                  }}   
                                  onChange={handleDodajNaStanje}
                                  label="Dodaj na stanje"
                                  variant="outlined"
                                  value={dodajNaStanje}
                                  style={{width:'49%'}}
                          />
                          <br/><br/>
                          <SifarnikJedinicaMere/>
                          <br/><br/>
                          <TextField   
                                  size="small"
                                  required                    
                                  fullWidth
                                  onChange={handleDonjiLimit}
                                  label="Donji limit"
                                  variant="outlined"
                                  value={donjiLimit}
                          />
                          <br/>
                          <br/>
                          <Button fullWidth color="success" variant="contained" onClick={  (event) => { izmeniArtikal(); } } > Sačuvaj </Button>
                      </Box>
          
      </>  
     )
}

export default IzmeniArtikal;