import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { 
  setIzbaciStavkuIzRacuna,  
  setSumaSvihStavkiPoreskaOsnovicaStavke, 
  setSumaSvihStavkiIznosPdvStavke, 
  setSumaSvihStavkiUkpVrednostStavke, 
  setSumaSvihStavkRabataStavke, 
  setDisejbluj,
  setIdArtikla, 
  setImeArtikla,
  setJedinicaMere,
  setMaloprodajnaCena,
  setVeleprodajnaCena,
  setStanje,
  setDonjiLimit,
  setOdabraniRabatId,
  setOdabraniRabatVrednost,
  setReloadKomponenteArtikli, setReloadKomponenteRabat } from '../../redux/stranice/maloprodaja';


export const TabelaStavkiPrivremena = (props:any) => {

    const dispatch = useDispatch();
    const stavkeRacuna = useSelector((state: RootState) => state.maloprodaja.stavkeRacuna);
    const reloadKomponenteArtikli = useSelector((state: RootState) => state.maloprodaja.reloadKomponenteArtikli);
    const reloadKomponenteRabat = useSelector((state: RootState) => state.maloprodaja.reloadKomponenteRabat);
    



    

    const ukloniStavkuIzObjekta = (idReda:any) => {     
        dispatch(setIzbaciStavkuIzRacuna(idReda));

        Object.entries(stavkeRacuna).map(([id, stavka], index, array) => {       
          const isLastStavka = array.length;

          if (isLastStavka === 1) { //ako je poslednja stavka u nizu onda omoguci dugmice i isprazni vrednosti
            dispatch(setDisejbluj(false)); 
            dispatch(setIdArtikla(0));
            dispatch(setImeArtikla(''));
            dispatch(setJedinicaMere(''));
            dispatch(setMaloprodajnaCena(0));
            dispatch(setVeleprodajnaCena(0));
            dispatch(setStanje(0));
            dispatch(setDonjiLimit(0));
            dispatch(setOdabraniRabatId(1));
            dispatch(setOdabraniRabatVrednost(0)); 
            dispatch(setReloadKomponenteArtikli(reloadKomponenteArtikli+1));   
            dispatch(setReloadKomponenteRabat(reloadKomponenteRabat+1));  
            // Vaše radnje za poslednju stavku
          }

        });


      };



           //SVAKI PUT KADA SE RE-RENDERUJE KOMPONENTA BILO DA SE DODAJE ILI UKLANJA STAVKA UVEK SE PRVO VRSI SABIRANJE SVIH TRENUTNIH STAVKI U OBJEKTU
            //SUMA SVIH STAVKI PO PORESKOJ OSNOVICI, IZNOSU PDV-a I UKUPNOJ VREDNOSTI
            //PRODJEMO KROZ CEO OBJEKAT I SABEREMO SVE REDOVE ZA OVE CETIRI KOLONE
            const suma = Object.entries(stavkeRacuna).map(([id, stavka]) => {
                return {
                  ukpVrednostStavke: stavka.ukpVrednostStavke,
                  iznosPdvStavke: stavka.iznosPdvStavke,
                  poreskaOsnovicaStavke: stavka.poreskaOsnovicaStavke,
                  odabraniRabatCifra: stavka.odabraniRabatCifra
                };
              }).reduce((acc, currentValue) => {
                return {
                  ukpVrednostStavke: acc.ukpVrednostStavke + currentValue.ukpVrednostStavke,
                  iznosPdvStavke: acc.iznosPdvStavke + currentValue.iznosPdvStavke,
                  poreskaOsnovicaStavke: acc.poreskaOsnovicaStavke + currentValue.poreskaOsnovicaStavke,
                  odabraniRabatCifra: acc.odabraniRabatCifra + currentValue.odabraniRabatCifra
                };
              }, { ukpVrednostStavke: 0, iznosPdvStavke: 0, poreskaOsnovicaStavke: 0, odabraniRabatCifra: 0 });
  
              dispatch(setSumaSvihStavkiPoreskaOsnovicaStavke(Number(suma.poreskaOsnovicaStavke.toFixed(1))));
              dispatch(setSumaSvihStavkiIznosPdvStavke(Number(suma.iznosPdvStavke.toFixed(1))));
              dispatch(setSumaSvihStavkiUkpVrednostStavke(Number(suma.ukpVrednostStavke.toFixed(1))));
              dispatch(setSumaSvihStavkRabataStavke(Number(suma.odabraniRabatCifra.toFixed(1))));

    return(

        <>
            

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table" /*stickyHeader*/>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Rb.</TableCell>
                                    <TableCell align="center">Artikal</TableCell>
                                    <TableCell align="center">JM</TableCell>
                                    <TableCell align="center">Količina</TableCell>

                                    <TableCell align="center">Cena</TableCell>
                                    <TableCell align="center">Poreska osnovica</TableCell>
                                    <TableCell align="center">Iznos PDV</TableCell>

                                    <TableCell align="center">Rabat</TableCell>
                                    <TableCell align="center">Rabat UKP</TableCell>
                                    <TableCell align="center">PDV</TableCell>
                                    {/* <TableCell align="center">Cena sa PDV</TableCell> */}
                                    <TableCell align="center">Ukupno</TableCell>
                                    <TableCell align="center">Ukloni</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                

                                        {Object.entries(stavkeRacuna).map(([id, stavka]) => (
                                            <TableRow key={id}>
                                                    <TableCell align="center">{parseInt(id) + 1}.</TableCell>
                                                    <TableCell align="center">{stavka.imeArtikla}</TableCell>
                                                    <TableCell align="center">{stavka.jedinicaMere}</TableCell>
                                                    <TableCell align="center">{stavka.kolicina}</TableCell>

                                                    <TableCell align="center">{stavka.cenaStavke}</TableCell>
                                                    <TableCell align="center">{stavka.poreskaOsnovicaStavke}</TableCell>
                                                    <TableCell align="center">{stavka.iznosPdvStavke}</TableCell>

                                                    <TableCell align="center">{stavka.rabatStavkeVrednost}%</TableCell>
                                                    <TableCell align="center">{stavka.odabraniRabatCifra}</TableCell>
                                                    <TableCell align="center">{stavka.pdvStavkeVrednost}%</TableCell>
                                                    {/* <TableCell align="center">Cena sa PDV</TableCell> */}
                                                    <TableCell align="center">{stavka.ukpVrednostStavke}</TableCell>
                                                    <TableCell align="center"><Button variant="contained" color="error" size="small" onClick={(e:any) => ukloniStavkuIzObjekta(stavka.idReda)}>Ukloni</Button> </TableCell>
                                            </TableRow>
                                        ))}


                                </TableBody>
                        </Table>
                    </TableContainer>    

            
        </>
     )
}

export default TabelaStavkiPrivremena;