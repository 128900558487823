import { Box, Button, Container, Grid, TextField, Typography } from '@mui/material';
import { useState } from "react";
import { setProveraStanja } from '../redux/stranice/login';
import { useDispatch, useSelector } from 'react-redux';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { RootState } from '../redux/store';


const Login = () => {

  
  const proveraStanja = useSelector((state: RootState) => state.logovanje.proveraStanja);
  const defaultniUrl = useSelector((state: RootState) => state.defaultPodaci.defaultniUrl);

  const MySwal = withReactContent (Swal); //sweetalert2
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [korisnickoIme, setKorisnickoIme] = useState('');
  const [lozinka, setLozinka] = useState('');

  const handleChangeKorisnickoIme = (event:any) => {setKorisnickoIme(event.target.value)};
  const handleChangeLozinka = (event:any) => {setLozinka(event.target.value)};

  //dispatch(setProveraStanja(0)); 

  const styles = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh"
  };

      const logovanje = async () => {

        if(korisnickoIme != "" || lozinka != ""){
                      fetch(`${defaultniUrl}logovanje`, {
                    
                          method: 'POST', 
                          headers: {
                          'Content-Type': 'application/json'
                          },
                          body: JSON.stringify({
                            korisnickoIme:korisnickoIme,
                            lozinka:lozinka
                                  })        
                              }).then(res => res.json())
                              .then(data => {                            
                                  if(data != ""){
                                    if(data.broj == 1){

                                      dispatch(setProveraStanja(data.broj)); 
                                      navigate('/racun');
                                    }else{
                                          MySwal.fire({
                                            title: 'Pogrešno uneti podaci!',
                                            //text: '',
                                            //icon: 'info',
                                            timer: 4000,
                                            showCancelButton: false,
                                            showConfirmButton: false,
                                            icon: 'warning',
                                            //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                                        })
                                        dispatch(setProveraStanja(0)); 
                                    }
                                    

                                  }
                        })
        }else{
              MySwal.fire({
                title: 'Korisničko ime i lozinka su obavezni podaci!',
                //text: '',
                //icon: 'info',
                timer: 4000,
                showCancelButton: false,
                showConfirmButton: false,
                icon: 'warning',
                //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
            })
        }

      }

  return (
    <>
      <Box component="main" style={styles}>
        <Container maxWidth="sm">
            <TextField
              fullWidth
              label="Korisničko ime"
              margin="normal"
              name="email"
              onChange={handleChangeKorisnickoIme}
              type="email"
              variant="outlined"
            />
            <TextField
              fullWidth
              label="Lozinka"
              margin="normal"
              name="Lozinka"
              onChange={handleChangeLozinka}
              type="Lozinka"
            //  value={formik.values.password}
              variant="outlined"
            />
            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={  (event) => { logovanje(); } } 
              >
                Prijavi se
                {/* Prijavi se {proveraStanja} */}
              </Button>
            </Box>
        </Container>
      </Box>
    </>
  );
};

export default Login;
