import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { setStranica, isprazniFakturu, isprazniStavkeFakture  } from '../../../../redux/stranice/izlazneFakture';

export const NazadNaFakture = () => {

  const dispatch = useDispatch();

  const nazadNaFakture = () => { 
    dispatch(isprazniFakturu());
    dispatch(isprazniStavkeFakture());
    dispatch(setStranica(1));    
  }

 return(
    <Button style={{ width: '150px'}} variant="contained" color="warning"  onClick={() => nazadNaFakture()}>Nazad</Button> 
 )
};

export default NazadNaFakture;