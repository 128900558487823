import {  useEffect, useState } from "react";
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { setStranica } from '../../../redux/stranice/ulazneFakture';
import { SifarnikArtikli } from '../../selectSifarnici/Artikli';


export const PrikazFaktureUlazne = () => {

  const dispatch = useDispatch();
  //const stranica = useSelector((state: RootState) => state.ulazneFakture.stranica);
  const idFakture = useSelector((state: RootState) => state.ulazneFakture.idFakture);
  const [stavkeFakture, setStavkeFakture] = useState([]);
  const defaultniUrl = useSelector((state: RootState) => state.defaultPodaci.defaultniUrl);

  const td = {
    border: '1px solid black',
    borderLeft: 'none',
    borderTop: 'none',
    padding: '10px'
   };
   const tabela = {
    width: '100%'
  };
  const th = {
    border: '1px solid black',
    borderLeft: 'none',
    
    };

  const prikazFakture = async () => {

    fetch(`${defaultniUrl}podaci_fakture_ulazne`, {
            method: 'POST', 
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                idFakture:idFakture
                    })        
                }).then(res => res.json())
                .then(data => {                            
                    if(data !== ""){
                     
                     // setData(data);
                     // setFilter(data); 

                      //stavke fakture
                      const stavkeFakture = data.stavke_ulazne_fakture.map((row:any, index:any) =>  (
                        <tr key={row.id} >
                           {/* <td style={{...td, borderLeft: '1px solid black'}} align="center">{index+1}.</td> */}
                           <td style={td} align="center">{row.redni_broj}</td>
                           <td style={td} align="center">{row.ulazna_sifra_stavke}</td>
                           <td style={td} align="center">{row.naziv}</td>
                           <td style={td} align="center"><SifarnikArtikli/></td>
                           <td style={td} align="center">{row.jedinica_mere}</td>
                           <td style={td} align="center">{row.kolicina}</td>
                           <td style={td} align="center">{row.cena}</td>
                           <td style={td} align="center">{row.poreska_osnovica}</td>
                           <td style={td} align="center">{row.iznos_pdv}</td>
                           <td style={td} align="center"><Button  /*onClick={}*/ color="success" variant="contained">Preuzmi</Button></td>
                           {/* <td style={td} align="center">{row.ukupna_vrednost}</td> */}
                        </tr>
                      ))
                      setStavkeFakture(stavkeFakture);

                      
                    }              
                })
}


useEffect(() => { 
    prikazFakture();  
},[]);





  const nazadNaFakture = () => { 
    dispatch(setStranica(1));    
  }







 return(
   <>

<p>id fakture: {idFakture}</p>

<table style={tabela} cellSpacing ="0">
                                  
                                      <thead>
                                          <tr>
                                              <th style={{...th, borderLeft: '1px solid black'}}>R.B</th>
                                              <th style={th}>SIFRA</th>
                                              <th style={th}>NAZIV</th>
                                              <th style={th}>NAZIV</th>
                                              <th style={th}>JEDINICA MERE</th>
                                              <th style={th}>KOLIČINA</th>
                                              <th style={th}>CENA</th>
                                              <th style={th}>P.OSNOVICA</th>
                                              <th style={th}>PDV</th>
                                              <th style={th}>Preuzmi</th>
                                              {/* <th style={th}>UKUPNO</th> */}
                                          </tr>
                                      </thead>

                                      <tbody>
                                        {stavkeFakture}
                                      </tbody>
                                  
                              </table>


<Button style={{ width: '150px'}} variant="contained" color="warning"  onClick={() => nazadNaFakture()}>Nazad</Button> 
   </>
 )
};


export default PrikazFaktureUlazne;