import TextField from '@mui/material/TextField';
import { Button, Box, Typography } from '@mui/material';
import { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setStranica } from '../../redux/stranice/klijenti';


export const IzmeniKlijenta = (props:any) => {

    const dispatch = useDispatch();
    const MySwal = withReactContent (Swal); //sweetalert2
    const idKlijenta = useSelector((state: RootState) => state.klijenti.idKlijenta);
    const defaultniUrl = useSelector((state: RootState) => state.defaultPodaci.defaultniUrl);
    const [ime, setIme] = useState('');
    const [adresa, setAdresa] = useState('');
    const [mesto, setMesto] = useState('');
    const [pib, setPib] = useState('');
    const [maticniBroj, setMaticniBroj] = useState('');
    const [email, setEmail] = useState('');
    const [telefon, setTelefon] = useState('');
    const [zr, setZr] = useState('');
    const [jbkjs, setJbkjs] = useState('');


    const handleChangeIme = (event:any) => {setIme(event.target.value);};
    const handleChangeAdresa = (event:any) => {setAdresa(event.target.value);};
    const handleChangeMesto = (event:any) => {setMesto(event.target.value);};
    const handleChangePib = (event:any) => {setPib(event.target.value);};
    const handleChangeMaticniBroj = (event:any) => {setMaticniBroj(event.target.value);};
    const handleChangeEmail = (event:any) => {setEmail(event.target.value);};
    const handleChangeTelefon = (event:any) => {setTelefon(event.target.value);};
    const handleChangeZr = (event:any) => {setZr(event.target.value);};
    const handleChangeJbkjs = (event:any) => {setJbkjs(event.target.value);};

    const naTabeluKlijenta = () => {
      dispatch(setStranica(1));
    };

   
const PrikazKlijentovihPodataka = async () => {
 
            fetch(`${defaultniUrl}odredjeni_klijent`, {
                    method: 'POST', 
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                      idKlijenta:idKlijenta
                            })        
                        }).then(res => res.json())
                        .then(data => {

                          setIme(data[0].ime);
                          setAdresa(data[0].adresa);
                          setMesto(data[0].mesto);
                          setPib(data[0].pib);
                          setMaticniBroj(data[0].maticni_br );
                          setEmail(data[0].email);
                          setTelefon(data[0].telefon);
                          setZr(data[0].ziro_racun);
                          setJbkjs(data[0].jbkjs);
             
                        })
    }


    useEffect(() => { 
      PrikazKlijentovihPodataka();  
    },[]);

        

    const izmeniKlijenta = () => {
      
                  fetch(`${defaultniUrl}izmeni_klijenta`, {
                          method: 'POST', 
                          headers: {
                          'Content-Type': 'application/json'
                          },
                          body: JSON.stringify({
                            idKlijenta:idKlijenta,
                            ime:ime,
                            adresa:adresa,
                            mesto:mesto,
                            pib:pib,
                            maticniBroj:maticniBroj,
                            email:email,
                            telefon:telefon,
                            zr:zr,
                            jbkjs:jbkjs
                          })        
                              }).then(res => res.json())
                              .then(data => {
                                                    if(data.poruka == "uspesno"){ 
                                                      MySwal.fire({
                                                      title: `Uspešno izmenjeni podaci o klijentu`,
                                                      //text: '',
                                                      //icon: 'info',
                                                      timer: 4000,
                                                      showCancelButton: false,
                                                      showConfirmButton: false,
                                                      icon: 'success',
                                                      //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                                                      })
                                                        dispatch(setStranica(1));
                                                    }else{
                                                      MySwal.fire({
                                                      title: `Greška`,
                                                      //text: '',
                                                      //icon: 'info',
                                                      timer: 4000,
                                                      showCancelButton: false,
                                                      showConfirmButton: false,
                                                      icon: 'error',
                                                      //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                                                      })
                                                    }
                  
                              })
        
    }

    return(
      <>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          m:2
        }}
      >
        <Typography sx={{ m: 1 }} variant="h4">IZMENI KLIJENTA</Typography>
        <Box sx={{ m: 1 }}>
            <Button  onClick={naTabeluKlijenta} color="warning" variant="contained">Nazad</Button>
        </Box>
      </Box>
      <br/>
      <Box style={{padding:"20px", width: '30%'}}>      
          <TextField   
                  size="small"
                  required                    
                  fullWidth
                  onChange={handleChangeIme}
                  label="Ime"
                  variant="outlined"
                  value={ime}
          />
          <br/><br/>      
          <TextField
                  size="small"
                  required
                  fullWidth
                  onChange={handleChangeAdresa}
                  label="Adresa"
                  variant="outlined"
                  value={adresa}
          />
          <br/><br/>  
          <TextField
                  size="small"
                  required
                  fullWidth
                  onChange={handleChangeMesto}
                  label="Mesto"
                  variant="outlined"
                  value={mesto}
          />
          <br/><br/>
          <TextField
                  size="small"
                  required
                  fullWidth
                  onChange={handleChangePib}
                  label="Pib"
                  variant="outlined"
                  value={pib}
                  inputProps={{
                          maxLength: 9, 
                        }}
          />
          <br/><br/>
          <TextField
                  size="small"
                  required
                  fullWidth
                  onChange={handleChangeMaticniBroj}
                  label="Matični broj"
                  variant="outlined"
                  value={maticniBroj}
                  inputProps={{
                          maxLength: 8, 
                        }}
          />
          <br/><br/>
          <TextField
                  size="small"
                  required
                  fullWidth
                  onChange={handleChangeEmail}
                  label="Email"
                  variant="outlined"
                  value={email}
          />
          <br/><br/>
          <TextField
                  size="small"
                  required
                  fullWidth
                  onChange={handleChangeTelefon}
                  label="Telefon"
                  variant="outlined"
                  value={telefon}
          />
          <br/><br/>
          <TextField
                  size="small"
                  required
                  fullWidth
                  onChange={handleChangeZr}
                  label="Žiro račun"
                  variant="outlined"
                  value={zr}
          /> 
          <br/>
          <br/>
          <TextField
                  size="small"
                  fullWidth
                  onChange={handleChangeJbkjs}
                  label="JBKJS - opciono"
                  variant="outlined"
                  value={jbkjs}
                  //style={{border:"2px solid black", borderRadius: "10px"}}
          />       
          <br/>
          <br/>
          <Button fullWidth color="success" variant="contained" onClick={  (event) => { izmeniKlijenta(); } } > Sačuvaj </Button>
      </Box>
</>    
     )
}

export default IzmeniKlijenta;