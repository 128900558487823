import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import xmlbuilder from 'xmlbuilder';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const PosaljiNaEFakture = () => {

  const defaultniUrl = useSelector((state: RootState) => state.defaultPodaci.defaultniUrl);
  const MySwal = withReactContent (Swal); //sweetalert2
  const idFakture = useSelector((state: RootState) => state.izlazneFakture.idFakture);
  const ime = useSelector((state: RootState) => state.izlazneFakture.faktura[0].ime);
  const adresa = useSelector((state: RootState) => state.izlazneFakture.faktura[0].adresa);
  //const mestoOtpreme = useSelector((state: RootState) => state.izlazneFakture.faktura[0].mestoOtpreme);
  const pib = useSelector((state: RootState) => state.izlazneFakture.faktura[0].pib);
  const maticniBr = useSelector((state: RootState) => state.izlazneFakture.faktura[0].maticniBr);
  //const sifraDelatnosti = useSelector((state: RootState) => state.izlazneFakture.faktura[0].sifraDelatnosti);
  const zr = useSelector((state: RootState) => state.izlazneFakture.faktura[0].zr);
  //const opis = useSelector((state: RootState) => state.izlazneFakture.faktura[0].opis);  //opis admina firme
  //const telefon = useSelector((state: RootState) => state.izlazneFakture.faktura[0].telefon);
  //const fixtelefon = useSelector((state: RootState) => state.izlazneFakture.faktura[0].fixtelefon);
  //const email = useSelector((state: RootState) => state.izlazneFakture.faktura[0].email);

  const imeKlijenta = useSelector((state: RootState) => state.izlazneFakture.faktura[0].imeKlijenta);
  const adresaKlijenta = useSelector((state: RootState) => state.izlazneFakture.faktura[0].adresaKlijenta);
  const mestoKlijenta = useSelector((state: RootState) => state.izlazneFakture.faktura[0].mestoKlijenta);
  const pibKlijenta = useSelector((state: RootState) => state.izlazneFakture.faktura[0].pibKlijenta);
  const maticniBrKlijenta = useSelector((state: RootState) => state.izlazneFakture.faktura[0].maticniBrKlijenta);
  //const telefonKlijenta = useSelector((state: RootState) => state.izlazneFakture.faktura[0].telefonKlijenta);
  const brojFakture = useSelector((state: RootState) => state.izlazneFakture.faktura[0].brojFakture);
  //const datumFakture = useSelector((state: RootState) => state.izlazneFakture.faktura[0].datumFakture);
  var datumDospeca = useSelector((state: RootState) => state.izlazneFakture.faktura[0].datumDospeca);
  const ukupnaPdvFak = useSelector((state: RootState) => state.izlazneFakture.faktura[0].ukupnaPdvFak);
  const poreskaOsnovica = useSelector((state: RootState) => state.izlazneFakture.faktura[0].poreskaOsnovica);
  const ukupnaVrednostFak = useSelector((state: RootState) => state.izlazneFakture.faktura[0].ukupnaVrednostFak);

  const StavkeFakture = useSelector((state: RootState) => state.izlazneFakture.stavkeFakture);

  //konvertovanje danasnjeg datuma
  const selectedDateOnly = new Date();
  const day = String(selectedDateOnly.getDate()).padStart(2, '0');
  const month = String(selectedDateOnly.getMonth() + 1).padStart(2, '0');
  const year = selectedDateOnly.getFullYear();
  const danasnjiDatum = `${year}-${month}-${day}`;
  //konvertovanje danasnjeg datuma

  //konvertovanje datuma dospeca
  var deloviDatuma = datumDospeca.split('/');
  var dan = parseInt(deloviDatuma[0], 10);
  var mesec = parseInt(deloviDatuma[1], 10);
  var godina = parseInt(deloviDatuma[2], 10);
  var datum = new Date(godina, mesec - 1, dan);
  datumDospeca = datum.getFullYear() + '-' + padLeftZero(datum.getMonth() + 1) + '-' + padLeftZero(datum.getDate());
  function padLeftZero(broj:any) {
    return broj < 10 ? '0' + broj : broj;
  }
  //konvertovanje datuma dospeca


  const posaljiFakturuNaEFakture = () => {
    // Kreiranje novog XML dokument koristeći xmlbuilder
    const xml = xmlbuilder.create('Invoice', { version: '1.0', encoding: 'UTF-8' }).att('xmlns', 'urn:oasis:names:specification:ubl:schema:xsd:Invoice-2')
                                                                                   .att('xmlns:cac', 'urn:oasis:names:specification:ubl:schema:xsd:CommonAggregateComponents-2')
                                                                                   .att('xmlns:cbc', 'urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2')
                                                                                   .att('xmlns:cec', 'urn:oasis:names:specification:ubl:schema:xsd:CommonExtensionComponents-2')
                                                                                   .att('xmlns:sbt', 'http://mfin.gov.rs/srbdt/srbdtext')
                                                                                   .att('xmlns:xsd', 'http://www.w3.org/2001/XMLSchema')
                                                                                   .att('xmlns:xsi', 'http://www.w3.org/2001/XMLSchema-instance');
  

       xml.ele('cbc:CustomizationID', 'urn:cen.eu:en16931:2017#compliant#urn:mfin.gov.rs:srbdt:2021').up();
       xml.ele('cbc:ID', brojFakture).up();                                                                        //BROJ FAKTURE
       xml.ele('cbc:IssueDate', danasnjiDatum).up();                                                               //DATUM FAKTURE/ IZDAVANJA  -- DANASNJI DATUM
       xml.ele('cbc:DueDate', datumDospeca).up();                                                                  //DATUM DOSPECA
       xml.ele('cbc:InvoiceTypeCode', '380').up();                                                                 //KOD DA JE FAKTURA --ZAKUCANO OVAKO 
       xml.ele('cbc:Note', '').up();                                                                               //NEKA NJIHOVA PORUKA OPIS ?
       xml.ele('cbc:DocumentCurrencyCode', 'RSD').up();                                                            //VALUTA
       const InvoicePeriod = xml.ele('cac:InvoicePeriod');
       InvoicePeriod.ele('cbc:DescriptionCode', '35');                                                             //ZA POREZE KAD DOSTIZU NA PLACANJE (PROVERITI)

       //PODACI O PRODAVCU - MOJI ADMIN PODACI
       const AccountingSupplierParty = xml.ele('cac:AccountingSupplierParty').ele('cac:Party');
       AccountingSupplierParty.ele('cbc:EndpointID', { schemeID: '9948' }, pib).up();                              //PIB
       const PartyIdentification = AccountingSupplierParty.ele('cac:PartyIdentification');
       PartyIdentification.ele('cbc:ID', pib).up();                                                                //PIB
       const PartyName = AccountingSupplierParty.ele('cac:PartyName');
       PartyName.ele('cbc:Name', ime).up();                                                                        //NAZIV - IME FIRME
       const PostalAddress = AccountingSupplierParty.ele('cac:PostalAddress');
       PostalAddress.ele('cbc:StreetName', adresa);
       PostalAddress.ele('cbc:CityName', '');
       const Country = PostalAddress.ele('cac:Country');
       Country.ele('cbc:IdentificationCode', 'RS');                                                                //ZAKUCANO
       Country.ele('cbc:Name', 'Serbia');
       const PartyTaxScheme = AccountingSupplierParty.ele('cac:PartyTaxScheme');
       PartyTaxScheme.ele('cbc:CompanyID', String('RS'+pib));                                                      //RS + PIB
       PartyTaxScheme.ele('cac:TaxScheme').ele('cbc:ID', 'VAT');                                                   //ZAKUCANO
       const PartyLegalEntity = AccountingSupplierParty.ele('cac:PartyLegalEntity');
       PartyLegalEntity.ele('cbc:RegistrationName', ime);                                                          //NAZIV - IME FIRME
       PartyLegalEntity.ele('cbc:CompanyID', maticniBr);                                                           //MATICNI BROJ  ?

       //PODACI O KUPCU - KLIJENTU
       const AccountingCustomerParty = xml.ele('cac:AccountingCustomerParty');
       AccountingCustomerParty.ele('cbc:CustomerAssignedAccountID', '1').up();                                     //ZAKUCANO
       const Party1 = AccountingCustomerParty.ele('cac:Party');
       Party1.ele('cbc:EndpointID', { schemeID: '9948' }, pibKlijenta).up();                                       //PIB
       const PartyIdentification1 = Party1.ele('cac:PartyIdentification');
       PartyIdentification1.ele('cbc:ID', pibKlijenta).up();                                                       //PIB
       const PartyName1 = Party1.ele('cac:PartyName');
       PartyName1.ele('cbc:Name', imeKlijenta).up();                                                               //NAZIV - IME FIRME KLIJENTA
       const PostalAddress1 = Party1.ele('cac:PostalAddress');
       PostalAddress1.ele('cbc:StreetName', adresaKlijenta);                                                       //ADRESA
       PostalAddress1.ele('cbc:CityName', mestoKlijenta);                                                          //GRAD
       const Country1 = PostalAddress1.ele('cac:Country');
       Country1.ele('cbc:IdentificationCode', 'RS');                                                               //ZAKUCANO
       Country1.ele('cbc:Name', 'Serbia');                                                                         //ZAKUCANO
       const PartyTaxScheme1 = Party1.ele('cac:PartyTaxScheme');
       PartyTaxScheme1.ele('cbc:CompanyID', String('RS'+pibKlijenta));                                             //RS + PIB
       PartyTaxScheme1.ele('cac:TaxScheme').ele('cbc:ID', 'VAT');                                                  //ZAKUCANO
       const PartyLegalEntity1 = Party1.ele('cac:PartyLegalEntity');
       PartyLegalEntity1.ele('cbc:RegistrationName', imeKlijenta);                                                 //NAZIV - IME FIRME KLIJENTA
       PartyLegalEntity1.ele('cbc:CompanyID', maticniBrKlijenta);                                                  //MATICNI BROJ (OPCIONO)


       //DATUM PROMETA
       const Delivery = xml.ele('cac:Delivery');
       Delivery.ele('cbc:ActualDeliveryDate', danasnjiDatum).up();                                                 //DATUM PROMETA -- DATUM FAKTURE  


       //PLACANJE
       const PaymentMeans = xml.ele('cac:PaymentMeans');
       PaymentMeans.ele('cbc:PaymentMeansCode', '30').up();                                                        //KAKO SE PLACA -- ZAKUCANO
       PaymentMeans.ele('cbc:PaymentID').up();                                                                     //MODEL I POZIV NA BROJ -- NE ZNAM DA LI JE OBAVEZNO ? '(97) 81-1293-112023-183' ??????????
       PaymentMeans.ele('cac:PayeeFinancialAccount').ele('cbc:ID', zr).up();                                       //ZIRO RACUN PRODAVCA/ NAS--- NAKOJI TREBA DA SE PLATI...moze vise komada jedan zarez drugi zarez treci


       //BLOK ZA POREZ
       const TaxTotal = xml.ele('cac:TaxTotal');
       TaxTotal.ele('cbc:TaxAmount', { currencyID: 'RSD' }, ukupnaPdvFak).up();                                    //IZNOS POREZA  UKUPNO
       const TaxSubtotal = TaxTotal.ele('cac:TaxSubtotal');
       TaxSubtotal.ele('cbc:TaxableAmount', { currencyID: 'RSD' }, poreskaOsnovica).up();                          //IZNOS OSNOVICE ZA 20% POREZA
       TaxSubtotal.ele('cbc:TaxAmount', { currencyID: 'RSD' }, ukupnaPdvFak).up();                                 //IZNOS POREZA  ZA 20% POREZA
       const TaxCategory = TaxSubtotal.ele('cac:TaxCategory');
       TaxCategory.ele('cbc:ID', 'S').up();
       TaxCategory.ele('cbc:Percent', '20.00').up();                                                               //SEMA POREZA
       const TaxScheme = TaxCategory.ele('cac:TaxScheme');
       TaxScheme.ele('cbc:ID', 'VAT').up();


       //UKUPNE CIFRE
       const LegalMonetaryTotal = xml.ele('cac:LegalMonetaryTotal');
       LegalMonetaryTotal.ele('cbc:LineExtensionAmount', { currencyID: 'RSD' }, poreskaOsnovica).up();             //OSNOVICA ZA POREZ
       LegalMonetaryTotal.ele('cbc:TaxExclusiveAmount', { currencyID: 'RSD' }, '0').up();                          //IZNOS NA KOJI SE NE PLACA POREZ - TEBI JE 0
       LegalMonetaryTotal.ele('cbc:TaxInclusiveAmount', { currencyID: 'RSD' }, ukupnaVrednostFak).up();            //UKUPAN IZNOS FAKTURE
       LegalMonetaryTotal.ele('cbc:AllowanceTotalAmount', { currencyID: 'RSD' }, '0.00').up();                     //ZAKUCANO 0 -- MOZDA UMANJENJE
       LegalMonetaryTotal.ele('cbc:PrepaidAmount', { currencyID: 'RSD' }, '0').up();                               //PLACENO AVANSOM -- TEBI ZAKUCANO 0
       LegalMonetaryTotal.ele('cbc:PayableAmount', { currencyID: 'RSD' }, ukupnaVrednostFak).up();                 //KOLIKO TREBA DA SE PLATI -- TEBI IZNOS FAKTURE


       
       StavkeFakture.map((row:any, index:any) =>  {
        
      
      
       //STAVKE FAKTURE - OVO JE U LOOP-U
       const InvoiceLine = xml.ele('cac:InvoiceLine');
       InvoiceLine.ele('cbc:ID', String(index + 1)).up();                                                          //REDNI BROJ STAVKE 
       InvoiceLine.ele('cbc:InvoicedQuantity', { unitCode: row.unitCode }, row.kolicina).up();                     //KOLICINA --  UNIT CODE= H87  IMA SIFARNIK - OVO JE ZA KOMAD   
       InvoiceLine.ele('cbc:LineExtensionAmount', { currencyID: 'RSD' }, row.poreska_osnovica).up();               //UKUPAN IZNOS BEZ POREZA ZA OVU STAVKU - OSNOVICA (AKO JE CENA BEZ POREZA 100 I IMAS 3 KOM OVDE SALJES 300 DIN)  PROVERITI DA LI SU NJEMU MALOPRODAJNE I VELEPRODAJNE CENE SA ILI BEZ POREZA 
       const Item = InvoiceLine.ele('cac:Item');
       Item.ele('cbc:Description', row.ime).up();                                                                  //OPIS  MOZDA NIJE OBAVEZNO ????????????????????????????
       Item.ele('cbc:Name', row.ime).up();                                                                         //NAZIV STAVKE 
       const SellersItemIdentification = Item.ele('cac:SellersItemIdentification');
       SellersItemIdentification.ele('cbc:ID', String(row.id)).up();                                               //TVOJ ID STAVKE FAKTURE  SALJE SE KAKO VARCHAR 
       const ClassifiedTaxCategory = Item.ele('cac:ClassifiedTaxCategory');
       ClassifiedTaxCategory.ele('cbc:ID', 'S').up();
       ClassifiedTaxCategory.ele('cbc:Percent', '20.00').up();                                                     //ZAKUCANO ZA POREZ NA STAVCI -- 20
       const TaxScheme1 = ClassifiedTaxCategory.ele('cac:TaxScheme');
       TaxScheme1.ele('cbc:ID', 'VAT').up();                                                                       //ZAKUCANO
       const Price = InvoiceLine.ele('cac:Price');
       Price.ele('cbc:PriceAmount', { currencyID: 'RSD' }, row.cena).up();                                         //CENA ATRIKKLA - MALOPRODAJNA - VELEPRODAJNA

       })

       const xmlString = xml.toString({ pretty: true });
       //console.log(xmlString);

  
                  fetch(`${defaultniUrl}posalji_fakturu_na_e_fakture`, {
                      method: 'POST', 
                      headers: {
                      'Content-Type': 'application/json'
                      },
                      body: JSON.stringify({
                        idFakture:idFakture,
                        xmlString:xmlString
                              })        
                          }).then(res => res.json())
                          .then(data => {                            
                               if(data.poruka == "uspesno"){
                                    MySwal.fire({
                                      title: 'Faktura poslata na E-sef!',
                                      //text: '',
                                      //icon: 'info',
                                      timer: 4000,
                                      showCancelButton: false,
                                      showConfirmButton: false,
                                      icon: 'success',
                                      //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                                  })
                               }              
                          })
  }



 return(
   <>
        <Button style={{ width: '150px'}} variant="contained" color="error"  onClick={() => posaljiFakturuNaEFakture()}>Pošalji</Button>&nbsp;
   </>
 )
};


export default PosaljiNaEFakture;