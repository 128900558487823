import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setJedinicaMereId } from '../../redux/stranice/magacin';
import { RootState } from '../../redux/store';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';

interface JM {
    id: number;
    naziv: string;
    oznaka: string;
    jm:number;
}

export const SifarnikJedinicaMere = (props:any) => {
    const defaultniUrl = useSelector((state: RootState) => state.defaultPodaci.defaultniUrl);
    const [data, setData] = useState<JM[]>([]);  
    const dispatch = useDispatch();
    //const jm = useSelector((state: RootState) => state.magacin.jedinicaMereId);
    const jm: JM | null = useSelector((state: RootState) => {
        const selectedId = state.magacin.jedinicaMereId;
        return selectedId !== 0 ? data.find(item => item.id === selectedId) || null : null;
      });

    const PrikazSelecta = async () => {
        fetch(`${defaultniUrl}sifarnik_jedinica_mere`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({})        
        })
        .then(res => res.json())
        .then(data => {
            if (data !== "") {
                setData(data);
                //console.log(data)
            }
        })
    }

    useEffect(() => { 
        PrikazSelecta();  
    }, []);


    return (       
        <FormControl style={{ width: '100%'}}>
        <Autocomplete
            //key={idArtikla}
            id="jmId"    
            value={jm ? { id: jm.id, label: `${jm.oznaka} - ${jm.naziv}`, vrednost: jm.oznaka } : null}
            options={data.map((option) => ({ id: option.id, label: `${option.oznaka} - ${option.naziv}`,  vrednost: option.oznaka }))}
            renderInput={(params) => <TextField {...params} label="Jedinica mere" />}
            getOptionLabel={(option) => option.label}
            onChange={(event, newValue: { label: string; id: any; vrednost: string } | null) => {
                if (newValue) {
                    dispatch(setJedinicaMereId(newValue.id));
                } else {
                    // Kada se izvrši brisanje (clear)
                    dispatch(setJedinicaMereId(0));
                }
            }}
        />
    </FormControl>
            )
}

export default SifarnikJedinicaMere;