import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import TextField from '@mui/material/TextField';
import { Box, Typography } from '@mui/material';
import { setStranica, setIdFakture, setIdFaktureEFakture } from '../../../redux/stranice/izlazneFakture';
import Button from '@mui/material/Button';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import SyncIcon from '@mui/icons-material/Sync';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

type FaktureIzlazne = {
  id: number;
  broj: string;
  ime: string;
  datum_pravljenja_fakture: string;
  datum_dospeca: string;
  valuta: string;
  nacin_placanja: string;
  ukupna_vrednost: string;
  status: string;
  e_fakture_status: string;
  e_fakture_id:number;
};


export const TabelaIzlazneFak = (props:any) => {

     const MySwal = withReactContent (Swal); //sweetalert2
    const dispatch = useDispatch();
    const [data, setData] = useState<FaktureIzlazne[]>([]);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState<FaktureIzlazne[]>([]);
    const defaultniUrl = useSelector((state: RootState) => state.defaultPodaci.defaultniUrl);


    //const [startingRowNumber, setStartingRowNumber] = useState(1);


    const columns:any = [
      // {
      //     name: 'RB.',
      //     //cell: (row: any, index: number) => startingRowNumber + index + '.',
      //     cell: (row:any, index: any) => index+1+'.',
      // },
      {
        name: "BROJ FAKTURE",
        selector: (row: FaktureIzlazne) => row.broj.toString(),
      },
      
      {
        name: "KLIJENT",
        selector: (row: FaktureIzlazne) => row.ime.toString(),
      },
      {
        name: "DATUM FAKTURE",
        selector: (row: FaktureIzlazne) => row.datum_pravljenja_fakture.toString(),
      },
      { 
          name: "DATUM DOSPEĆA",
          selector: (row: FaktureIzlazne) => row.datum_dospeca.toString(),
      },
      {
          name: "VALUTA",
          selector: (row: FaktureIzlazne) => row.valuta.toString(),
      },
      // {
      //     name: "NAČIN PLAĆANJA",
      //     selector: (row: FaktureIzlazne) => row.nacin_placanja.toString(),
      // },
      {
          name: "VREDNOST FAKTURE",
          selector: (row: FaktureIzlazne) => row.ukupna_vrednost.toString(),
      },
      {
          name: "STATUS",
          selector: (row: FaktureIzlazne) => {
            if (row.status === 'Approved') {
              return 'Prihvaćena';
            }else if(row.status === 'Sent') {
              return 'Poslata';
            }else if(row.status === 'New') {
              return 'Na čekanju';
            }else if(row.status === 'Sending') {
              return 'Šalje se';
            }else if(row.status === 'Rejected') {
              return 'Odbijena';
            }else if(row.status === 'Mistake') {
              return 'Greška';
            }else if(row.status === 'Draft') {
              return 'Nacrt';
            }else if(row.status === 'Cancelled') {
              return 'Otkazana';
            }else {
              return row.status.toString();  //storno
            }
          },
      },
      {
        name: "PROVERI STATUS",
        cell: (row: FaktureIzlazne) => (
          <Button onClick={() => proveraStatusa(row.id, row.e_fakture_id, row.status)} variant="contained" color="error" style={{ margin: 'auto' }}><SyncIcon/></Button>
        ),
      }
    ];


    const proveraStatusa = async (idFakture:number,e_fakture_id:number, status: string) => {

      if(e_fakture_id != 0){ 
                fetch(`${defaultniUrl}proveri_status_poslete_fakture_na_e_fakture`, {
                  method: 'POST', 
                  headers: {
                  'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    idFakture:idFakture,
                    idFaktureEFakture:e_fakture_id
                          })        
                      }).then(res => res.json())
                      .then(data => {
                          if(data.status != status){  
                              MySwal.fire({
                                title: 'Status je azuriran',
                                //text: '',
                                //icon: 'info',
                                timer: 4000,
                                showCancelButton: false,
                                showConfirmButton: false,
                                icon: 'success',
                                //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                            })
                            izlazneFakture(); 
                          }else{
                                MySwal.fire({
                                  title: 'Status je ne promenjen',
                                  //text: '',
                                  //icon: 'info',
                                  timer: 4000,
                                  showCancelButton: false,
                                  showConfirmButton: false,
                                  icon: 'warning',
                                  //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                              })
                          }              
                      })
      }else{
        MySwal.fire({
          title: 'Izabrana faktura jos uvek nije poslata na E-sef!',
          //text: '',
          //icon: 'info',
          timer: 4000,
          showCancelButton: false,
          showConfirmButton: false,
          icon: 'warning',
          //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
      })
      }
}

      const izlazneFakture = async () => {
 
            fetch(`${defaultniUrl}izlazne_fakture`, {
                    method: 'POST', 
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                    
                            })        
                        }).then(res => res.json())
                        .then(data => {                            
                            if(data !== ""){
                              console.log(data[0])
                              setData(data);
                              setFilter(data); 
                            }              
                        })
    }


    useEffect(() => { 
      izlazneFakture();  
    },[]);

    useEffect(() => { 
      const result = data.filter((item)=>{
        return (
          item.broj.toLowerCase().includes(search.toLowerCase()) || // Pretražujemo po broju fakture
          item.ime.toLowerCase().includes(search.toLowerCase()) || // Pretražujemo po imenu klijenta
          item.datum_pravljenja_fakture.toLowerCase().includes(search.toLowerCase()) || // Pretražujemo po datumu kreiranja fakture
          item.datum_dospeca.toLowerCase().includes(search.toLowerCase()) || // Pretražujemo po datumu dospeca fakture
          item.ukupna_vrednost.toString().toLowerCase().includes(search.toLowerCase()) || // Pretražujemo po ukupnoj vrednosti
          item.status.toLowerCase().includes(search.toLowerCase())  // Pretražujemo po statusu
        )
      }) 
      setFilter(result);
    },[search]);

    function otvaranjeFakture (row:any) {        
      dispatch(setIdFakture(row.id));
      dispatch(setIdFaktureEFakture(row.e_fakture_id));
      dispatch(setStranica(2));     
    }



    const customRowStyles = [
      {
        when: (row: FaktureIzlazne) => row.status === 'New',
        style: {
          background: 'darkgray',
          color: 'white',
        },
      },
      {
        when: (row: FaktureIzlazne) => row.status === 'Approved',
        style: {
          background: 'linear-gradient(to right, green, red)',
          color: 'white',
        },
      },
      {
        when: (row: FaktureIzlazne) => row.status === 'Storno',
        style: {
          background: 'orange',
          color: 'black',
        },
      },
      {
        when: (row: FaktureIzlazne) => row.status === 'Sent',
        style: {
          background: 'green',
          color: 'white',
        },
      },
    ];


      return (
      <>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            m:2
          }}
        >
          <Typography sx={{ m: 1 }} variant="h4">IZLAZNE FAKTURE</Typography>
          {/* <Box sx={{ m: 1 }}>
              <Button  onClick={} color="success" variant="contained">Dodaj novog klijenta</Button>
          </Box> */}
        </Box>
      
        <br/>
        <Box sx={{ padding:"20px" }}>
        <DataTable
                    columns={columns}
                    data={filter}
                    pagination


                    // paginationServer
                    //   paginationTotalRows={data.length} // Ukupan broj redova
                    //   paginationPerPage={10} // Prilagodite broj redova po stranici po potrebi
                    //   onChangePage={(page) => {
                    //     const newStartingRowNumber = (page - 1) * 10 + 1; // Prilagodite na osnovu broja redova po stranici
                    //     setStartingRowNumber(newStartingRowNumber);
                    //   }}




                    fixedHeader
                    highlightOnHover
                    //selectableRowsHighlight
                    //fixedHeaderScrollHeight='450px'
                    // selectableRows
                    //  actions={<button className='btn btn-sm btn-info'>Dogadjaj</button>}
                    onRowClicked={otvaranjeFakture}
                    subHeader
                    subHeaderComponent={
                      <TextField 
                            size="small"
                            onChange={(e)=>setSearch(e.target.value)}
                            label="Pretraži"
                            variant="outlined"
                            value={search}
                            style={{ width: '300px'}}   
                        />
                      
                    }
                    conditionalRowStyles={customRowStyles}
                    // className={tableClassName}
              />
        </Box>
      </>
  );


}

export default TabelaIzlazneFak;