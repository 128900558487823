import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface StavkeFakture {
    id: number;
    id_artikla: number;
    sifra: string;
    ime: string;
    kolicina: number;
    cena: number;
    poreska_osnovica: number;   
    ukupna_vrednost: number; 
    stanje: number;  
    iznos_rabata: number;
    iznos_pdv: number;      
 }

interface Predracuni {
  stranica: number;
  idPredracuna: number;
  idArtiklaDodajNovi: number;
  odabraniRabatIdNaPredracunu: number;
  odabraniRabatVrednostNaPredracunu: number;
  stavkeFakture: StavkeFakture[];
}

const initialState: Predracuni = {
    stranica: 1,
    idPredracuna: 0,
    idArtiklaDodajNovi: 0,
    odabraniRabatIdNaPredracunu: 1,
    odabraniRabatVrednostNaPredracunu: 0,
    stavkeFakture: []
};

const predracuni = createSlice({
  name: 'predracuni',
  initialState,
  reducers: {
    setStranica: (state, action: PayloadAction<number>) => {
      state.stranica = action.payload;
    },
    setIdPredracuna: (state, action: PayloadAction<number>) => {
        state.idPredracuna = action.payload;
      },
      setIdArtiklaDodajNovi: (state, action: PayloadAction<number>) => {
        state.idArtiklaDodajNovi = action.payload;
      },
      setOdabraniRabatIdNaPredracunu: (state, action: PayloadAction<number>) => {
        state.odabraniRabatIdNaPredracunu = action.payload;
      },
      setOdabraniRabatVrednostNaPredracunu: (state, action: PayloadAction<number>) => {
        state.odabraniRabatVrednostNaPredracunu = action.payload;
      },
    dodajStavkeFakture: (state, action: PayloadAction<StavkeFakture>) => { //SA OVOM FCJ PUNIMO OBJEKAT TJ DODAJEMO STAVKU PO STAVKU
        state.stavkeFakture.push(action.payload);
      },
    isprazniStavkeFakture : (state) => { //SA OVOM FCJ PRAZNIMO OBJEKAT TJ BRISEMO SVE STAVKE
        state.stavkeFakture = [];
      },
  }
});

export const { setStranica, setIdPredracuna, setIdArtiklaDodajNovi, dodajStavkeFakture, isprazniStavkeFakture, setOdabraniRabatIdNaPredracunu, setOdabraniRabatVrednostNaPredracunu } = predracuni.actions;
export const predracuniReducer = predracuni.reducer;


