import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { setReloadKomponenteRacun, setDisejbluj } from '../../redux/stranice/maloprodaja';


export const Buttoni_i_funkcije = () => {

    const dispatch = useDispatch();
    const MySwal = withReactContent (Swal); //sweetalert2
    const defaultniUrl = useSelector((state: RootState) => state.defaultPodaci.defaultniUrl);
    const reloadKomponenteRacun = useSelector((state: RootState) => state.maloprodaja.reloadKomponenteRacun);
    const stavkeRacuna = useSelector((state: RootState) => state.maloprodaja.stavkeRacuna); //objekat u kom su nam sve stavke
    const sumaUkpVrednostRabat = useSelector((state: RootState) => state.sumaStavki.sumaSvihStavkiRabataStavke);  //suma rabata 
    const sumaPoreskeOsnovice = useSelector((state: RootState) => state.sumaStavki.sumaSvihStavkiPoreskaOsnovicaStavke);//suma poreske osnovice
    const sumaUkpIznosPdv = useSelector((state: RootState) => state.sumaStavki.sumaSvihStavkiIznosPdvStavke);//suma pdv-e vrednosti
    const sumaUkpVrednost = useSelector((state: RootState) => state.sumaStavki.sumaSvihStavkiUkpVrednostStavke);//suma ukupne vrednosti

    const odabraniPdvVrednost = useSelector((state: RootState) => state.maloprodaja.odabraniPdvVrednost);
    const tip = useSelector((state: RootState) => state.maloprodaja.tip);
    const idOdabranoglijenta = useSelector((state: RootState) => state.maloprodaja.idOdabranoglijenta);
    const datumPravljenjaFakture = useSelector((state: RootState) => state.maloprodaja.datumPravljenjaFakture);
    const datumDospecaFakture = useSelector((state: RootState) => state.maloprodaja.datumDospecaFakture);
    //const nacinPlacanja = useSelector((state: RootState) => state.maloprodaja.nacinPlacanja);


    const flImeIPrezime = useSelector((state: RootState) => state.maloprodaja.flImeIPrezime);
    const flAdresa = useSelector((state: RootState) => state.maloprodaja.flAdresa);
    const flMesto = useSelector((state: RootState) => state.maloprodaja.flMesto);
    const flLicnaKarta = useSelector((state: RootState) => state.maloprodaja.flLicnaKarta);
    const flTelefon = useSelector((state: RootState) => state.maloprodaja.flTelefon);

    const sacuvajFakturu = () => {
        if(idOdabranoglijenta === 0 ){
            MySwal.fire({
            title: `Izaberite klijenta`,
            //text: '',
            //icon: 'info',
            timer: 4000,
            showCancelButton: false,
            showConfirmButton: false,
            icon: 'warning',
            //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
            })
        }
        else if(datumPravljenjaFakture === '' && tip == 2){
                                MySwal.fire({
                                title: `Datum pravljenja fakture je obavezan podatak`,
                                //text: '',
                                //icon: 'info',
                                timer: 4000,
                                showCancelButton: false,
                                showConfirmButton: false,
                                icon: 'warning',
                                //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                                })
        }
        else if(datumDospecaFakture === '' && tip == 2){
                                MySwal.fire({
                                title: `Datum dospeća fakture je obavezan podatak`,
                                //text: '',
                                //icon: 'info',
                                timer: 4000,
                                showCancelButton: false,
                                showConfirmButton: false,
                                icon: 'warning',
                                //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                                })
        }
        else{     
            fetch(`${defaultniUrl}kreiranje_fakture_i_stavki`, {
                method: 'POST', 
                headers: {
                'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    stavkeRacuna:stavkeRacuna,
                    idOdabranoglijenta:idOdabranoglijenta,
                    tip:tip,
                    datumPravljenjaFakture:datumPravljenjaFakture,
                    datumDospecaFakture:datumDospecaFakture,
                    //nacinPlacanja:nacinPlacanja,
                    sumaPoreskeOsnovice:sumaPoreskeOsnovice,
                    sumaUkpIznosPdv:sumaUkpIznosPdv,
                    sumaUkpVrednostRabat:sumaUkpVrednostRabat,
                    sumaUkpVrednost:sumaUkpVrednost,
                    odabraniPdvVrednost:odabraniPdvVrednost

                        })        
                    })
                    .then(res => res.json())
                    .then(data => {
                        
                        if(data.poruka == "uspesno"){
                                MySwal.fire({
                                title: `Uspešno kreirana faktura`,
                                //text: '',
                                //icon: 'info',
                                timer: 4000,
                                showCancelButton: false,
                                showConfirmButton: false,
                                icon: 'success',
                                //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                                })
                                dispatch(setReloadKomponenteRacun(reloadKomponenteRacun+1)); 
                                dispatch(setDisejbluj(false)); 

                        }else{
                            MySwal.fire({
                                title: `Greška u komunikaciji`,
                                //text: '',
                                //icon: 'info',
                                timer: 4000,
                                showCancelButton: false,
                                showConfirmButton: false,
                                icon: 'error',
                                //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                                })
                        }

                                    
                    })
        }
     }











     const sacuvajPredracun = () => { 
        if(idOdabranoglijenta === 0 ){
            MySwal.fire({
            title: `Izaberite klijenta ili fizičko lice`,
            //text: '',
            //icon: 'info',
            timer: 4000,
            showCancelButton: false,
            showConfirmButton: false,
            icon: 'warning',
            //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
            })
        }
        else{     
            fetch(`${defaultniUrl}kreiranje_predracuna_i_stavki`, {
                method: 'POST', 
                headers: {
                'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    stavkeRacuna:stavkeRacuna,
                    idOdabranoglijenta:idOdabranoglijenta,
                    tip:tip,
                    sumaPoreskeOsnovice:sumaPoreskeOsnovice,
                    sumaUkpIznosPdv:sumaUkpIznosPdv,
                    sumaUkpVrednostRabat:sumaUkpVrednostRabat,
                    sumaUkpVrednost:sumaUkpVrednost,
                    odabraniPdvVrednost:odabraniPdvVrednost,
                    flImeIPrezime:flImeIPrezime,
                    flAdresa:flAdresa,
                    flMesto:flMesto,
                    flLicnaKarta:flLicnaKarta,
                    flTelefon:flTelefon

                        })        
                    })
                    .then(res => res.json())
                    .then(data => {
                        
                        if(data.poruka == "uspesno"){
                                MySwal.fire({
                                title: `Uspešno kreiran predračun`,
                                //text: '',
                                //icon: 'info',
                                timer: 4000,
                                showCancelButton: false,
                                showConfirmButton: false,
                                icon: 'success',
                                //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                                })
                                dispatch(setReloadKomponenteRacun(reloadKomponenteRacun+1)); 
                                dispatch(setDisejbluj(false)); 

                        }else{
                            MySwal.fire({
                                title: `Greška u komunikaciji`,
                                //text: '',
                                //icon: 'info',
                                timer: 4000,
                                showCancelButton: false,
                                showConfirmButton: false,
                                icon: 'error',
                                //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                                })
                        }

                                    
                    })
        }
     }


     const fiskalniPrinter = () => { 
        var odgovor_kase = '';
        var broj_fisk_racuna = '';

        // var podaci = `{
        //     "dateAndTimeOfIssue": "",
        //     "cashier": "Momcilo Stanisavljevic",
        //     "buyerId": "${klijent}",
        //     "buyerCostCenterId": "1053229",
        //     "invoiceType": "Normal",
        //     "transactionType": "Sale",
        //     "omitQRCodeGen": "1",
        //     "omitTextualRepresentation": "0"
        //     ,"items":


        // "payment": [
        //     {
        //         "amount": "#za_placanje#",
        //         "paymentType": "Cash"
        //     }
        // ]};
        // fetch('pin', {
        //     method: 'POST', 
        //     headers: {
        //     'Accept': 'application/json',
        //     'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         pin:'1234'

        //             })        
        //         })
        //         .then(res => res.json())
        //         .then(data => {
                  
        //              odgovor_kase = data.Filecontent;
        //         })


        // if(odgovor_kase == '"0100"'){
        //     fetch('print', {
        //         method: 'POST', 
        //         headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //         },
        //         body: JSON.stringify({
        //             pin:'1234'
    
        //                 })        
        //             })
        //             .then(res => res.json())
        //             .then(data => {
                      
        //                 broj_fisk_racuna = data.Filecontent.invoiceNumber;

        //                                     fetch('update_tabele_fakture', {
        //                                         method: 'POST', 
        //                                         headers: {
        //                                         'Accept': 'application/json',
        //                                         'Content-Type': 'application/json'
        //                                         },
        //                                         body: JSON.stringify({
        //                                             broj_fisk_racuna:broj_fisk_racuna
                                    
        //                                                 })        
        //                                             })
        //                                             .then(res => res.json())
        //                                             .then(data => {
                                                    
        //                                                 broj_fisk_racuna = data.Filecontent.invoiceNumber 
        //                                             })
        //             })
        // }        
     }

    return(
        <>
             {/* predracun */}
            {tip === 1 && 
                            <Button style={{ width: '200px'}} variant="contained" color="secondary" endIcon={<SendIcon />} onClick={() => sacuvajPredracun()} >Sačuvaj predračun</Button >
                            
            }
            {/* faktura */}
            {tip === 2 &&   <> 
                              <Button style={{ width: '200px'}} variant="contained" color="success" endIcon={<SendIcon />} onClick={() => sacuvajFakturu()}>Sačuvaj</Button > 
                              {/* <Button style={{ width: '200px'}} variant="contained" color="error" endIcon={<SendIcon />} >Štampaj</Button >  */}
                            </>
            }
            {/* fiskalni racun */}
            {tip === 3 &&   
                            <Button style={{ width: '200px'}} variant="contained" endIcon={<SendIcon />} onClick={() => fiskalniPrinter()}>Fiskalni printer</Button >
            } 
        </>
    )
}

export default Buttoni_i_funkcije;