import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
//import { RootState } from '../../redux/store';
import { setIdOdabranogKlijenta } from '../../redux/stranice/maloprodaja';
import { setIdKlijentaZaIzv, setImeKlijentaZaIzv } from '../../redux/stranice/izvZbirniArtikli';
import { RootState } from '../../redux/store';

interface Klijenti {
    ime: string;
    id: string;
    value:number;
    label: string;
  }

export const SifarnikKlijenti = (props:any) => {

    const defaultniUrl = useSelector((state: RootState) => state.defaultPodaci.defaultniUrl);
    const [prikaz, setPrikaz] = useState<any>('');
    const dispatch = useDispatch();
    const tip = useSelector((state: RootState) => state.maloprodaja.tip);
    const disejbluj = useSelector((state: RootState) => state.maloprodaja.disejbluj);




    const PrikazSelecta = async () => {
 
            fetch(`${defaultniUrl}klijenti`, {
                    method: 'POST', 
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                    
                            })        
                        }).then(res => res.json())
                        .then(data => {
                            if(data !== ""){ 
                                
                                if(tip === 2 || props.lokacija == "izvestajZbirniArtikli"){        //ako je tip 2(Faktura) onda... 
                                    var filteredData = data.filter((item: any) => item.id !== 1);  //...filterom uklanjamo klijenta sa ID-em 1....to je Fizicko lice           
                                }else{
                                    var filteredData = data;                                      //a ako je tip 1(Predracun) onda pokazujemo i Fizicko lice
                                }


                                // Dodavanje prazne stavke na početak ako je props.lokacija == "izvestajZbirniArtikli" ////DEO SAMO ZA IZVESTAJ
                                if(props.lokacija == "izvestajZbirniArtikli") {
                                    filteredData.unshift({ id: "", ime: "", pib: "" }); // Dodavanje prazne stavke
                                }

                                  const menuHTML =  
                                        <Box>
                                            <FormControl style={{ width: props.lokacija == "izvestajZbirniArtikli" ? '100%' : '250px' }}>                                       
                                                    <Autocomplete 
                                                       disabled={disejbluj}  
                                                    key={tip}                     
                                                        id="free-solo-demo"
                                                        options={filteredData.map((option:any, index:any) => ({  label: `${option.ime} - ${option.pib}`, value: option.id, ime: `${option.ime}` }))}
                                                        renderInput={(params) => <TextField  {...params} label="Klijenti"   />}
                                                       
                                                            onChange={(event, newValue: Klijenti | null) => {
                                                          
                                                            if (newValue) { 
                                                                if(props.lokacija == "racun"){

                                                                    dispatch(setIdOdabranogKlijenta(newValue.value));
                                                                }else if(props.lokacija == "izvestajZbirniArtikli"){
                                                                    dispatch(setIdKlijentaZaIzv(newValue.value)); 
                                                                    dispatch(setImeKlijentaZaIzv(newValue.ime)); 
                                                                }
                                                                       
                                                                 
                                                                            
                                                              

                                                            }else {
                                                                dispatch(setIdOdabranogKlijenta(0));
                                                                //console.log("Polje za unos je ispražnjeno (clear).");
                                                              }
                                                          }}     
                                                    />
                                            </FormControl>&nbsp;
                                        </Box>
                                setPrikaz(menuHTML);
                            }              
                        })
    }

    useEffect(() => { 
        PrikazSelecta();  
    },[tip, disejbluj]);


    return(
        <>       
             {prikaz}              
        </>
    )
}

export default SifarnikKlijenti;