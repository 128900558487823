import { GlavnaIzlazneFakture } from '../components/fakture/izlazne/glavna_izlazne';

function izlazneFakture() {

return(

   <GlavnaIzlazneFakture/>

)
}

export default izlazneFakture;