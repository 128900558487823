import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { setStranica, isprazniStavkeFakture  } from '../../../../redux/stranice/izlazneFakture';


export const StornirajFakturu = () => {

  const dispatch = useDispatch();
  const idFakture = useSelector((state: RootState) => state.izlazneFakture.idFakture);
  const idFaktureEFakture = useSelector((state: RootState) => state.izlazneFakture.idFaktureEFakture);
  const MySwal = withReactContent (Swal); //sweetalert2
  const defaultniUrl = useSelector((state: RootState) => state.defaultPodaci.defaultniUrl);

  const stornirajFakturu = () => { 
    fetch(`${defaultniUrl}storniraj_fakturu`, {
          method: 'POST', 
          headers: {
          'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              idFakture:idFakture,
              idFaktureEFakture:idFaktureEFakture
                  })        
              }).then(res => res.json())
              .then(data => {
                  
                  if(data.poruka == "uspesno"){

                      MySwal.fire({
                          title: 'Faktura je stornirana!',
                          //text: '',
                          //icon: 'info',
                          timer: 4000,
                          showCancelButton: false,
                          showConfirmButton: false,
                          icon: 'success',
                          //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                      })
                      dispatch(isprazniStavkeFakture());//obavezno svaki put isprazniti
                      dispatch(setStranica(1)); 
                    }                        
              }) 
  }



 return(
    <>
        <Button style={{ width: '150px'}} variant="contained" color="primary"  onClick={() => stornirajFakturu()}>Storniraj</Button>&nbsp;
    </>
 )
};


export default StornirajFakturu;