import { Glavna_izvZbirniKlijenti } from '../components/izvestaji/izvZbirniKlijenti/glavna_izvZbirniKlijenti';

function izvZbirniKlijenti() {

return(

   <Glavna_izvZbirniKlijenti/>

)
}

export default izvZbirniKlijenti;