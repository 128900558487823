import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IzvZbirniArtikli {
  stranica: number;
  idKlijentaZaIzv: number;
  imeKlijentaZaIzv: string;
}

const initialState: IzvZbirniArtikli = {
    stranica: 1,
    idKlijentaZaIzv: 0,
    imeKlijentaZaIzv: ''
};

const izvZbirniArtikli = createSlice({
  name: 'izvZbirniArtikli',
  initialState,
  reducers: {
    setStranica: (state, action: PayloadAction<number>) => {
      state.stranica = action.payload;
    },
    setIdKlijentaZaIzv: (state, action: PayloadAction<number>) => {
      state.idKlijentaZaIzv = action.payload;
    },
    setImeKlijentaZaIzv: (state, action: PayloadAction<string>) => {
      state.imeKlijentaZaIzv = action.payload;
    },
  }
});

export const { setStranica, setIdKlijentaZaIzv, setImeKlijentaZaIzv } = izvZbirniArtikli.actions;
export const izvZbirniArtikliReducer = izvZbirniArtikli.reducer;


