import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { setDatumDospecaFakture } from '../../redux/stranice/maloprodaja';
import {  useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const DatumDospeca = () => {
    const dispatch = useDispatch();
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const datumPravljenjaFakture = useSelector((state: RootState) => state.maloprodaja.datumPravljenjaFakture);
    const MySwal = withReactContent (Swal); //sweetalert2

    useEffect(() => { 
        if (selectedDate !== null) {
            const datumPravljenjaFakture1 = new Date(datumPravljenjaFakture);
            const selectedDateOnly1 = new Date(selectedDate);

         //   console.log(datumPravljenjaFakture1+' '+selectedDateOnly1);           ????????????????????????????????????????????????????????
            if(datumPravljenjaFakture == ""){
                    MySwal.fire({
                    title: `Morate prvo izabrati datum fakture!`,
                    //text: '',
                    //icon: 'info',
                    timer: 4000,
                    showCancelButton: false,
                    showConfirmButton: false,
                    icon: 'warning',
                    //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
                    })
                    //sad treba isprazniti polje
            }
        //     else if(datumPravljenjaFakture1 > selectedDateOnly1){
        //         MySwal.fire({
        //         title: `Datum fakture ne može biti veći datuma dospeća!`,
        //         //text: '',
        //         //icon: 'info',
        //         timer: 4000,
        //         showCancelButton: false,
        //         showConfirmButton: false,
        //         icon: 'warning',
        //         //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
        //         })
        //         //sad treba isprazniti polje
        // }
        else{
                const selectedDateOnly = new Date(selectedDate);
                const day = String(selectedDateOnly.getDate()).padStart(2, '0');
                const month = String(selectedDateOnly.getMonth() + 1).padStart(2, '0');
                const year = selectedDateOnly.getFullYear();
                const formattedDate = `${day}/${month}/${year}`;
                dispatch(setDatumDospecaFakture(formattedDate));
            }
            
        }
    }, [selectedDate]);

    return (       
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker 
                label="Datum dospeća" 
                value={selectedDate}
                onChange={(newDate) => setSelectedDate(newDate)}
                sx={{ width: '170px'}}
            />
        </LocalizationProvider>
    )
}

export default DatumDospeca;


