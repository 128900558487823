import { GlavnaUlazneFakture } from '../components/fakture/ulazne/glavna_ulazne';

function ulazneFakture() {

return(

   <GlavnaUlazneFakture/>

)
}

export default ulazneFakture;