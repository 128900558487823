import { Filteri_izvZbirniKlijenti } from './filteri_izvZbirniKlijenti';
import { RootState } from '../../../redux/store';
import { useLocation } from 'react-router-dom';
import {  useEffect } from "react";
import { setStranica } from '../../../redux/stranice/izvZbirniKlijenti';
import { useDispatch, useSelector } from 'react-redux';

export const Glavna_izvZbirniKlijenti  = () => {
  const stranica = useSelector((state: RootState) => state.izvZbirniKlijenti.stranica);
  const dispatch = useDispatch();

//OVO UVEK KADA SE DODJE NA STRANICU RESETUJE PRVO SVE PROMENLJIVE U REDUXU....PA POSLE NEK RADI KAKO HOCE
const location = useLocation();
useEffect(() => {
  if (location.pathname === '/izvZbirniKlijenti') {
      dispatch(setStranica(1));  
  }
}, [location.pathname]);
//OVO UVEK KADA SE DODJE NA STRANICU RESETUJE PRVO SVE PROMENLJIVE U REDUXU....PA POSLE NEK RADI KAKO HOCE


 return(
   <>
     {stranica === 1 && <Filteri_izvZbirniKlijenti/>}
   </>
 )
};


export default Glavna_izvZbirniKlijenti ;