import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { setDatumPravljenjaFakture } from '../../redux/stranice/maloprodaja';
import dayjs from 'dayjs'; 
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'

export const DatumPravljenja = () => {
    const dispatch = useDispatch();
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const MySwal = withReactContent (Swal); //sweetalert2

    useEffect(() => { 
        if (selectedDate !== null) {
            const selectedDateOnly = new Date(selectedDate);
            //const today = dayjs(); // Trenutni datum
            //const selected = dayjs(selectedDate);
            
            // if (selected.isBefore(today, 'day')) {
            //                     //console.log('Izabran datum je stariji od današnjeg datuma');
            //                     MySwal.fire({
            //                     title: `Izabran datum je stariji od današnjeg datuma`,
            //                     //text: '',
            //                     //icon: 'info',
            //                     timer: 4000,
            //                     showCancelButton: false,
            //                     showConfirmButton: false,
            //                     icon: 'warning',
            //                     //html: "<img src='" + ImgNapustioMec + "' style='width:150px;'>"
            //                     })
            //                     return;

            // } else {
                const day = String(selectedDateOnly.getDate()).padStart(2, '0');
                const month = String(selectedDateOnly.getMonth() + 1).padStart(2, '0');
                const year = selectedDateOnly.getFullYear();
                const formattedDate = `${day}/${month}/${year}`;
                dispatch(setDatumPravljenjaFakture(formattedDate));
            //}
        }
    }, [selectedDate]);

    return (       
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker 
                label="Datum fakture" 
                value={selectedDate}
                onChange={(newDate) => setSelectedDate(newDate)}
                sx={{ width: '170px'}}
            />
        </LocalizationProvider>
    )
}

export default DatumPravljenja;
