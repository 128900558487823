import { TabelaIzlazneFak } from './tabela_izlazne';
import { PrikazFakture } from './prikaz_fakture';
// import { Dod_dodataka } from './dod_dodatak';
// import { Izm_dodatak } from './izm_dodatak';
import {  useEffect } from "react";
import { useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

import { setStranica } from '../../../redux/stranice/izlazneFakture';


export const GlavnaIzlazneFakture = () => {

  const dispatch = useDispatch();
  const stranica = useSelector((state: RootState) => state.izlazneFakture.stranica);


 

//OVO UVEK KADA SE DODJE NA STRANICU RESETUJE PRVO SVE PROMENLJIVE U REDUXU....PA POSLE NEK RADI KAKO HOCE
const location = useLocation();
useEffect(() => {
  if (location.pathname === '/izlFaklture') {
      dispatch(setStranica(1));  
  }
}, [location.pathname]);
//OVO UVEK KADA SE DODJE NA STRANICU RESETUJE PRVO SVE PROMENLJIVE U REDUXU....PA POSLE NEK RADI KAKO HOCE




 return(
   <>

 
   {/* {idArtikla} */}
     {stranica === 1 && <TabelaIzlazneFak/>}
     {stranica === 2 && <PrikazFakture/>}

     {/* {stranica === 2 && <Dod_dodataka/>}
     {stranica === 3 && <Izm_dodatak/>} */}
     {/*{trenutniPrikazMenu === 3 && <Dod_nasl_menu/>}
     {trenutniPrikazMenu === 4 && <Izm_nasl_menu/>}
     {trenutniPrikazMenu === 5 && <Dod_stavku_menu id={redNasloviMenia} naziv={nazivNaslova}/>}
     {trenutniPrikazMenu === 6 && <Izm_stavku_menu id={redStavkeMenia} naziv={nazivStavke} />} */}
   </>
 )
};


export default GlavnaIzlazneFakture;