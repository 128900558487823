import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IzvZbirniKlijenti {
  stranica: number;
}

const initialState: IzvZbirniKlijenti = {
    stranica: 1,
};

const izvZbirniKlijenti = createSlice({
  name: 'izvZbirniKlijenti',
  initialState,
  reducers: {
    setStranica: (state, action: PayloadAction<number>) => {
      state.stranica = action.payload;
    },
  }
});

export const { setStranica } = izvZbirniKlijenti.actions;
export const izvZbirniKlijentiReducer = izvZbirniKlijenti.reducer;


