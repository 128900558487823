import { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { setStranica } from '../../../redux/stranice/izlazneFakture';


export const OtpremnicaTemplate = (props:any) => {

  const idFakture = useSelector((state: RootState) => state.izlazneFakture.idFakture);
  const vozilo = useSelector((state: RootState) => state.izlazneFakture.vozilo);
  const defaultniUrl = useSelector((state: RootState) => state.defaultPodaci.defaultniUrl);

  //moji admin podaci
  const [ime, setIme] = useState('');
  const [adresa, setAdrese] = useState('');
  const [mestoOtpreme, setMestoOtpreme] = useState('');
  const [pib, setPib] = useState(0);
  const [maticniBr, setMaticniBr] = useState(0);
  const [sifraDelatnosti, setSifraDelatnosti] = useState(0);
  const [zr, setZr] = useState('');
  const [opis, setOpis] = useState('');
  const [telefon, setTelefon] = useState(0);
  const [fixtelefon, setFixTelefon] = useState(0);
  const [email, setEmail] = useState('');  
  //moji admin podaci

  //klijentovi podaci
  const [imeKlijenta, setImeKlijenta] = useState('');
  const [adresaKlijenta, setAdresaKlijenta] = useState('');
  const [mestoKlijenta, setMestoKlijenta] = useState('');
  const [pibKlijenta, setPibKlijenta] = useState('');
  const [maticniBrKlijenta, setMaticniBrKlijenta] = useState('');
  const [telefonKlijenta, setTelefonKlijenta] = useState('');
  //klijentovi podaci

  //faktura podaci
  const [brojFakture, setBrojFakture] = useState('');
  const [datumFakture, setDatumFakture] = useState('');
  const [datumDospeca, setDatumDospeca] = useState('');
  const [ukupnaPdvFak, setUkupnaPdvFak] = useState(0);
  const [ukupnaVrednostFak, setUkupnaVrednostFak] = useState(0);


   //vozila
   const [tipVozila, seTipVozila] = useState('');
   const [registracijaVozila, setRegistracijaVozila] = useState('');
   const [vozac, setVozac] = useState('');


  //faktura podaci
  const [stavkeFakture, setStavkeFakture] = useState([]);

  const dispatch = useDispatch();

  
   const gornjiDeo = {
    display: 'flex',
    padding: '15px'
  };
  const mojiPodaci = {
    border: '1px solid black',
    width: '45%',
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'space-between',
    height: '100%',
    backgroundColor: 'red',
    padding: '5px'
  };
  const prazanMedjuDiv = {
    width: '10%'
  };
  const klijentoviPodaci = {
    border: '1px solid black',
    width: '45%',
    display: 'flex',
    flexDirection: 'column' as const,
    height: '100%',
    padding: '5px'
  };
  const posiljaoc = {
    fontSize: '22px'
  };  
  const primaoc = {
    fontSize: '22px'
  }; 
  const medju = {
    display: 'flex',
    padding: '15px'
  }; 
  const otpremnica = {
    width: '45%',
    padding: '5px',
    fontSize: '23px'
  }; 
  const medju_medju = {
    width: '10%',
  };
  const gde_i_kada = {
    width: '45%',
    padding: '5px'
  };

  const tabela = {
    width: '100%'
  };
  const div_tabela = {
    padding: '15px'
  };
  
  const th = {
    border: '1px solid black',
    borderLeft: 'none'
    };
  const td = {
    border: '1px solid black',
    borderLeft: 'none',
    borderTop: 'none'
   };
   const osn_podaci = {
    padding: '15px',
    display: 'flex',
    justifyContent: 'space-between',
   };

   
  
  

  const PodaciFakture = async () => {     
    fetch(`${defaultniUrl}podaci_fakture`, {
            method: 'POST', 
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              idFakture:idFakture
                    })        
                }).then(res => res.json())
                .then(data => {
                    if(data !== ""){  
                       //admin podaci                 
                       setIme(data.admin[0].ime);
                       setAdrese(data.admin[0].adresa);
                       setMestoOtpreme(data.admin[0].mesto_otpreme);
                       setPib(data.admin[0].pib);
                       setMaticniBr(data.admin[0].maticni_br);
                       setSifraDelatnosti(data.admin[0].sifra_delatnosti);
                       setZr(data.admin[0].ziro_racun);
                       setOpis(data.admin[0].opis);
                       setTelefon(data.admin[0].telefon);
                       setFixTelefon(data.admin[0].fix_telefon);
                       setEmail(data.admin[0].email);

                       //klijent podaci
                       setImeKlijenta(data.faktura_klijent[0].ime);
                       setAdresaKlijenta(data.faktura_klijent[0].adresa);
                       setMestoKlijenta(data.faktura_klijent[0].mesto);
                       setPibKlijenta(data.faktura_klijent[0].pib);
                       setMaticniBrKlijenta(data.faktura_klijent[0].maticni_br);
                       setTelefonKlijenta(data.faktura_klijent[0].telefon);


                       //faktura podaci
                       setBrojFakture(data.faktura_klijent[0].broj);
                       setDatumFakture(data.faktura_klijent[0].datum_pravljenja_fakture);
                       setDatumDospeca(data.faktura_klijent[0].datum_dospeca);
                       setUkupnaPdvFak(data.faktura_klijent[0].ukupan_pdv);
                       setUkupnaVrednostFak(data.faktura_klijent[0].ukupna_vrednost);


                       //stavke fakture
                       const stavkeFakture = data.stavke_fakture.map((row:any, index:any) =>  (
                        <tr key={row.id} >
                           <td style={{...td, borderLeft: '1px solid black'}} align="center">{index+1}.</td>
                           <td style={td} align="center">{row.ime}</td>
                           <td style={td} align="center">{row.jedinica_mere}</td>
                           <td style={td} align="center">{row.kolicina}</td>
                           <td style={td} align="center">{row.cena}</td>
                           <td style={td} align="center">{row.ukupna_vrednost}</td>
                        </tr>
                      ))
                      setStavkeFakture(stavkeFakture);


                      


                    }              
                })
}  

  useEffect(() => { 
    PodaciFakture(); 
  },[]);


  const okvir = {
    padding: '25px'
  };

 

  useEffect(() => { 
    if(vozilo === "1"){
      seTipVozila('Kombi');
      setRegistracijaVozila('BG 111 AA');
      setVozac('Momčilo Stanisavljević');    
    } 
    else if(vozilo === "2"){
      seTipVozila('Caddy');
      setRegistracijaVozila('BG 222 AA');
      setVozac('Momčilo Stanisavljević');  
    } 
  },[vozilo]);
       




      
return (
    <div  id={props.id} style={{ display: 'none' }}> {/*jeste display none ali je na stampi vidljiva  */}
            

                      <div style={gornjiDeo}>
                          <div style={mojiPodaci}>
                               <span style={posiljaoc}>NAZIV POSILJAOCA</span>
                               <span>{ime}</span>
                               <span>Adresa: {adresa}</span>
                               <span>PIB: {pib}</span>
                               <span>MB: {maticniBr}</span>
                              
                            
                          </div>

                          <div style={prazanMedjuDiv}></div>

                          <div style={klijentoviPodaci}>
                              <span style={primaoc}>NAZIV PRIMAOCA</span>
                              <span>{imeKlijenta}</span>
                               <span>Adresa: {adresaKlijenta}</span>
                               <span>PIB: {pibKlijenta}</span>
                               <span>MB: {maticniBrKlijenta}</span>
                          </div>
                      </div>

                      <div style={medju}>
                        <div style={otpremnica}><b>OTPREMNICA BR.:_______</b></div>
                        <div style={medju_medju}></div>
                        <div style={gde_i_kada}>U {mestoOtpreme} <br/> dana _______ 20______ .god</div>
                        
                      </div>

                      <div style={div_tabela}>

                          <table style={tabela} cellSpacing ="0">                                 
                                  <thead>
                                      <tr>
                                          <th style={{...th, borderLeft: '1px solid black'}}>R.B</th>
                                          <th style={th}>Naziv dobra - usluge</th>
                                          <th style={th}>Jedinica<br/>mere</th>
                                          <th style={th}>Kolicina</th>
                                          <th style={th}>Cena</th>
                                          <th style={th}>Iznos</th>
                                      </tr>
                                  </thead>

                                  <tbody>
                                    {stavkeFakture}
                                  </tbody>                              
                          </table>


                      </div>
                        

                      <div style={osn_podaci}>                          
                          <span>Nacin otpreme: {tipVozila}</span>  <span>Reg. br.: {registracijaVozila}</span>  <span>Vozac: {vozac}</span>                      
                      </div>   

                      <div style={osn_podaci}>                                            
                          <span>Izdao ________________________________</span>    <span>Primio ________________________________</span>                          
                      </div>                          
    </div>
);





      


   
}

export default OtpremnicaTemplate;