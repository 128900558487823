import { Filteri_izvZbirniArtikli } from './filteri_izvZbirniArtikli ';
import { RootState } from '../../../redux/store';
import { useLocation } from 'react-router-dom';
import {  useEffect } from "react";
import { setStranica } from '../../../redux/stranice/izvZbirniArtikli';
import { useDispatch, useSelector } from 'react-redux';


export const Glavna_izvZbirniArtikli  = () => {
    const stranica = useSelector((state: RootState) => state.izvZbirniArtikli.stranica);
    const dispatch = useDispatch();

//OVO UVEK KADA SE DODJE NA STRANICU RESETUJE PRVO SVE PROMENLJIVE U REDUXU....PA POSLE NEK RADI KAKO HOCE
const location = useLocation();
useEffect(() => {
  if (location.pathname === '/izvZbirniArtikli') {
      dispatch(setStranica(1));  
  }
}, [location.pathname]);
//OVO UVEK KADA SE DODJE NA STRANICU RESETUJE PRVO SVE PROMENLJIVE U REDUXU....PA POSLE NEK RADI KAKO HOCE

  return(
    <>
      {stranica === 1 && <Filteri_izvZbirniArtikli/>}
    </>
  )
 };
 
 
 export default Glavna_izvZbirniArtikli ;