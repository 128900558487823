import { Glavna_klijenti } from '../components/klijenti/glavna_klijenti';

function Klijenti() {

return(

   <Glavna_klijenti/>

)
}

export default Klijenti;
